import AppStore from "~/src/Configurations/AppStore";
import {
  resetSearchBillerState,
  setBillerRegisterationToPaymentPage,
} from "~/src/Redux/Billers/Reducers/SearchService.reducer";
import { hideLoader } from "~/src/Redux/Loaders/Reducers/Loaders.reducers";

export const billerListPageLoader = async () => {
  AppStore.dispatch(hideLoader());
  AppStore.dispatch(setBillerRegisterationToPaymentPage({})); //clear journey data if any
  AppStore.dispatch(resetSearchBillerState({}));
  return "";
};
