import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { CoachMarksMapper, ProcessCoachMarksMapper } from "../Model";
import {
  getCoachMarksDetailAction,
  processCoachMarksDetailAction,
} from "../Actions";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";

type TGetService = {
  customerId: string;
};

async function getService({ customerId }: TGetService) {
  try {
    const options = {
      url: URL.COACHMARKS.DISPLAY_COACH_MARKS,
      method: "POST",
      data: { customerId },
    };
    const response = await asHttp.request(options);
    const mappedData = new CoachMarksMapper().toModel(response?.data?.data);

    return mappedData;
  } catch (error) {
    throw error;
  }
}

export const getCoachMarksDataThunk = serviceActionCreator(
  getCoachMarksDetailAction,
  getService
);

type TProcessService = { customerId: string; isJourneyCompleted: string };

async function processService({
  customerId,
  isJourneyCompleted,
}: TProcessService) {
  try {
    const options = {
      url: URL.COACHMARKS.PROCESS_COACH_MARKS,
      method: "POST",
      data: {
        customerId: customerId,
        isJourneyCompleted,
      },
    };
    const response = await asHttp.request(options);
    const mappedData = new ProcessCoachMarksMapper().toModel(
      response?.data?.data
    );

    return mappedData;
  } catch (error) {
    throw error;
  }
}

export const processCoachMarksDataThunk = serviceActionCreator(
  processCoachMarksDetailAction,
  processService
);
