import { ChangeEvent } from "react";

export const changevalidate = (e: ChangeEvent<HTMLInputElement>): boolean => {
  if (e.target.value.length === 0) {
    return true;
  }
  return !isNaN(Number(e.target.value));
};

export const twoDecimalPointRestriction = (amount: string): string => {
  return amount.indexOf(".") >= 0
    ? amount.substr(0, amount.indexOf(".")) +
        amount.substr(amount.indexOf("."), 3)
    : amount;
};

export function localeAmountFormat(val: number): string {
  return val.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
