import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "../ServiceTracker/Selectors";
import { getHistoryListConfigName } from "./Actions";

export const SLICE_NAME = "historyList";

export const getHistoryLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getHistoryListConfigName]);

export const getHistorySuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getHistoryListConfigName]);

export const getHistoryErrorSelector = (state: RootState) =>
  isServiceError(state, [getHistoryListConfigName]);

export const getHistorySelector = (state: RootState) => state[SLICE_NAME];
