import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "../../ServiceTracker/Selectors";
import { getBillerListDataConfigName } from "../Actions/SearchService.action";

export const SLICE_NAME = "billers";

export const getBannerLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getBillerListDataConfigName]);

export const getBannerSuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getBillerListDataConfigName]);

export const getBannerErrorSelector = (state: RootState) =>
  isServiceError(state, [getBillerListDataConfigName]);

export const billersSelector = (state: RootState) => state[SLICE_NAME];
