import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";
import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  OperatorCircleDto,
  OperatorCircle,
  OperatorCircleMapper,
} from "~/src/Redux/Prepaid/Model/prepaid.model";
import {
  fetchPrepaidOperatorList,
  fetchPrepaidOpeartorListFail,
  fetchPrepaidOperatorListSuccess,
} from "~/src/Redux/Prepaid/Reducers/PrepaidOperator.reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
const PREPAID_OPERATOR_URL = URL.PREPAID.PREPAID_OPERATOR;

export const getPrepaidOperatorListThunk =
  (
    customerId: string,
    mobile: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchPrepaidOperatorList(customerId));
    console.log("PREPAID_OPERATOR_URL", PREPAID_OPERATOR_URL);
    try {
      const options: WebHttpRequestOptions = {
        url: `${PREPAID_OPERATOR_URL}`,
        method: "POST",
        data: { operatorSeriesId: mobile.substring(0, 5) },
      };
      const response = await asHttp.request(options);

      const mapper = new OperatorCircleMapper();
      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchPrepaidOperatorListSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchPrepaidOpeartorListFail(error as ApiResponseError));
      throw error;
    }
  };
