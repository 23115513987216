import { ModelMapper } from "~/src/Lib/types/modelmapper";
import { PaymentMethodEnum } from "../../Payment/Model/PaymentDetail.model";

export enum fieldDataType {
  "NUMERIC" = "NUMERIC",
  "ALPHANUMERIC" = "ALPHANUMERIC",
  "LIST" = "LIST",
}

export enum BillerTypeEnum {
  BILLER = "BILLER",
  PAYEE = "PAYEE",
  BOTH = "BOTH",
}

export enum PaymentChannelEnum {
  ATM = "ATM",
  Agent = "Agent",
  BankBranch = "BankBranch",
  BusinessCorrespondent = "BusinessCorrespondent",
  Internet = "Internet",
  InternetBanking = "InternetBanking",
  Kiosk = "Kiosk",
  MPOS = "MPOS",
  Mobile = "Mobile",
  MobileBanking = "MobileBanking",
  POS = "POS",
}

export interface PaymentChannel {
  paymentChannel: PaymentChannelEnum;
  minLimit: number;
  maxLimit: number;
}

export interface PaymentMethod {
  payLaterAllowed: boolean;
  paymentMethod: PaymentMethodEnum;
  minLimit: number;
  maxLimit: number;
  autopayAllowed: boolean;
}

export interface CustomerConvenienceFee {
  amount_slab_end: string;
  amount_slab_start: string;
  bou_conv_fee: string;
  bou_conv_fee_gst: string;
  bou_conv_fee_gst_type: string;
  bou_conv_fee_type: string;
  max_bou_conv_fee: string;
  min_bou_conv_fee: string;
  payment_channel: string;
  payment_method: string;
}

export interface BillerAuthenticator {
  optional: boolean;
  regex: string;
  fieldName: string;
  parameterName: string;
  dataType: fieldDataType;
  errorMessage: string;
  seq: number;
  minLength?: number;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  encryptionRequired: boolean;
  initValue?: any;
  listOfValues?: { name: string; value: string }[];
}

export interface Biller {
  billerId: string;
  billerLegalName: string;
  billerName: string;
  billerCategory: string;
  billerLogo: string;
  billerSampleBill: string;
  billerType: BillerTypeEnum;
  partialPay: boolean;
  partialPayLessThanAmount: boolean;
  payAfterDuedate: boolean;
  onlineValidation: boolean;
  isBillerBBPS: boolean;
  paymentAmountValidation: boolean;
  billPresentment: boolean;
  authenticators: BillerAuthenticator[];
  paymentMethods: PaymentMethod[];
  paymentChannels: PaymentChannel[];
  additionalValidation?: BillerAuthenticator[];
  isExactUpPayment: boolean;
  isExactDownPayment: boolean;
  isAnyPartialPayAmount: boolean;
  billerRegState: string;
  customer_conv_fee: CustomerConvenienceFee[];
}

interface AuthenticatorDto {
  optional: string;
  regex: string;
  parameter_name: string;
  data_type: string;
  error_message: string;
  seq: string;
  user_input: string;
  encryption_required: string;
  list_of_values?: { name: string; value: string }[];
}

export interface BillerDto {
  authenticators: AuthenticatorDto[];
  billerid: string;
  biller_legal_name: string;
  biller_name: string;
  biller_category: string;
  biller_logo: string;
  biller_bill_copy: string;
  biller_type: string;
  pay_after_duedate: string;
  isbillerbbps: string;
  paymentamount_validation: string;
  partial_pay: string;
  partial_pay_amount: string;
  bill_presentment: string;
  biller_reg_state: string;
  online_validation: string;
  payment_channels: {
    payment_channel: string;
    min_limit: string;
    max_limit: string;
  }[];
  allowed_payment_methods: {
    paylater_allowed: string;
    payment_method: string;
    min_limit: string;
    max_limit: string;
    autopay_allowed: string;
  }[];
  additional_validation_details?: AuthenticatorDto[];
  customer_conv_fee: CustomerConvenienceFee[];
}

export class BillerMapper implements ModelMapper<BillerDto, Biller> {
  toModel(dto: BillerDto): Biller {
    return {
      billerId: dto.billerid,
      billerLegalName: dto.biller_legal_name,
      billerName: dto.biller_name,
      billerCategory: dto.biller_category,
      billerLogo: dto.biller_logo,
      billerSampleBill: dto.biller_bill_copy,
      billerRegState: dto.biller_reg_state,
      authenticators: dto.authenticators
        .map((auth) => ({
          parameterName: auth.parameter_name,
          fieldName: auth.parameter_name.replace(/[^A-Za-z0-9]+/g, ""),
          dataType:
            auth.data_type.toLowerCase() === "numeric"
              ? fieldDataType.NUMERIC
              : auth.data_type.toLowerCase() === "list"
              ? fieldDataType.LIST
              : fieldDataType.ALPHANUMERIC,
          optional: auth.optional == "Y" ? true : false,
          regex: auth.regex,
          errorMessage: auth.error_message,
          seq: Number(auth.seq),
          encryptionRequired: auth.encryption_required === "Y" ? true : false,
          listOfValues: auth.list_of_values
            ? auth.list_of_values.map((item) => {
                const listElem = { name: item.name, value: item.value };
                return listElem;
              })
            : undefined,
        }))
        .sort((a, b) => a.seq - b.seq),
      billerType: dto.biller_type as BillerTypeEnum,
      isBillerBBPS: dto.isbillerbbps == "Y" ? true : false,
      partialPay: dto.partial_pay == "Y" ? true : false,
      partialPayLessThanAmount:
        dto.partial_pay_amount === "exact_down" ? true : false,
      payAfterDuedate: dto.pay_after_duedate == "Y" ? true : false,
      onlineValidation: dto.online_validation == "Y" ? true : false,
      paymentAmountValidation:
        dto.paymentamount_validation == "Y" ? true : false,
      billPresentment: dto.bill_presentment == "Y" ? true : false,
      paymentMethods: dto.allowed_payment_methods.map((method) => ({
        paymentMethod: method.payment_method as PaymentMethodEnum,
        payLaterAllowed: method.paylater_allowed == "Y" ? true : false,
        minLimit: Number(method.min_limit),
        maxLimit: Number(method.max_limit),
        autopayAllowed: method.autopay_allowed == "Y" ? true : false,
      })),
      paymentChannels: dto.payment_channels.map((channel) => ({
        paymentChannel: channel.payment_channel as PaymentChannelEnum,
        minLimit: Number(channel.min_limit),
        maxLimit: Number(channel.max_limit),
      })),
      additionalValidation:
        dto.additional_validation_details &&
        Array.isArray(dto.additional_validation_details)
          ? dto.additional_validation_details
              ?.map((auth) => ({
                parameterName: auth.parameter_name,
                fieldName: auth.parameter_name.replace(/[^A-Za-z]+/g, ""),
                dataType:
                  auth.data_type.toLowerCase() === "numeric"
                    ? fieldDataType.NUMERIC
                    : fieldDataType.ALPHANUMERIC,
                optional: auth.optional == "Y" ? true : false,
                regex: auth.regex,
                errorMessage: auth.error_message,
                seq: Number(auth.seq),
                encryptionRequired:
                  auth.encryption_required === "Y" ? true : false,
              }))
              .sort((a, b) => a.seq - b.seq)
          : undefined,
      isAnyPartialPayAmount: dto.partial_pay_amount === "" ? true : false,
      isExactDownPayment:
        dto.partial_pay_amount === "exact_down" ? true : false,
      isExactUpPayment: dto.partial_pay_amount === "exact_up" ? true : false,
      customer_conv_fee: dto.customer_conv_fee,
    };
  }
  toDto(model: Biller): BillerDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
