import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { DiscoveryApiDetailMapper } from "~/src/Redux/Splash/Model/DiscoveryApi.model";

import {
  fetchDiscoverApiDetailsList,
  fetchDiscoverApiDetailsListFail,
  fetchDiscoverApiDetailsListSuccess,
} from "~/src/Redux/Splash/Reducer/DiscoveryApi.reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const DISCOVERY_API_DETAILS_URL = URL.AUTHENTICATION.DISCOVERY_API_CITI;

export const getDiscoveryApiDetailsListThunk =
  (customerId: string): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchDiscoverApiDetailsList({
        customerId,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: DISCOVERY_API_DETAILS_URL,
        method: "POST",
        data: {
          customerid: customerId,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;

      const mapper = new DiscoveryApiDetailMapper();
      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchDiscoverApiDetailsListSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchDiscoverApiDetailsListFail(error as ApiResponseError));
      throw error;
    }
  };
