import { ModelMapper } from "~/src/Lib/types/modelmapper";
export interface CoachMarksDto {
  coachFlag: boolean;
  isNewUser: boolean;
}
export interface CoachMarksData {
  coachFlag: boolean;
  isNewUser: boolean;
}
export class CoachMarksMapper
  implements ModelMapper<CoachMarksDto, CoachMarksData>
{
  toModel(dto: CoachMarksDto): CoachMarksData {
    return {
      coachFlag: dto.coachFlag,
      isNewUser: dto.isNewUser,
    };
  }
  toDto(model: CoachMarksData): CoachMarksDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}

export interface ProcessCoachMarksDto {
  processed: boolean;
}

export interface ProcessCoachMarksData {
  processed: boolean;
}
export class ProcessCoachMarksMapper
  implements ModelMapper<ProcessCoachMarksDto, ProcessCoachMarksData>
{
  toModel(dto: ProcessCoachMarksDto): ProcessCoachMarksData {
    return {
      processed: dto.processed,
    };
  }
  toDto(model: ProcessCoachMarksData): ProcessCoachMarksDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
