import { ModelMapper } from '~/src/Lib/types/modelmapper'
import parse from 'date-fns/parse'

export enum BillerStateTypeEnum {
  OFFLINE = 'OFFLINE',
  INACTIVE = 'INACTIVE',
  FLUCTUATING = 'FLUCTUATING',
  ACTIVE = 'ACTIVE'
}

export interface BillerStatusDto {
  objectid: string
  sourceid: string
  billerid: string
  biller_state: string
  start_time: string
  update_time: string
  end_time: string
  billeralertid: string
}

export interface BillerStatusData {
  objectId: string
  sourceId: string
  billerId: string
  billerState: string
  startTime: Date
  updateTime: Date
  endTime: Date
  billerAlertId: string
}

export class BillerStatusDataMapper
  implements ModelMapper<BillerStatusDto, BillerStatusData>
{
  toModel(dto: BillerStatusDto): BillerStatusData {
    return {
      objectId: dto.objectid,
      sourceId: dto.sourceid,
      billerId: dto.billerid,
      billerState: dto.biller_state,
      startTime: parse(dto.start_time, 'dd-MM-yyyy', new Date()),
      updateTime: parse(dto.update_time, 'dd-MM-yyyy', new Date()),
      endTime: parse(dto.end_time, 'dd-MM-yyyy', new Date()),
      billerAlertId: dto.billeralertid
    }
  }
  toDto(model: BillerStatusData): BillerStatusDto {
    throw new Error(`Method not implemented. for ${model}`)
  }
}
