import { ModelMapper } from "~/src/Lib/types/modelmapper";
import { PaymentAccount, PaymentMethodEnum } from "./PaymentDetail.model";

export interface CreditCardDetailDto {
  cardNumber: string;
  primaryCard: number;
  customerName: string;
  customerId: string;
  peopleSerNo: number;
  productType: string;
  virtual: number;
  cardStatus: string;
  expiryDate: string;
  primeCardSerialNumber: number;
  maskedCardNumber: string;
}

export interface CreditCardDetailData {
  cardNumber: string;
  primaryCard: number;
  customerName: string;
  customerId: string;
  peopleSerNo: number;
  productType: string;
  virtual: number;
  cardStatus: string;
  expiryDate: string;
  primeCardSerialNumber: number;
  maskedCardNumber: string;
}

export class CreditCardDetailMapper
  implements ModelMapper<CreditCardDetailDto, CreditCardDetailData>
{
  toModel(dto: CreditCardDetailDto[]): CreditCardDetailData[] {
    return dto.map((item) => ({
      cardNumber: item.cardNumber,
      primaryCard: item.primaryCard,
      customerName: item.customerName,
      customerId: item.customerId,
      peopleSerNo: item.peopleSerNo,
      productType: item.productType,
      virtual: item.virtual,
      cardStatus: item.cardStatus,
      expiryDate: item.expiryDate,
      primeCardSerialNumber: item.primeCardSerialNumber,
      maskedCardNumber: item.maskedCardNumber,
    }));
  }
  toDto(model: CreditCardDetailData): CreditCardDetailDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}

export const mapCardDetailToPaymentAccount = (
  ccDetails: CreditCardDetailData,
  ispayment?: boolean
): PaymentAccount => {
  return {
    paymentMethod: PaymentMethodEnum.CreditCard,
    maskedCardNumber: ccDetails.cardNumber,
    primeCardSerialNumber: ccDetails.primeCardSerialNumber,
    cardName: ccDetails.productType,
    cardStart: !ispayment ? ccDetails.cardNumber.substring(0, 6) : undefined,
    cardEnd: !ispayment
      ? ccDetails.cardNumber.substring(
          ccDetails.cardNumber.length,
          ccDetails.cardNumber.length - 4
        )
      : undefined,
  };
};
