import React, { lazy } from "react";
import { NavigateOptions, RouteObject, To } from "react-router-dom";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { billerRegistrationLoader } from "./categoriesRegister/registrationLoader";
import { billerListPageLoader } from "./category/billerListPageLoader";

type BillerRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type BillerRoute = {
  [key: string]: BillerRouteItem;
};

const BillerRegisterPage = lazy(
  () => import("~/src/Pages/billers/categoriesRegister/biller.register.page")
);

const CommonCategorySelectionPage = lazy(
  () => import("~/src/Pages/billers/category/commonCategories.page")
);

const MutualfundLinkPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-link.page" /* webpackChunkName: "MutualfundLinkPage" */
    )
);
const MutualfundMPINPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-mpin.page" /* webpackChunkName: "MutualfundMPINPage" */
    )
);

const MutualfundStatus = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-status.page" /* webpackChunkName: "MutualFundStatusPage" */
    )
);

//need to move the route to biller.billdetail.page
const BillerBillDetailPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/biller.billdetail.page" /* webpackChunkName: "BillerBillDetailPage" */
    )
);

const getCategoryListPageRoutes = {
  ELECTRICITY: { pathname: `${APP_ROUTES.BILLERS.pathname}/electricity` },
  CREDITCARD: { pathname: `${APP_ROUTES.BILLERS.pathname}/creditcard` },
  FASTAG: { pathname: `${APP_ROUTES.BILLERS.pathname}/fastag` },
  EDUCATION: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/education`,
  },
  DTH: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/dth`,
  },
  GAS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/gas`,
  },
  LOAN: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/loan`,
  },
  BROADBAND: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/broadband`,
  },
  LANDLINE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/landline`,
  },
  PIPEDGAS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/piped-gas`,
  },
  INSURANCE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/insurance`,
  },
  WATER: { pathname: `${APP_ROUTES.BILLERS.pathname}/water` },
  CABLE_TV: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/cable-tv`,
  },
  RENTAL_PAYMENT: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/rental-payment`,
  },
  MOBILE_POSTPAID: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_postpaid`,
  },
  MUNICIPAL_TAX: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/tax`,
  },
  MUNICIPAL_SERVICES: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/municipal-services`,
  },
  RECURRING_DEPOSIT: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/recurring-deposit`,
  },
  CHARITY: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/donation`,
  },
  CLUBS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/clubs`,
  },
  MUTUALFUND: { pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund` },
  MOBILE_PREPAID: { pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_prepaid` },
  SUBSCRIPTION: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/subscription`,
  },
  HOUSING_SOCIETY: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/housing-society`,
  },
  HOSPITAL: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/hospital`,
  },
  NCMC: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/ncmc`,
  },
  NPS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/nps`,
  },
  PREPAID_METER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/prepaid-meter`,
  },
};

const setCategoryListPageRoutes = () => {
  const routeObj: RouteObject[] = [];
  Object.values(getCategoryListPageRoutes).forEach((route) => {
    routeObj.push({
      path: `${route.pathname}`,
      element: <CommonCategorySelectionPage />,
      loader: billerListPageLoader,
    });
  });
  return routeObj;
};

const commonCategoryPages: RouteObject[] = setCategoryListPageRoutes();

export const BILLERS_ROUTES: BillerRoute = {
  ...getCategoryListPageRoutes,
  // Mutual Fund Journey
  MUTUALFUND_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/register`,
  },
  MUTUALFUND_LINK: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/link`,
  },
  MUTUALFUND_STATUS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/status`,
  },
  MUTUALFUND_MPIN: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/mpin`,
  },

  NCMC_PAYMENT_PAGE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/ncmc/payment`,
  },

  // BILLER_HOME: `${APP_ROUTES.BILLERS}/common/home/:categoryId`,
  BILLER_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/common/register`,
  },
  // BILLER_REGISTER: `${APP_ROUTES.BILLERS.pathname}/common/register`,
  BILLER_BILLDETAIL: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/common/billdetail`,
  },
};

export const billerRoutes: RouteObject[] = [
  ...commonCategoryPages,
  {
    path: `${BILLERS_ROUTES.BILLER_REGISTER.pathname}`,
    element: <BillerRegisterPage />,
    loader: billerRegistrationLoader,
  },
  {
    path: `${BILLERS_ROUTES.BILLER_BILLDETAIL.pathname}`,
    element: <BillerBillDetailPage />,
  },
  {
    path: `${BILLERS_ROUTES.MUTUALFUND_LINK.pathname}`,
    element: <MutualfundLinkPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_MPIN.pathname}`,
    element: <MutualfundMPINPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_STATUS.pathname}`,
    element: <MutualfundStatus />,
  },
];
