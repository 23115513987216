export interface BannerData {
  imageUrl: string;
  title: string;
  link: string;
  mbLink: string;
  ibLink: string;
}

export const BannerMapper = (dto: BannerData): BannerData => {
  return {
    imageUrl: dto.imageUrl,
    title: dto?.title,
    link: dto?.link,
    mbLink: dto?.mbLink,
    ibLink: dto?.ibLink,
  };
};
