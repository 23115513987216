import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions,
} from "@reduxjs/toolkit";
import { IRemoteData, RemoteData } from "~/src/Lib/types/datatransfer";
import { getHistoryListDataAction } from "./Actions";
import { PaymentDetail } from "./Model";
import { SLICE_NAME } from "./Selector";

export type HistoryListState = {
  historyDetailData: IRemoteData<PaymentDetail[]>;
};

type HistoryActionType = {
  getHistoryDetails: (
    customerId: string,
    oneYear: boolean
  ) => Promise<PaymentDetail[]>;
  setHistoryStatusNotLoaded: () => void;
};

const initialState: HistoryListState = {
  historyDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setHistoryStatusNotLoaded(state) {
      state.historyDetailData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getHistoryListDataAction.success, (state, { payload }) => {
      state.historyDetailData = state.historyDetailData.setData(payload);
    });

    builder.addCase(getHistoryListDataAction.error, (state, { payload }) => {
      state.historyDetailData = state.historyDetailData.setError(payload);
    });
    builder.addCase(getHistoryListDataAction.loading, (state) => {
      state.historyDetailData = state.historyDetailData.setLoading();
    });
  },
};

const historyListSlice = createSlice(sliceOptions);
export default historyListSlice.reducer;

export const { setHistoryStatusNotLoaded } = historyListSlice.actions;
