import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/BillerAccount.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { Biller } from "../Model/Biller.model";
import { BillerAccount } from "../Model/BillerAccount.model";
import { BillerStatusData } from "../Model/BillerState.model";

export type BillerDataStateType = {
  billers: IRemoteData<Biller[]>;
  billerData: Biller[];
  billerDetailData: Biller;
  billerStatus: DataStatus;
  billerError?: ApiResponseError;
  billerAccount: IRemoteData<BillerAccount>;
  billerAccountData: BillerAccount;
  billerAccountStatus: DataStatus;
  billerAccountError?: ApiResponseError;
  billerStatusState: IRemoteData<BillerStatusData>;
  billerStatusData: BillerStatusData;
  billerStatusStatus: DataStatus;
  billerStatusError?: ApiResponseError;
};

const initialState: BillerDataStateType = {
  billers: new RemoteData(),
  billerData: [],
  billerDetailData: {} as Biller,
  billerStatus: DataStatus.NOT_LOADED,
  billerError: undefined,
  billerAccount: new RemoteData(),
  billerAccountData: {} as BillerAccount,
  billerAccountStatus: DataStatus.NOT_LOADED,
  billerAccountError: undefined,
  billerStatusState: new RemoteData(),
  billerStatusData: {} as BillerStatusData,
  billerStatusStatus: DataStatus.NOT_LOADED,
  billerStatusError: undefined,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchBiller(state) {
      state.billers = state.billers.setLoading();
      state.billerStatus = state.billers.status;
    },
    fetchBillerFail(state, action: PayloadAction<ApiResponseError>) {
      state.billers = state.billers.setError(action.payload);
      state.billerError = action.payload;
      state.billerStatus = state.billers.status;
    },
    fetchBillerSuccess(state, action: PayloadAction<Biller[]>) {
      state.billers = state.billers.setData(action.payload);
      state.billerData = action.payload;
      (state.billerDetailData = action.payload),
        (state.billerStatus = state.billers.status);
      state.billerError = undefined;
    },
    fetchBillerAccount(state) {
      state.billerAccount = state.billerAccount.setLoading();
      state.billerAccountStatus = state.billerAccount.status;
    },
    fetchBillerAccountFail(state, action: PayloadAction<ApiResponseError>) {
      state.billerAccount = state.billerAccount.setError(action.payload);
      state.billerAccountError = action.payload;
      state.billerAccountStatus = state.billerAccount.status;
    },
    fetchBillerAccountSuccess(state, action: PayloadAction<Biller[]>) {
      state.billerAccount = state.billerAccount.setData(action.payload);
      state.billerAccountData = action.payload;
      state.billerAccountStatus = state.billerAccount.status;
      state.billerAccountError = undefined;
    },
    fetchBillerState(state) {
      state.billerStatusState = state.billerStatusState.setLoading();
      state.billerStatusStatus = state.billerStatusState.status;
    },
    fetchBillerStateFail(state, action: PayloadAction<ApiResponseError>) {
      state.billerStatusState = state.billerStatusState.setError(
        action.payload
      );
      state.billerStatusError = action.payload;
      state.billerStatusStatus = state.billerStatusState.status;
    },
    fetchBillerStateSuccess(state, action: PayloadAction<Biller[]>) {
      state.billerStatusState = state.billerStatusState.setData(action.payload);
      state.billerStatusData = action.payload;
      state.billerStatusStatus = state.billerStatusState.status;
      state.billerStatusError = undefined;
    },
    setBillerIdStatusNotLoaded(state) {
      state.billerStatus = DataStatus.NOT_LOADED;
      state.billerData = new RemoteData();
    },
    setBillerStateNotLoaded(state) {
      return initialState;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const billerData = createSlice(sliceOptions);

export default billerData.reducer;
export const {
  fetchBiller,
  fetchBillerSuccess,
  fetchBillerFail,
  fetchBillerAccount,
  fetchBillerAccountFail,
  fetchBillerAccountSuccess,
  fetchBillerState,
  fetchBillerStateFail,
  fetchBillerStateSuccess,
  setBillerIdStatusNotLoaded,
  setBillerStateNotLoaded,
} = billerData.actions;
