import { WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import AppStore, { RootState } from "~/src/Configurations/AppStore";
import { ApiResponseError } from "~/src/Lib/types/api";
import { BillDetailDto } from "../Model/BillDetail.model";
import {
  BillerAccountDto,
  BillerAccountMapper,
} from "../Model/BillerAccount.model";
import {
  fetchDueBillsList,
  fetchDueBillsListFail,
  fetchDueBillsListSuccess,
  getBillerAlreadyUsedNickName,
  setBillerAccountStatusNotLoaded,
  setCashbackBillerAccountData,
} from "../Reducers/BillerAccount.reducer";
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
const DUE_BILLS = URL.BILLER.BILLER_ACCOUNT;
export const getDueBills =
  (
    customerId: string,
    dataStatusType?: string
  ): AppThunk<
    Promise<
      | {
          billlist?: BillDetailDto[];
          billeraccount: BillerAccountDto;
        }[]
      | ApiResponseError
    >
  > =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    const state: RootState = AppStore.getState();
    const custId =
      state?.customerDetail?.customerDetail?.data?.customerId || customerId;
    if (custId && custId?.length > 0) {
      dispatch(fetchDueBillsList({ custId }));
      try {
        const options: WebHttpRequestOptions = {
          url: DUE_BILLS,
          method: "POST",
          data: {
            customerid: custId,
          },
        };
        const response = await asHttp.request(options);
        dispatch(setBillerAccountStatusNotLoaded(dataStatusType));
        const mapper = new BillerAccountMapper();
        const mappedData = response.data.data.map((acc) => {
          acc.billeraccount.billlist = acc.billlist;
          return mapper.toModel(acc.billeraccount);
        });

        dispatch(getBillerAlreadyUsedNickName(mappedData));
        dispatch(setCashbackBillerAccountData(response.data.data));
        dispatch(fetchDueBillsListSuccess(mappedData));
        return mappedData;
      } catch (error) {
        dispatch(fetchDueBillsListFail(error as ApiResponseError));
        if (error?.statusCode === 404) {
          dispatch(fetchDueBillsListSuccess([]));
          return [];
        }
        throw error;
      }
    }
  };
