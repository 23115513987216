import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { getBillerListDataAction } from "../Actions/SearchService.action";
import { Biller } from "../Model/Biller.model";
import { BillerAccount } from "../Model/BillerAccount.model";
import { ValidatePayment } from "../Model/ValidatePayment.model";
import { SLICE_NAME } from "../Selectors/SearchService.selector";

export type BillerDataStateType = {
  billers: IRemoteData<Biller[]>;
  billerData: Biller[];
  billerStatus: DataStatus;
  billerError?: ApiResponseError;
  selectedBiller?: Biller | undefined;
  selectedCategory?: string | undefined;
  billerRegisterationToPaymentPage?: tbillerRegisterationToPaymentPage;
};

type tbillerRegisterationToPaymentPage = {
  biller: Biller;
  unregistred: Boolean;
  billerAccount: BillerAccount;
  validatePayment: ValidatePayment;
  nickName: string;
  categoryLogo: object;
  isCreditCardSelected?: Boolean;
};

const initialState: BillerDataStateType = {
  billers: new RemoteData(),
  billerData: [],
  billerStatus: DataStatus.NOT_LOADED,
  billerError: undefined,
  selectedBiller: undefined,
  selectedCategory: undefined,
  billerRegisterationToPaymentPage: { unregistred: false },
};

const sliceOptions: CreateSliceOptions<BillerDataStateType> = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetSearchBillerState: () => {
      return initialState;
    },
    setSelectedBillerDetails: (
      state: BillerDataStateType,
      action: PayloadAction<{ biller: Biller; categoryId: string }>
    ) => {
      state.selectedBiller = action.payload.biller;
      state.selectedCategory = action.payload.categoryId;
    },
    resetSelectedBillerDetails: (state: BillerDataStateType) => {
      state.selectedBiller = undefined;
      state.selectedCategory = "";
    },
    setBillerRegisterationToPaymentPage: (
      state: BillerDataStateType,
      action
    ) => {
      state.billerRegisterationToPaymentPage = action.payload;
    },
    resetBillerRegisterationToPaymentPage: (state: BillerDataStateType) => {
      state.billerRegisterationToPaymentPage = {};
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<BillerDataStateType>) => {
    builder.addCase(
      getBillerListDataAction.success,
      (state, action: PayloadAction<Biller[]>) => {
        state.billers = state.billers.setData(action.payload); // type safety
        state.billerData = action.payload;
        state.billerStatus = state.billers.status;
      }
    );
    builder.addCase(
      getBillerListDataAction.error,
      (state, action: PayloadAction<ApiResponseError>) => {
        state.billers = state.billers.setError(action.payload);
        state.billerError = action.payload;
        state.billerStatus = DataStatus.ERRORED; // Correct error handling
      }
    );

    builder.addCase(getBillerListDataAction.loading, (state) => {
      state.billers = state.billers.setLoading();
      state.billerStatus = state.billers.status;
    });
  },
};

const billerData = createSlice(sliceOptions);
const SearchServiceReducer = createSlice(sliceOptions).reducer;

export default SearchServiceReducer;
export const {
  resetSearchBillerState,
  setSelectedBillerDetails,
  resetSelectedBillerDetails,
  setBillerRegisterationToPaymentPage,
  resetBillerRegisterationToPaymentPage,
} = billerData.actions;
