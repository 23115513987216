import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/RegisterBiller.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { BillerAccount } from "../Model/BillerAccount.model";
import { getRegisterBillerDetailsListDataAction } from "../Actions/RegisteBiller.action";

export type RegisterBillerDetailsListState = {
  registerBillerData: IRemoteData<BillerAccount>;
};

const initialState: RegisterBillerDetailsListState = {
  registerBillerData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setBillerRegisterStatusNotLoaded(state) {
      state.registerBillerData.status = DataStatus.NOT_LOADED;
      state.registerBillerData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getRegisterBillerDetailsListDataAction.success,
      (state, { payload }) => {
        state.registerBillerData = state.registerBillerData.setData(payload);
      }
    );

    builder.addCase(
      getRegisterBillerDetailsListDataAction.error,
      (state, { payload }) => {
        state.registerBillerData = state.registerBillerData =
          state.registerBillerData.setError(payload);
      }
    );

    builder.addCase(getRegisterBillerDetailsListDataAction.loading, (state) => {
      state.registerBillerData = state.registerBillerData.setLoading();
    });
  },
};

const registerBillerDetailsSlice = createSlice(sliceOptions);

export default registerBillerDetailsSlice.reducer;
export const { setBillerRegisterStatusNotLoaded } =
  registerBillerDetailsSlice.actions;
