import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "../ServiceTracker/Selectors";
import { getCustomerDataConfigName } from "./Customer.action";

export const SLICE_NAME = "customerDetail";

export const getCustomerLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getCustomerDataConfigName]);

export const getCustomerSuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getCustomerDataConfigName]);

export const getCustomerErrorSelector = (state: RootState) =>
  isServiceError(state, [getCustomerDataConfigName]);

export const getCustomerSelector = (state: RootState) => state[SLICE_NAME];

export const getCustomerId = (state: RootState) => state[SLICE_NAME].customerId;

export const getMobile = (state: RootState) =>
  state[SLICE_NAME].customerDetail.data?.mobile;

export const getEmailId = (state: RootState) =>
  state[SLICE_NAME].customerDetail?.data?.emailId;

export const getCustomerData = (state: RootState) =>
  state[SLICE_NAME].customerDetail && state[SLICE_NAME].customerDetail.data;

export const getUtmDetails = (state: RootState) => state[SLICE_NAME].utmDetails;
