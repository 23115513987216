// import { ModelMapper } from "~/src/Lib/types/modelmapper";
// import {
//   PaymentAccount,
//   PaymentMethodEnum,
// } from "~/src/Redux/Payment/Model/PaymentDetail.model";

// export interface UserDetailsDto {
//   customerName: string;
//   dateOfBirth: Date;
//   pan: string;
//   mobile?: string;
//   aadharNo?: string;
//   passportNo?: string;
//   suspended: "N" | "Y";
// }

// export interface UserDetail {
//   customerName: string;
//   dateOfBirth: Date;
//   pan: string;
//   mobile?: string;
//   aadharNo?: string;
//   passportNo?: string;
//   suspended: "N" | "Y";
// }

// export interface AccountDetailsDto {
//   customerId: string;
//   acid: string;
//   branchId: string;
//   currencyCode: string;
//   schemType: string;
//   schemCode: string;
//   clearBalanceAmount: number;
//   unClearBalanceAmount: number;
//   accountAvailableBalance: number;
//   accountFFDBalance: number;
//   accountName: string;
//   nickName: string;
//   deleteFlag: string;
//   accountStatus: string;
//   bankId: string;
//   ifscCode: string;
//   branchName: string;
// }

// export interface AccountDetail {
//   customerId: string;
//   acid: string;
//   branchId: string;
//   currencyCode: string;
//   schemType: string;
//   schemCode: string;
//   clearBalanceAmount: number;
//   unClearBalanceAmount: number;
//   accountAvailableBalance: number;
//   accountFFDBalance: number;
//   accountName: string;
//   nickName: string;
//   deleteFlag: string;
//   accountStatus: string;
//   bankId: string;
//   ifscCode: string;
//   branchName: string;
//   paymentMethod: PaymentMethodEnum;
// }

// export interface AccountInfoDto {
//   CustomerDetails: UserDetailsDto[];
//   AccountDetails: AccountDetailsDto[];
// }

// export interface AccountInfo {
//   CustomerDetail: UserDetail[];
//   AccountDetails: AccountDetail[];
// }

// export class AccountDetailMapper
//   implements ModelMapper<AccountDetailsDto[], AccountDetail[]>
// {
//   toModel(dto: AccountDetailsDto[]): AccountDetail[] {
//     return dto.map((item) => ({
//       customerId: item.customerId,
//       acid: item.acid,
//       branchId: item.branchId,
//       currencyCode: item.currencyCode,
//       schemType: item.schemType,
//       schemCode: item.schemCode,
//       clearBalanceAmount: item.clearBalanceAmount,
//       unClearBalanceAmount: item.unClearBalanceAmount,
//       accountAvailableBalance: item.accountAvailableBalance,
//       accountFFDBalance: item.accountFFDBalance,
//       accountName: item.accountName,
//       nickName: item.nickName,
//       deleteFlag: item.deleteFlag,
//       accountStatus: item.accountStatus,
//       bankId: item.bankId,
//       ifscCode: item.ifscCode,
//       branchName: item.branchName,
//       paymentMethod: PaymentMethodEnum.BankAccount, // NOTE: since only SA and CA is used
//     }));
//   }
//   toDto(model: AccountDetail[]): AccountDetailsDto[] {
//     throw new Error("Method not implemented." + model);
//   }
// }

// export class AccountInfoMapper
//   implements ModelMapper<AccountInfoDto, AccountInfo>
// {
//   toModel(dto: AccountInfoDto): AccountInfo {
//     const actMapper = new AccountDetailMapper();
//     return {
//       CustomerDetail: dto.CustomerDetails.map(
//         (userDetail) => userDetail as UserDetail
//       ),
//       AccountDetails: dto.AccountDetails.map((ac) => ac as AccountDetail),
//     };
//   }
//   toDto(model: AccountInfo): AccountInfoDto {
//     return {
//       CustomerDetails: model.CustomerDetail.map(
//         (userDetail) => userDetail as UserDetailsDto
//       ),
//       AccountDetails: model.AccountDetails.map(
//         (acc) => acc as AccountDetailsDto
//       ),
//     };
//   }
// }

import { ModelMapper } from "~/src/Lib/types/modelmapper";
import {
  PaymentAccount,
  PaymentMethodEnum,
} from "~/src/Redux/Payment/Model/PaymentDetail.model";

export interface CustomerDetailsDto {
  customerName: string;
  dateOfBirth: Date;
  pan: string;
  mobile?: string;
  aadharNo?: string;
  passportNo?: string;
  suspended: "N" | "Y";
}

export interface CustomerDetail {
  customerName: string;
  dateOfBirth: Date;
  pan: string;
  mobile?: string;
  aadharNo?: string;
  passportNo?: string;
  suspended: "N" | "Y";
}

export interface AccountDetailsDto {
  customerId: string;
  acid: string;
  branchId: string;
  currencyCode: string;
  schemType: string;
  schemCode: string;
  clearBalanceAmount: number;
  unClearBalanceAmount: number;
  accountAvailableBalance: number;
  accountFFDBalance: number;
  accountName: string;
  nickName: string;
  deleteFlag: string;
  accountStatus: string;
  bankId: string;
  ifscCode: string;
  branchName: string;
}

export interface AccountDetail {
  customerId: string;
  acid: string;
  branchId: string;
  currencyCode: string;
  schemType: string;
  schemCode: string;
  clearBalanceAmount: number;
  unClearBalanceAmount: number;
  accountAvailableBalance: number;
  accountFFDBalance: number;
  accountName: string;
  nickName: string;
  deleteFlag: string;
  accountStatus: string;
  bankId: string;
  ifscCode: string;
  branchName: string;
  paymentMethod: PaymentMethodEnum;
}

export interface AccountInfoDto {
  CustomerDetails: CustomerDetailsDto[];
  AccountDetails: AccountDetailsDto[];
}

export interface AccountInfo {
  CustomerDetails: CustomerDetail[];
  AccountDetails: AccountDetail[];
}

export class AccountDetailMapper
  implements ModelMapper<AccountDetailsDto, AccountDetail>
{
  toModel(dto: AccountDetailsDto): AccountDetail {
    return {
      customerId: dto.customerId,
      acid: dto.acid,
      branchId: dto.branchId,
      currencyCode: dto.currencyCode,
      schemType: dto.schemType,
      schemCode: dto.schemCode,
      clearBalanceAmount: dto.clearBalanceAmount,
      unClearBalanceAmount: dto.unClearBalanceAmount,
      accountAvailableBalance: dto.accountAvailableBalance,
      accountFFDBalance: dto.accountFFDBalance,
      accountName: dto.accountName,
      nickName: dto.nickName,
      deleteFlag: dto.deleteFlag,
      accountStatus: dto.accountStatus,
      bankId: dto.bankId,
      ifscCode: dto.ifscCode,
      branchName: dto.branchName,
      paymentMethod: PaymentMethodEnum.BankAccount, // NOTE: since only SA and CA is used
    };
  }
  toDto(model: AccountDetail): AccountDetailsDto {
    throw new Error("Method not implemented." + model);
  }
}
export class CustomerDetailMapper
  implements ModelMapper<CustomerDetailsDto, CustomerDetail>
{
  toModel(dto: CustomerDetailsDto): CustomerDetail {
    return {
      customerName: dto.customerName,
      dateOfBirth: dto.dateOfBirth,
      pan: dto.pan,
      mobile: dto.mobile,
      aadharNo: dto.aadharNo,
      passportNo: dto.passportNo,
      suspended: dto.suspended,
    };
  }
  toDto(model: CustomerDetail): CustomerDetailsDto {
    throw new Error("Method not implemented." + model);
  }
}
export class AccountInfoMapper
  implements ModelMapper<AccountInfoDto, AccountInfo>
{
  toModel(dto: AccountInfoDto): AccountInfo {
    const actMapper = new AccountDetailMapper();
    const customerMapper = new CustomerDetailMapper();
    return {
      CustomerDetails: (dto.CustomerDetails as CustomerDetailsDto[])?.map(
        (customer) => customerMapper.toModel(customer)
      ),
      AccountDetails: (dto.AccountDetails as AccountDetailsDto[])?.map((ac) =>
        actMapper.toModel(ac)
      ),
    };
  }
  toDto(model: AccountInfo): AccountInfoDto {
    return {
      CustomerDetails: model.CustomerDetails as CustomerDetailsDto[],
      AccountDetails: model.AccountDetails as AccountDetailsDto[],
    };
  }
}

export const mapAccountDetailToPaymentAccount = (
  accDet: AccountDetail
): PaymentAccount => {
  return {
    //customerId: accDet.customerId,
    //shortName: accDet.nickName,
    paymentMethod: PaymentMethodEnum.BankAccount, // TODO: not account detail for credit;debit card;
    accountNumber: accDet.acid, // NOTE these might change based on payment method
    ifsc: accDet.ifscCode,
    //accountBalance: accDet.clearBalanceAmount.toFixed(2),
    //currency: accDet.currencyCode === "INR" ? "356" : accDet.currencyCode, // TODO: need mapping
    // cardStart?: string;
    // cardEnd?: string;
    // cardholderName?: string;
    // mmid?: string; //imps
    // mobileNo?: string; //wallet
    // walletName?: string; //wallet
    // encCardNumber?: string; //catd
    // encCardExpiry?: string; //card
    // cvv?: string; //card
    // cardAccountNumber?: string; // card
  };
};
