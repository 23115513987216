import React, { Component, ReactElement } from "react";
import {
  DsImage,
  DsBox,
  DsTypography,
  DsStack,
} from "@am92/react-design-system";
import LoaderAnimation from "./LoaderAnimation";
import { DsLoader } from "@am92/react-design-system";

type OverlayLoaderProps = {
  bg?: string;
  loaderText?: {
    heading: string;
    subText: string;
  };
};
class OverlayLoader extends Component<OverlayLoaderProps> {
  render(): ReactElement {
    const { bg, loaderText } = this.props;
    return (
      <>
        <CustomLoader bg={bg}>
          {loaderText && (
            <DsStack textAlign={"center"} gap={2}>
              <DsTypography variant="headingBoldSmall">
                {loaderText?.heading}
              </DsTypography>
              <DsTypography variant="bodyRegularMedium">
                {loaderText?.subText}
              </DsTypography>
            </DsStack>
          )}
        </CustomLoader>
      </>
    );
  }
}

export default OverlayLoader;
export type WithLoaderProps = {
  setLoading: (isLoading: boolean) => void;
};

export const Loader = () => {
  return (
    <>
      <DsLoader
        ds-variant="threeDot"
        backdrop={true}
        BackdropProps={{
          open: true,
          style: {
            backgroundColor: "#ffffff9c",
          },
        }}
        color="actionSecondary"
      />
    </>
  );
};

export const CustomLoader = ({ bg, children }) => {
  return (
    <DsBox
      // height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
      bgcolor={bg ? bg : "var(--ds-colour-surfaceBackground)"}
      sx={{
        width: "100%",
        position: "fixed",
        zIndex: 99999999,
        top: 0,
        left: 0,
      }}
    >
      <DsStack>
        <Loader />
        {children}
      </DsStack>
    </DsBox>
  );
};
