import { Biller } from "~/src/Redux/Billers/Model/Biller.model";
import { BillerAccount } from "~/src/Redux/Billers/Model/BillerAccount.model";
import { ComplaintType } from "~/src/Redux/Support/Model/ComplaintType.model";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import { VelocityCheckApplicableCategories } from "~/src/Pages/common/utitlity/biller.utilities";

export const minMaxAutoPayAmount = (
  min: boolean,
  biller: Biller | undefined,
  channelId: CustomerChannelEnum
): number => {
  const amount = biller?.paymentChannels.find(
    (item) => "" + item.paymentChannel === "" + CustomerChannelEnum[channelId]
  );
  return amount ? (min ? amount.minLimit : amount.maxLimit) : min ? 1 : 999999;
};

export const isAutoPayRestriced = (billerType: any): boolean => {
  const AutopayRestrictedBillerCategories = [
    "Education",
    "Loan",
    "Rental Payment",
    "FASTag",
    "Credit Card",
    "Donation",
    "Subscription",
    "LPG Cylinder",
    "DTH",
    "Mobile Prepaid",
  ];
  return AutopayRestrictedBillerCategories.some((item) => item === billerType);
};
export const isCreditCardRestriced = (billerType: string): boolean => {
  const CreditCardRestricedBillerCategories = [
    "Loan",
    "Rental Payment",
    "Credit Card",
    "Recurring Deposit",
    "Mutual Fund",
  ];
  return CreditCardRestricedBillerCategories.some(
    (item) => item === billerType
  );
};

export const isCreditCardNudgeRestricted = (billerType: string): boolean => {
  const CreditCardNudgeRestrictedcategories = [
    "Loan",
    "Credit Card",
    "Mutual Fund",
    "Mobile Prepaid",
  ];
  return CreditCardNudgeRestrictedcategories.some(
    (item) => item === billerType
  );
};

export const isCashbackEligibleCategories = (billerType: string): boolean => {
  const CashbackNudgeEligibleCategories = [
    "Mobile Postpaid",
    "Electricity",
    "DTH",
    "FASTag",
    "Broadband Postpaid",
    "Landline Postpaid",
    "Water",
    "Gas",
    "LPG Cylinder",
  ];
  return CashbackNudgeEligibleCategories.some((item) => item === billerType);
};

export const isSchedulePaymentAllowedCategoryPayeeType = (
  billerAccount: BillerAccount
): boolean => {
  //const allowedPayeeCategories = ["Credit Card", "Mobile Postpaid"];
  const allowedPayeeCategories = ["Mobile Postpaid"];
  return (
    (billerAccount.billerType === "PAYEE" ||
      billerAccount.billerType === "BOTH") &&
    allowedPayeeCategories.some((item) => item === billerAccount.billerCategory)
  );
};

export const isSchedulePaymentRestrictedCategoryBillerType = (
  billerAccount: BillerAccount
): boolean => {
  //const restrictedBillerCategories = ["Education", "Loan", "Rental Payment"];
  const restrictedBillerCategories: string[] = []; // keeping empty for now
  return (
    billerAccount.billerType === "BILLER" &&
    restrictedBillerCategories?.some(
      (item) => item === billerAccount.billerCategory
    )
  );
};

export const mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const createSuggestionType = (
  listOfValues: { name: string; value: string }[]
): ComplaintType[] => {
  const sugList: ComplaintType[] = listOfValues?.map((item) => {
    const obj = {
      id: item.value,
      complaintType: item.name,
    };
    return obj;
  });
  return sugList;
};

export const isHpGas = (billerId: string | undefined): boolean => {
  const HpLpgCyclinder = "HPCL00000NAT01";

  return HpLpgCyclinder.toLowerCase() === billerId?.toLowerCase();
};

export const getCashbackString = (
  cashbackData: any,
  manualMaximumCashback: number,
  autoPayMaximumCashback: number,
  biller: Biller | undefined,
  currTransactionCount: number,
  cashbackAmountUpto: number,
  nextMilestoneTxnCount?: number
) => {
  console.log("nextMilestoneTxnCount", nextMilestoneTxnCount);
  let cashbackStatus = "";
  if (
    cashbackData?.AUTO.length &&
    cashbackData?.MANUAL.length &&
    !isAutoPayRestriced((biller && biller?.billerCategory) || "")
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${manualMaximumCashback}
              for this payment, and Up to ₹${autoPayMaximumCashback} if you opt
              for autopay. Subject to `;
  }
  if (
    cashbackData?.AUTO.length &&
    !cashbackData?.MANUAL.length &&
    !isAutoPayRestriced((biller && biller?.billerCategory) || "")
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${autoPayMaximumCashback}
               if you opt for autopay. Subject to `;
  }
  if (cashbackData?.MANUAL.length && !cashbackData?.AUTO.length) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${manualMaximumCashback}
              for this payment. Subject to `;
  }
  if (
    cashbackData?.MANUAL.length &&
    isAutoPayRestriced(biller && biller?.billerCategory)
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${manualMaximumCashback}
              for this payment. Subject to `;
  }
  if (
    !cashbackData?.MANUAL.length &&
    cashbackData?.AUTO.length &&
    cashbackData?.futureManualMilestone.length &&
    !isAutoPayRestriced((biller && biller?.billerCategory) || "") &&
    (currTransactionCount === 1 || currTransactionCount === 3)
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${cashbackAmountUpto}
              for your next payment, and Up to ₹${autoPayMaximumCashback} if you opt
              for autopay. Subject to `;
  }
  if (
    !cashbackData?.MANUAL.length &&
    !cashbackData?.AUTO.length &&
    cashbackData?.futureManualMilestone.length &&
    !isAutoPayRestriced((biller && biller?.billerCategory) || "") &&
    (currTransactionCount === 1 || currTransactionCount === 3)
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${cashbackAmountUpto}
              for your next payment. Subjected to`;
  }
  if (
    !cashbackData?.MANUAL.length &&
    !cashbackData?.AUTO.length &&
    cashbackData?.futureManualMilestone.length &&
    isAutoPayRestriced((biller && biller?.billerCategory) || "") &&
    (currTransactionCount === 1 || currTransactionCount === 3)
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${cashbackAmountUpto}
              for your next payment. Subjected to`;
  }
  if (
    !cashbackData?.MANUAL.length &&
    cashbackData?.AUTO.length &&
    cashbackData?.futureManualMilestone.length &&
    isAutoPayRestriced((biller && biller?.billerCategory) || "") &&
    (currTransactionCount === 1 || currTransactionCount === 3)
  ) {
    cashbackStatus = `You are eligible for a cashback of Up to ₹${cashbackAmountUpto}
              for your next payment. Subjected to`;
  }
  return cashbackStatus;
};

/**
 * TO CALCULATE WHETHER THE REGISTRATION HAS COMPLETED 24 hrs
 */
export const isRegistrationInTimeFrame = (
  billerRegistrationDate: Date | undefined,
  billerCategory: string | undefined
): boolean => {
  let isRegistrationDoneWithinTimeFrame: boolean;
  const billerRegDate = billerRegistrationDate;
  const regDate = new Date(billerRegDate ? billerRegDate : "");
  const currentDate = new Date();
  const differenceInCurrentAndRegistration =
    currentDate.getTime() - regDate.getTime();
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  if (
    differenceInCurrentAndRegistration < twentyFourHoursInMilliseconds &&
    billerCategory &&
    VelocityCheckApplicableCategories.includes(billerCategory.toLowerCase())
  ) {
    isRegistrationDoneWithinTimeFrame = true;
  } else {
    isRegistrationDoneWithinTimeFrame = false;
  }

  return isRegistrationDoneWithinTimeFrame;
};
