import { ModelMapper } from "~/src/Lib/types/modelmapper";
import parse from "date-fns/parse";
import {
  BillDetail,
  BillDetailDto,
  BillDetailMapper,
} from "~/src/Redux/Billers/Model/BillDetail.model";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import {
  AuthenticatorValue,
  AuthenticatorValueDto,
} from "~/src/Redux/Billers/Model/BillerAccount.model";

export interface PaymentAccountDto {
  paymentaccountid?: string;
  //customerid?: string;
  //short_name?: string;
  payment_method: PaymentMethodEnum;
  card_start?: string;
  card_end?: string;
  cardholder_name?: string;
  mmid?: string; //imps
  mobileno?: string; //wallet
  wallet_name?: string; //wallet
  ifsc?: string; //bank
  account_number?: string; //bank
  enc_card_number?: string; //catd
  enc_card_expiry?: string; //card
  cvv?: string; //card
  card_account_number?: string; // card
  //account_balance?: string; //any
  //currency?: string; //any
  cardExpiryDate?: string;
  cardName?: string;
  cardNumber?: string;
  maskedCardNumber?: string;
  primeCardSerialNumber?: number;
}

export interface PaymentAccount {
  paymentAccountId?: string;
  //customerId?: string;
  //shortName?: string;
  paymentMethod: PaymentMethodEnum;
  accountNumber?: string; // NOTE these might change based on payment method
  cardStart?: string;
  cardEnd?: string;
  cardholderName?: string;
  mmid?: string; //imps
  mobileNo?: string; //wallet
  walletName?: string; //wallet
  ifsc?: string; //bank
  encCardNumber?: string; //catd
  encCardExpiry?: string; //card
  cvv?: string; //card
  cardAccountNumber?: string; // card
  //accountBalance?: string; //any
  //currency?: string; //any
  cardExpiryDate?: string;
  cardName?: string;
  cardNumber?: string;
  maskedCardNumber?: string;
  primeCardSerialNumber?: number;
}

export enum PaymentMethodEnum {
  BankAccount = "BankAccount",
  DebitCard = "DebitCard",
  CreditCard = "CreditCard",
  PrepaidCard = "PrepaidCard",
  IMPS = "IMPS",
  Cash = "Cash",
  UPI = "UPI",
  Wallet = "Wallet",
  NEFT = "NEFT",
}

export class PaymentAccountMapper
  implements ModelMapper<PaymentAccountDto, PaymentAccount>
{
  toModel(dto: PaymentAccountDto): PaymentAccount {
    return {
      paymentAccountId: dto.paymentaccountid,
      //customerId: dto.customerid,
      //shortName: dto.short_name,
      paymentMethod: dto.payment_method,
      accountNumber: dto.account_number,
      cardStart: dto.card_start,
      cardEnd: dto.card_end,
      cardholderName: dto.cardholder_name,
      mmid: dto.mmid,
      mobileNo: dto.mobileno,
      walletName: dto.wallet_name,
      ifsc: dto.ifsc,
      encCardNumber: dto.enc_card_number,
      encCardExpiry: dto.enc_card_expiry,
      cvv: dto.cvv,
      cardAccountNumber: dto.card_account_number,
      //accountBalance: dto.account_balance,
      //currency: dto.currency,
      cardExpiryDate: dto.cardExpiryDate,
      cardName: dto.cardName,
      cardNumber: dto.cardNumber,
      maskedCardNumber: dto.maskedCardNumber,
      primeCardSerialNumber: dto.primeCardSerialNumber,
    } as PaymentAccount;
  }
  toDto(model: PaymentAccount): PaymentAccountDto {
    return {
      paymentaccountid: model.paymentAccountId,
      //customerid: model.customerId,
      //short_name: model.shortName,
      payment_method: model.paymentMethod,
      card_start: model.cardStart,
      card_end: model.cardEnd,
      cardholder_name: model.cardholderName,
      mmid: model.mmid,
      mobileno: model.mobileNo,
      wallet_name: model.walletName,
      ifsc: model.ifsc,
      account_number: model.accountNumber,
      enc_card_number: model.encCardNumber,
      enc_card_expiry: model.encCardExpiry,
      cvv: model.cvv,
      card_account_number: model.cardAccountNumber,
      //account_balance: model.accountBalance,
      //currency: model.currency,
      cardExpiryDate: model.cardExpiryDate,
      cardName: model.cardName,
      cardNumber: model.cardNumber,
      maskedCardNumber: model.maskedCardNumber,
      primeCardSerialNumber: model.primeCardSerialNumber,
    };
  }
}

// Payment Account ------------------------------------------------------------------------ END

// Payment DETAIL ------------------------------------------------------------------------ START
export enum PaymentTypeEnum {
  instapay = "instapay",
  billpay = "billpay",
  adhoc = "adhoc",
}
export enum BillerPaymentStatusEnum {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export enum PaymentStatusEnum {
  PAID = "PAID",
  FAILED = "FAILED",
  SCHEDULED = "SCHEDULED",
  REJECTED = "REJECTED",
  DELIVERED = "DELIVERED",
  PENDING = "PENDING",
}

export interface PaymentDetailDto {
  paymentid: string;
  sourceid: string;
  objectid: string;
  billerid: string;
  biller_name: string;
  biller_category: string;
  customerid: string;
  billeraccountid: string;
  short_name: string;
  authenticators?: {
    parameter_name: string;
    value: string;
  }[]; //
  validationid?: string;
  billlist?: BillDetailDto[];
  payment_type: PaymentTypeEnum;
  currency: string;
  payment_amount: string;
  cou_conv_fee: string;
  bou_conv_fee: string;
  debit_amount: string; // payment amount + cou_conv_fee
  payment_account: PaymentAccountDto; //
  payment_remarks: string;
  source_ref_no?: string;
  bbps_ref_no?: string;
  txn_date_time: string;
  schedule_date?: string;
  biller_status: BillerPaymentStatusEnum;
  payment_status: PaymentStatusEnum;
  biller_logo?: string;
  billerLogo?: string;
  additional_info?: AuthenticatorValueDto[];
}

export interface PaymentDetail {
  paymentId: string;
  sourceId: string;
  billerId: string;
  billerName: string;
  billerCategory: string;
  customerId: string;
  billerAccountId: string;
  shortName: string;
  authenticators?: {
    parameterName: string;
    value: string;
  }[];
  validationId?: string;
  billList?: BillDetail[];
  paymentType: PaymentTypeEnum;
  currency: string;
  paymentAmount: number;
  couConvFee: number;
  bouConvFee: number;
  debitAmount: number; // payment amount + cou_conv_fee
  paymentAccount: PaymentAccount; //
  paymentRemarks?: string;
  sourceRefNo: string;
  bbpsRefNo: string;
  transactionTime: Date;
  scheduleDate: Date;
  billerStatus: BillerPaymentStatusEnum;
  paymentStatus: PaymentStatusEnum;
  billerLogo: string;
  sortDate: Date;
  additionalInfo?: AuthenticatorValue[];
}

export class PaymentDetailMapper
  implements ModelMapper<PaymentDetailDto, PaymentDetail>
{
  toModel(dto: PaymentDetailDto): PaymentDetail {
    const billDetailMapper = new BillDetailMapper();
    const paymentAccountMapper = new PaymentAccountMapper();
    return {
      paymentId: dto.paymentid,
      sourceId: dto.sourceid,
      billerId: dto.billerid,
      billerName: dto.biller_name,
      billerLogo: dto.biller_logo || dto.billerLogo,
      billerCategory: dto.biller_category,
      customerId: dto.customerid,
      billerAccountId: dto.billeraccountid,
      shortName: dto.short_name,
      authenticators: dto.authenticators?.map((auth) => ({
        parameterName: auth.parameter_name,
        value: auth.value,
      })),
      validationId: dto.validationid,
      billList: dto.billlist?.map((bill) => billDetailMapper.toModel(bill)),
      paymentType: dto.payment_type,
      currency: dto.currency,
      paymentAmount: Number.parseFloat(dto.payment_amount),
      couConvFee: Number.parseFloat(dto.cou_conv_fee),
      bouConvFee: Number.parseFloat(dto.bou_conv_fee),
      debitAmount: Number.parseFloat(dto.debit_amount), // payment amount + cou_conv_fee
      paymentAccount: paymentAccountMapper.toModel(dto.payment_account),
      paymentRemarks: dto.payment_remarks,
      sourceRefNo: dto.source_ref_no,
      bbpsRefNo: dto.bbps_ref_no,
      transactionTime: parse(
        dto.txn_date_time,
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      ),
      scheduleDate: dto.schedule_date
        ? parse(dto.schedule_date, "dd-MM-yyyy", new Date())
        : undefined,
      billerStatus: dto.biller_status,
      paymentStatus: dto.payment_status,
      sortDate: dto.txn_date_time
        ? parse(dto.txn_date_time, "dd-MM-yyyy HH:mm:ss", new Date())
        : dto.schedule_date
        ? parse(dto.schedule_date, "dd-MM-yyyy", new Date())
        : new Date(), // NOTE: Fallback unlikely scenario
      additionalInfo: dto.additional_info?.map((auth) => ({
        parameterName: auth.parameter_name,
        value: auth.value,
      })),
    } as PaymentDetail;
  }
  toDto(model: PaymentDetail): PaymentDetailDto {
    throw new Error(`Method not implemented. for ${JSON.stringify(model)}`);
  }
}

// Payment DETAIL ------------------------------------------------------------------------ END

export interface MakePaymentRequestDto {
  billerName: string;
  customerid: string;
  mobile: string;
  email: string;
  billid?: string;
  billeraccountid?: string;
  billerid?: string;
  authenticators?: {
    parameter_name: string;
    value: string;
  }[]; //
  validationid?: string;
  payment_type: PaymentTypeEnum;
  currency: string; // "356";
  payment_amount: string;
  cou_conv_fee: string;
  bou_conv_fee: string;
  debit_amount: string;
  payment_remarks?: string;
  payment_account: PaymentAccountDto; //
  authorization_type?: string;
  schedule_date?: string;
  channelName: "IB" | "MB";
  customerType?: string;
}

// interface MakePaymentRequest {
//   customerid: string;
//   mobile: string;
//   billId?: string;
//   billerAccountId?: string;
//   billerId?: string;
//   authenticators?: {
//     parameter_name: string;
//     value: string;
//   }[]; //
//   validationId?: string;
//   paymentType: PaymentTypeEnum;
//   currency: string; // "356";
//   paymentAmount: string;
//   couConvFee: string;
//   bouConvFee: string;
//   debitAmount: string;
//   paymentRemarks?: string;
//   paymentAccount: PaymentAccount; //
// }

export const generateMakePaymentReq = (
  billerName: string,
  channelId: CustomerChannelEnum,
  customerId: string,
  mobile: string,
  paymentAccount: PaymentAccount,
  paymentAmount: number,
  couConvFee?: number,
  bouConvFee?: number,
  billId?: string,
  billerAccountId?: string,
  validationId?: string,
  billerId?: string,
  authenticator?: {
    parameterName: string;
    value: string;
  }[],
  scheduledDate?: Date | null,
  emailId?: string,
  billerType?: boolean,
  billerCategory?: string,
  offerId?: string,
  cashbackAmount?: number,
  isCreditCardSelected?: boolean,
  customerType?: string,
  promoCodeId?: string
  // customerDetails?: {
  //   isCitiCustomer: boolean;
  //   isAxisCustomer: boolean;
  // },
): MakePaymentRequestDto => {
  return {
    billerName,
    customerid: customerId,
    mobile: mobile,
    email: emailId ? emailId : "",
    billid: billerId !== "WEST00000WBL75" && validationId ? undefined : billId,
    billeraccountid: billerAccountId,
    billerid: billerId,
    authenticators: authenticator?.map((a) => ({
      parameter_name: a.parameterName,
      value: a.value,
    })), //
    validationid: validationId,
    payment_type: getValidationType(billId, validationId),
    currency: "356",
    payment_amount: Number(paymentAmount).toFixed(2),
    cou_conv_fee: couConvFee ? couConvFee.toFixed(2) : "0.00",
    bou_conv_fee: bouConvFee ? bouConvFee.toFixed(2) : "0.00",
    debit_amount: (
      Number(paymentAmount) +
      (couConvFee ? couConvFee : 0) +
      (bouConvFee ? bouConvFee : 0)
    ).toFixed(2),
    // payment_remarks?: string,
    payment_account: new PaymentAccountMapper().toDto(paymentAccount),
    schedule_date: scheduledDate
      ? scheduledDate?.toLocaleDateString("en-GB").split("/").join("-")
      : undefined,
    authorization_type: scheduledDate ? "schedule" : undefined,
    channelName: channelId === CustomerChannelEnum.MobileBanking ? "MB" : "IB",
    billerType: billerType ? "BBPS" : "NONBBPS",
    billerCategory: billerCategory,
    offerId: offerId && !isCreditCardSelected ? offerId : undefined,
    cashbackAmount: cashbackAmount,
    auth_code: isCreditCardSelected ? "0013" : undefined,
    customerType: customerType ? customerType : "",
    promoCodeId: promoCodeId ? promoCodeId : undefined,
    // customerDetails,
  } as MakePaymentRequestDto;
};

const getValidationType = (
  billId?: string,
  validationId?: string
): PaymentTypeEnum => {
  if (validationId) return PaymentTypeEnum.instapay;
  if (billId) return PaymentTypeEnum.billpay;
  return PaymentTypeEnum.adhoc;
};
