import traceActionsCreator from "../traceActionsCreator";

export const getCoachMarksDetail = "coachMarksDetail";

export const getCoachMarksDetailAction = traceActionsCreator(
  "coachMarksDetail/get"
);

export const processCoachMarksDetailAction = traceActionsCreator(
  "coachMarksDetail/process"
);
