import ChannelStore from "~/src/Common/utitlity/ChannelStore";
import AppStore from "~/src/Configurations/AppStore";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";
import { AuthenticatorValue } from "../Model//BillerAccount.model";
import { getvalidateBill } from "../Actions/ValidateBill.action";
import { getRegisterBillerDetailsListDataAction } from "../Actions/RegisteBiller.action";
import { PaymentAccount } from "../../Payment/Model/PaymentDetail.model";
import { BillerAccountMapper } from "../Model/BillerAccount.model";
import { ValidatePaymentMapper } from "../Model/ValidatePayment.model";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { AgentDetails } from "~/src/Redux/Billers/Model/BillerAccount.model";

type TRegisterBillerListService = {
  billerId: string;
  authenticators: AuthenticatorValue[];
  shortName?: string;
  additionalValidation?: AuthenticatorValue[];
  agentDetails?: AgentDetails;
  autopayStatus?: boolean;
  autopayAmount?: string;
  currency?: string;
  paymentAccount?: PaymentAccount;
  isCreditCardSelected?: boolean;
};

async function registerBillerListService({
  billerId,
  authenticators,
  shortName,
  additionalValidation,
  agentDetails,
  autopayStatus,
  autopayAmount,
  currency,
  paymentAccount,
  isCreditCardSelected,
}: TRegisterBillerListService) {
  try {
    const state = AppStore.getState();
    const options = {
      url: URL.BILLER.REGISTER_BILLER,
      method: "POST",
      data: {
        customerid: state.customerDetail.customerId,
        mobile: state.customerDetail.customerDetail.data?.mobile,
        email: state.customerDetail.customerDetail.data?.emailId,
        billerid: billerId,
        authenticators: authenticators.map((auth) => ({
          parameter_name: auth.parameterName,
          value: auth.value,
          encryption_required: auth.encryptionRequired ? "Y" : undefined,
        })),
        short_name: shortName,
        additional_validation_details:
          additionalValidation && additionalValidation.length
            ? additionalValidation?.map((auth) => ({
                parameter_name: auth.parameterName,
                value: auth.value,
              }))
            : undefined,
        autopay_status: autopayStatus ? "Y" : undefined,
        autopay_amount: autopayAmount ? autopayAmount : undefined,
        currency: currency ? "356" : undefined,
        payment_account: paymentAccount
          ? {
              payment_method: paymentAccount.paymentMethod
                ? paymentAccount.paymentMethod
                : undefined,
              account_number: paymentAccount.accountNumber
                ? paymentAccount.accountNumber
                : undefined,
              ifsc: paymentAccount.ifsc ? paymentAccount.ifsc : undefined,
              card_start: paymentAccount.cardStart
                ? paymentAccount.cardStart
                : undefined,
              card_end: paymentAccount.cardEnd
                ? paymentAccount.cardEnd
                : undefined,
              card_account_number: paymentAccount.primeCardSerialNumber
                ? `${paymentAccount.primeCardSerialNumber}`
                : undefined,
            }
          : undefined,
        onhold: true,
        channelName: ChannelStore.get("channel") || "",
        agent_details: agentDetails,
        auth_code: isCreditCardSelected ? "0013" : undefined,
      },
    };
    const response = await asHttp.request(options);
    const mapper = new BillerAccountMapper();
    const mappedData = mapper.toModel(response.data.data);
    return mappedData;
  } catch (error) {
    throw error;
  }
}

export const registerBillerListThunk = serviceActionCreator(
  getRegisterBillerDetailsListDataAction,
  registerBillerListService
);

type TValidatateBillListService = {
  billerId: string;
  authenticators: AuthenticatorValue[];
  shortName?: string;
  agentDetails?: AgentDetails;
  paymentAmount?: number;
  currency?: number;
  additionalValidation?: AuthenticatorValue[];
};

async function validateBillListService({
  billerId,
  authenticators,
  shortName,
  agentDetails,
  paymentAmount,
  currency,
  additionalValidation,
}: TValidatateBillListService) {
  try {
    const state = AppStore.getState();
    const options = {
      url: URL.BILLER.BILL_VALIDATE_AND_REGISTER,
      method: "POST",
      data: {
        customerid: state.customerDetail.customerId,
        mobile: state.customerDetail.customerDetail.data.mobile,
        email: state.customerDetail.customerDetail.data.emailId,
        billerid: billerId,
        authenticators: authenticators.map((auth) => ({
          parameter_name: auth.parameterName,
          value: auth.value,
        })),
        short_name: shortName,
        agent_details: agentDetails,
        currency: currency || paymentAmount ? 356 : undefined,
        payment_amount: paymentAmount?.toFixed(2),
        additional_validation_details: additionalValidation
          ? additionalValidation?.map((auth) => ({
              parameter_name: auth.parameterName,
              value: auth.value,
            }))
          : undefined,
        channelName: ChannelStore.get("channel") || "",
      },
    };

    const response = await asHttp.request(options);
    const mapper = new ValidatePaymentMapper();
    const mappedData = mapper.toModel(response.data.data);
    return mappedData;
  } catch (error) {
    throw error;
  }
}

export const validateBillListThunk = serviceActionCreator(
  getvalidateBill,
  validateBillListService
);
