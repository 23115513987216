import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "../ServiceTracker/Selectors";
import { getBannersDataConfigName } from "./Actions";

export const SLICE_NAME = "banners";

export const getBannerLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getBannersDataConfigName]);

export const getBannerSuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getBannersDataConfigName]);

export const getBannerErrorSelector = (state: RootState) =>
  isServiceError(state, [getBannersDataConfigName]);
