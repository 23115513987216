import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/PromoCode/Selectors/PromoCodeOffers.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

import { getPromoCodeOffersDataAction } from "~/src/Redux/PromoCode/Actions/PromoCodeOffers.action";
import { PromoCodeOffersData } from "~/src/Redux/PromoCode/Model/PromoCodeOffers.model";

export type PromoCodeOffersListState = {
  promoCodesOffersData: IRemoteData<PromoCodeOffersData[]>;
};

type PromoCodesOfferActionType = {
  getPromoCodeOfferDetails: (
    customerId: string,
    fetchRegistration: boolean,
    billerCategory?: string,
    manualBillAmount?: number
  ) => Promise<PromoCodeOffersData[]>;
  setPromoCodeOfferStatusNotLoaded: () => void;
};

const initialState: PromoCodeOffersListState = {
  promoCodesOffersData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setPromoCodeOfferStatusNotLoaded(state, action: PayloadAction) {
      state.promoCodesOffersData.status = DataStatus.NOT_LOADED;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getPromoCodeOffersDataAction.success,
      (state: PromoCodeOffersListState, action) => {
        state.promoCodesOffersData = state.promoCodesOffersData.setData(
          action.payload
        );
      }
    );

    builder.addCase(
      getPromoCodeOffersDataAction.error,
      (state: PromoCodeOffersListState, action) => {
        state.promoCodesOffersData = state.promoCodesOffersData.setError(
          action.payload
        );
      }
    );

    builder.addCase(
      getPromoCodeOffersDataAction.loading,
      (state: PromoCodeOffersListState) => {
        state.promoCodesOffersData = state.promoCodesOffersData.setLoading();
      }
    );
  },
};

const promoCodeOffersListSlice = createSlice(sliceOptions);

export default promoCodeOffersListSlice.reducer;

export const { setPromoCodeOfferStatusNotLoaded } =
  promoCodeOffersListSlice.actions;
