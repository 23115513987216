import { ModelMapper } from "~/src/Lib/types/modelmapper";
import parse from "date-fns/parse";
import { CustomerChannelEnum } from "./Customer.reducer";

export interface CustomerDetailDto {
  customerId: string;
  channelId: string;
  mobileNo: string;
  emailId: string;
  timestamp: string;
  maintenance: string;
  accountSchemeCodes: string[];
  customerType: string;
  clevertapId: string;
  // clevertapId: string;
  billeraccountid: string;
  category: string;
}

export interface CustomerDetail {
  customerId: string;
  channelId: CustomerChannelEnum;
  mobile: string;
  emailId: string;
  timestamp: Date;
  maintenance: string;
  accountSchemeCodes: string[];
  customerType: string;
  clevertapId: string;
  // clevertapId: string;
  billeraccountid: string;
  category: string;
}

export class CustomerDetailMapper
  implements ModelMapper<CustomerDetailDto, CustomerDetail>
{
  toModel(dto: CustomerDetailDto): CustomerDetail {
    return {
      customerId: dto.customerId,
      channelId:
        dto.channelId === "407"
          ? CustomerChannelEnum.InternetBanking
          : CustomerChannelEnum.MobileBanking,
      mobile: dto.mobileNo,
      emailId: dto.emailId,
      timestamp: parse(dto.timestamp, "dd-MM-yyyy HH:mm:ss", new Date()),
      maintenance: dto.maintenance,
      accountSchemeCodes: dto.accountSchemeCodes,
      customerType: dto.customerType,
      clevertapId: dto.clevertapId,
      // clevertapId: dto.clevertapId,
      billeraccountid: dto.billeraccountid,
      category: dto.category,
    } as CustomerDetail;
  }
  toDto(model: CustomerDetail): CustomerDetailDto {
    throw new Error("Method not implemented." + model);
  }
}
