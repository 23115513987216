import { ModelMapper } from "~/src/Lib/types/modelmapper";

interface OfferInfoData {
  amountOrPercentageFlag: string;
  billerCategory: string;
  billerId: [];
  billerIdNotIncluded: [];
  billerType: string;
  cashbackDisplayAmount: number;
  fixAmount: number;
  fixPercentage: number;
  frequencyPerUser: number;
  maximumCashbackAmount: number;
  minimumBillAmount: number;
  offerEligibleDate: Date;
  offerCreatedBy: string;
  offerDescription: string;
  offerEndDate: Date;
  offerId: string;
  offerOnlyOnBillerId: boolean;
  offerStartDate: Date;
  offerStatus: string;
  onlyForNewUser: boolean;
  onlyForNewUserOfCategory: boolean;
  paymentType: string;
  processAfterMilestoneCompletion: boolean;
  schemeCodes: [];
  _id: string;
}
export interface AutoOffersData {
  cashbackAmount: number;
  numOfTimeOfferAvailed: number;
  numOfTimeOfferCanAvail: number;
  offerInfo: OfferInfoData;
}
export interface ManualOffersData {
  cashbackAmount: number;
  numOfTimeOfferAvailed: number;
  numOfTimeOfferCanAvail: number;
  offerInfo: OfferInfoData;
}

export interface FutureManualMilestoneData {
  cashbackAmountUpto: number;
  currTransactionCount: number;
  nextMilestoneTxnCount: number;
}
export interface CashbackActiveOffersData {
  AUTO: AutoOffersData[];
  MANUAL: ManualOffersData[];
  futureManualMilestone: FutureManualMilestoneData[];
}
interface OfferInfoDto {
  amountOrPercentageFlag: string;
  billerCategory: string;
  billerId: [];
  billerIdNotIncluded: [];
  billerType: string;
  cashbackDisplayAmount: number;
  fixAmount: number;
  fixPercentage: number;
  frequencyPerUser: number;
  maximumCashbackAmount: number;
  minimumBillAmount: number;
  offerEligibleDate: Date;
  offerCreatedBy: string;
  offerDescription: string;
  offerEndDate: Date;
  offerId: string;
  offerOnlyOnBillerId: boolean;
  offerStartDate: Date;
  offerStatus: string;
  onlyForNewUser: boolean;
  onlyForNewUserOfCategory: boolean;
  paymentType: string;
  processAfterMilestoneCompletion: boolean;
  schemeCodes: [];
  _id: string;
}
interface AutoOffersDto {
  cashbackAmount: number;
  numOfTimeOfferAvailed: number;
  numOfTimeOfferCanAvail: number;
  offerInfo: OfferInfoDto;
}
interface ManualOffersDto {
  cashbackAmount: number;
  numOfTimeOfferAvailed: number;
  numOfTimeOfferCanAvail: number;
  offerInfo: OfferInfoDto;
}
interface FutureManualMilestoneDto {
  cashbackAmountUpto: number;
  currTransactionCount: number;
  nextMilestoneTxnCount: number;
}
export interface CashbackActiveOffersDataDto {
  AUTO: AutoOffersDto[];
  MANUAL: ManualOffersDto[];
  futureManualMilestone: FutureManualMilestoneDto[];
}

export class CashbackActiveOffersDataMapper
  implements ModelMapper<CashbackActiveOffersDataDto, CashbackActiveOffersData>
{
  toModel(dto: CashbackActiveOffersDataDto): CashbackActiveOffersData {
    return {
      AUTO: dto.AUTO
        ? dto.AUTO.map((offer) => ({
            numOfTimeOfferCanAvail: offer.numOfTimeOfferCanAvail,
            numOfTimeOfferAvailed: offer.numOfTimeOfferAvailed,
            cashbackAmount: offer.cashbackAmount,
            offerInfo: offer.offerInfo,
          }))
        : [],
      MANUAL: dto.MANUAL
        ? dto.MANUAL.map((offer) => ({
            numOfTimeOfferCanAvail: offer.numOfTimeOfferCanAvail,
            numOfTimeOfferAvailed: offer.numOfTimeOfferAvailed,
            cashbackAmount: offer.cashbackAmount,
            offerInfo: offer.offerInfo,
          }))
        : [],
      futureManualMilestone: dto.futureManualMilestone
        ? dto.futureManualMilestone.map((milestone) => ({
            cashbackAmountUpto: milestone.cashbackAmountUpto,
            currTransactionCount: milestone.currTransactionCount,
            nextMilestoneTxnCount: milestone.nextMilestoneTxnCount,
          }))
        : [],
    };
  }
  toDto(model: CashbackActiveOffersData): CashbackActiveOffersDataDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
