import AppStore, { RootState } from "~/src/Configurations/AppStore";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";
import { getHistoryListDataAction } from "../Actions";
import { PaymentDetailDto, PaymentDetailMapper } from "../Model";

type payload = {
  customerId: string;
  oneYearData: boolean;
  shortName?: string;
};

async function Service(req: payload) {
  const { customerId, oneYearData, shortName } = req;

  const state: RootState = AppStore.getState();
  const custId =
    state?.customerDetail?.customerDetail?.data?.customerId || customerId;

  if (custId && custId.length > 0) {
    const options = {
      url: URL.BILLER.PAYMENT_HISTORY,
      method: "POST",
      data: {
        customerid: custId,
        oneYearData: oneYearData,
        short_name: shortName,
      },
    };

    try {
      const response = await asHttp.request(options);
      const { data: body } = response;
      const { data } = body;
      const mapper = new PaymentDetailMapper();
      const mappedData = data.map((paymentDetails: PaymentDetailDto) =>
        mapper.toModel(paymentDetails)
      );
      return mappedData;
    } catch (err) {
      throw err;
    }
  }
}

export const getPaymentHistoryListThunk = serviceActionCreator(
  getHistoryListDataAction,
  Service
);
