import ChannelStore from "~/src/Common/utitlity/ChannelStore";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";
import { getvalidatePayment } from "../Actions/ValidatePayment.action";
import { ValidatePaymentMapper } from "../Model/ValidatePayment.model";
import { AuthenticatorValue } from "../Model//BillerAccount.model";
import { asHttp } from "~/src/Configurations/WebHttp";
import AppStore from "~/src/Configurations/AppStore";
import { URL } from "~/src/Constants/API_URLS";

type TService = {
  billerId: string;
  authenticators: AuthenticatorValue[];
  paymentAmount?: number;
  currency?: number;
  additionalValidation?: AuthenticatorValue[];
};

async function Service({
  billerId,
  authenticators,
  paymentAmount,
  currency,
  additionalValidation,
}: TService) {
  try {
    const state = AppStore.getState();
    const options = {
      url: URL.BILLER.BILL_VALIDATE,
      method: "POST",
      data: {
        customerid: state.customerDetail.customerId,
        mobile: state.customerDetail.customerDetail.data.mobile,
        email: state.customerDetail.customerDetail.data.emailId,
        billerid: billerId,
        authenticators: authenticators.map((auth) => ({
          parameter_name: auth.parameterName,
          value: auth.value,
        })),
        currency: currency || paymentAmount ? 356 : undefined,
        payment_amount: paymentAmount?.toFixed(2),
        additional_validation_details: additionalValidation
          ? additionalValidation?.map((auth) => ({
              parameter_name: auth.parameterName,
              value: auth.value,
            }))
          : undefined,
        channelName: ChannelStore.get("channel") || "",
      },
    };
    const response = await asHttp.request(options);
    const mappedData = new ValidatePaymentMapper().toModel(response.data.data);

    return mappedData;
  } catch (err) {
    throw err;
  }
}

export const validatePayment = serviceActionCreator(
  getvalidatePayment,
  Service
);
