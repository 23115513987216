import { ModelMapper } from "~/src/Lib/types/modelmapper";

export interface PromoCodeOffersDto {
  id: string;
  amountOrPercentageFlag: string;
  minimumBillAmount: number;
  billerCategory: string[];
  promoCodeOnlyOnBillerId: boolean;
  billerId: [];
  billerIdNotIncluded: [];
  billerType: string;
  frequencyPerUser: number;
  promoCode: string;
  redirectUrl: string;
  imageUrl: string;
  promoCodeStatus: string;
  promoCodeDescription: string;
  promoCodeStartDate: Date;
  promoCodeEndDate: Date;
  promoCodeCreatedBy: string;
  isApplicableWithPrevTransactionsInCategory: boolean;
  isApplicableForNewUsers: boolean;
  isApplicableForNewUserOfCategory: boolean;
  modeOfPaymentForPromoCode: string[];
  cashbackDisplayAmount: number;
  cashbackDisplayPercentage: number;
  _id: string;
}

export interface PromoCodeOffersData {
  id: string;
  amountOrPercentageFlag: string;
  minimumBillAmount: number;
  billerCategory: string[];
  promoCodeOnlyOnBillerId: boolean;
  billerId: [];
  billerIdNotIncluded: [];
  billerType: string;
  frequencyPerUser: number;
  promoCode: string;
  redirectUrl: string;
  imageUrl: string;
  promoCodeStatus: string;
  promoCodeDescription: string;
  promoCodeStartDate: Date;
  promoCodeEndDate: Date;
  promoCodeCreatedBy: string;
  isApplicableWithPrevTransactionsInCategory: boolean;
  isApplicableForNewUsers: boolean;
  isApplicableForNewUserOfCategory: boolean;
  modeOfPaymentForPromoCode: string[];
  cashbackDisplayAmount: number;
  cashbackDisplayPercentage: number;
  _id: string;
}

export class PromoCodeOffersDataMapper
  implements ModelMapper<PromoCodeOffersDto, PromoCodeOffersData>
{
  toModel(dto: PromoCodeOffersDto): PromoCodeOffersData {
    return {
      _id: dto._id,
      id: dto.id,
      amountOrPercentageFlag: dto.amountOrPercentageFlag,
      minimumBillAmount: dto.minimumBillAmount,
      billerCategory: dto.billerCategory,
      promoCodeOnlyOnBillerId: dto.promoCodeOnlyOnBillerId,
      billerId: dto.billerId,
      billerIdNotIncluded: dto.billerIdNotIncluded,
      billerType: dto.billerType,
      frequencyPerUser: dto.frequencyPerUser,
      promoCode: dto.promoCode,
      redirectUrl: dto.redirectUrl,
      imageUrl: dto.imageUrl,
      promoCodeStatus: dto.promoCodeStatus,
      promoCodeDescription: dto.promoCodeDescription,
      promoCodeStartDate: dto.promoCodeStartDate,
      promoCodeEndDate: dto.promoCodeEndDate,
      promoCodeCreatedBy: dto.promoCodeCreatedBy,
      isApplicableWithPrevTransactionsInCategory:
        dto.isApplicableWithPrevTransactionsInCategory,
      isApplicableForNewUsers: dto.isApplicableForNewUsers,
      isApplicableForNewUserOfCategory: dto.isApplicableForNewUserOfCategory,
      modeOfPaymentForPromoCode: dto.modeOfPaymentForPromoCode,
      cashbackDisplayAmount: dto.cashbackDisplayAmount,
      cashbackDisplayPercentage: dto.cashbackDisplayPercentage,
    };
  }
  toDto(model: PromoCodeOffersData): PromoCodeOffersDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
