import { ModelMapper } from '~/src/Lib/types/modelmapper'

import { parse } from 'date-fns'
import _ from 'lodash'
import { BillDetail, BillDetailDto, BillDetailMapper } from './BillDetail.model'
import {
  AuthenticatorValue,
  AuthenticatorValueDto
} from './BillerAccount.model'

export interface ValidatePaymentDto {
  validationid: string
  validation_date: string
  valid_until: string
  sourceid: string
  customerid: string
  billerid: string
  billeraccountid?: string
  payment_amount?: string
  authenticators: AuthenticatorValueDto[]
  currency: string
  billlist?: BillDetailDto[]
  pay_multiple_bills: string
  additional_validation_details?: AuthenticatorValueDto[]
  additional_info?: AuthenticatorValueDto[]
  short_name?: string
}

export interface ValidatePayment {
  validationId: string
  validationDate: Date
  validUntil: Date
  customerId: string
  billerId: string
  billerAccountId?: string
  paymentAmount?: number
  authenticators: AuthenticatorValue[]
  currency: string
  billList?: BillDetail[]
  payMultipleBills: boolean
  additionalValidationDetails?: AuthenticatorValue[]
  additionalInfo?: AuthenticatorValue[]
  nickName?: string
  currentBill?: BillDetail
}

export class ValidatePaymentMapper
  implements ModelMapper<ValidatePaymentDto, ValidatePayment>
{
  toModel(dto: ValidatePaymentDto): ValidatePayment {
    const billMapper = new BillDetailMapper()
    return {
      validationId: dto.validationid,
      validationDate: parse(
        dto.validation_date,
        'dd-MM-yyyy HH:mm:ss',
        new Date()
      ),
      validUntil: parse(dto.valid_until, 'dd-MM-yyyy HH:mm:ss', new Date()),
      customerId: dto.customerid,
      billerId: dto.billerid,
      billerAccountId: dto.billeraccountid,
      paymentAmount: dto.payment_amount
        ? Number.parseFloat(dto.payment_amount)
        : undefined,
      authenticators: dto.authenticators.map(auth => ({
        parameterName: auth.parameter_name,
        value: auth.value
      })),
      currency: dto.currency,
      billList: dto.billlist?.map(bill => billMapper.toModel(bill)),
      payMultipleBills: dto.pay_multiple_bills === 'Y' ? true : false,
      additionalValidationDetails: dto.additional_validation_details?.map(
        auth => ({
          parameterName: auth.parameter_name,
          value: auth.value
        })
      ),
      additionalInfo: dto.additional_info?.map(auth => ({
        parameterName: auth.parameter_name,
        value: auth.value
      })),
      nickName: dto.short_name,
      currentBill:
        dto.billlist && dto.billlist.length > 0
          ? billMapper.toModel(dto.billlist[0])
          : undefined
    } as ValidatePayment
  }
  toDto(model: ValidatePayment): ValidatePaymentDto {
    throw new Error('Method not implemented.' + model)
  }
}
