import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { Biller, BillerDto, BillerMapper } from "../Model/Biller.model";
import {
  BillerAccount,
  BillerAccountDto,
  BillerAccountMapper,
} from "../Model/BillerAccount.model";
import {
  BillerStatusData,
  BillerStatusDataMapper,
  BillerStatusDto,
} from "../Model/BillerState.model";
import {
  fetchBiller,
  fetchBillerFail,
  fetchBillerSuccess,
  fetchBillerAccount,
  fetchBillerAccountFail,
  fetchBillerAccountSuccess,
  fetchBillerState,
  fetchBillerStateFail,
  fetchBillerStateSuccess,
  setBillerIdStatusNotLoaded,
} from "../Reducers/Biller.reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const BILLER_FROM_ID = `biller/find/billerid`;
const BILLERACCOUNT_FROM_ID = `biller/get-registration-detail`;
const BILLER_CHECK_STATUS = `biller/check-status`;

export const getBiller =
  (billerId: string, dataStatusType?: string): AppThunk<Promise<Biller>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchBiller({ billerId }));
    try {
      const options: WebHttpRequestOptions = {
        url: `${URL.BASE}/${BILLER_FROM_ID}`,
        method: "POST",
        data: [billerId],
      };
      const response = await asHttp.request(options);
      dispatch(setBillerIdStatusNotLoaded(dataStatusType));
      if (Object.keys(response.data.data).length === 0) {
        //   throw CustomErrorHandler(customError_500);
      }
      const billerMapper = new BillerMapper();

      const mappedData = billerMapper.toModel(response.data.data[billerId]);
      dispatch(fetchBillerSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchBillerFail(error as ApiResponseError));

      throw error;
    }
  };

export const getBillers =
  (billerId: string[]): AppThunk<Promise<Biller[]>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchBiller({ billerId }));
    try {
      const options: WebHttpRequestOptions = {
        url: `${URL.BASE}/${BILLER_FROM_ID}`,
        method: "POST",
        data: billerId,
      };
      const response = await asHttp.request(options);
      const billers: BillerDto[] = [];
      if (Object.keys(response.data).length > 0) {
        const ObjKeys = Object.keys(response.data);
        ObjKeys.forEach((ob) => {
          billerId.forEach((biller) => {
            if (biller === ob) {
              billers.push(response.data[ob as unknown as number]);
            }
          });
        });
      }
      const billerMapper = new BillerMapper();

      const mappedData = billerMapper.toModel(response.data.data);
      dispatch(fetchBillerSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchBillerFail(error as ApiResponseError));

      throw error;
    }
  };

export const getBillerAccount =
  (
    customerId: string,
    billerAccountId: string
  ): AppThunk<Promise<BillerAccount>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchBillerAccount({ customerId, billerAccountId }));
    try {
      const options: WebHttpRequestOptions = {
        url: `${URL.BASE}/${BILLERACCOUNT_FROM_ID}`,
        method: "POST",
        data: {
          customerid: customerId,
          billeraccountid: billerAccountId,
        },
      };
      const response = await asHttp.request(options);
      const billerAccountMapper = new BillerAccountMapper();

      const mappedData = billerAccountMapper.toModel(response.data.data);
      dispatch(fetchBillerAccountSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchBillerAccountFail(error as ApiResponseError));

      throw error;
    }
  };

export const getBillerStatus =
  (billerId: string): AppThunk<Promise<BillerStatusData>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchBillerState({ billerId }));
    try {
      const options: WebHttpRequestOptions = {
        url: `${URL.BASE}/${BILLER_CHECK_STATUS}`,
        method: "POST",
        data: {
          billerId: billerId,
        },
      };
      const response = await asHttp.request(options);
      const billerStatusDataMapper = new BillerStatusDataMapper();

      const mappedData = billerStatusDataMapper.toModel(response.data.data);
      dispatch(fetchBillerStateSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchBillerStateFail(error as ApiResponseError));

      throw error;
    }
  };
