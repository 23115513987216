class Channelstore {
  data: Record<string, any> = {};
  get(key: string): any | undefined {
    console.log("CHANNEL GET", this.data);
    return this.data[key];
  }
  put(key: string, val: any) {
    this.data[key] = val;
    console.log("CHANNEL PUT", this.data);
  }
  remove(key: string) {
    if (this.data[key]) {
      delete this.data[key];
    }
  }
}

export default new Channelstore();
