import { ModelMapper } from "~/src/Lib/types/modelmapper";

export interface DiscoveryApiDataDto {
  isCitiCustomer: boolean;
  isAxisCustomer: boolean;
}

export interface DiscoveryApiData {
  isCitiCustomer: boolean;
  isAxisCustomer: boolean;
}

export class DiscoveryApiDetailMapper
  implements ModelMapper<DiscoveryApiDataDto, DiscoveryApiData>
{
  toModel(dto: DiscoveryApiDataDto): DiscoveryApiData {
    return {
      isCitiCustomer: dto.isCitiCustomer,
      isAxisCustomer: dto.isAxisCustomer,
    };
  }
  toDto(model: DiscoveryApiData): DiscoveryApiDataDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
