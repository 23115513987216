import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { CreditCardDetailMapper } from "~/src/Redux/Payment/Model/CreditCard.model";

import {
  fetchCreditCardDetailsList,
  fetchCreditCardDetailsListFail,
  fetchCreditCardDetailsListSuccess,
} from "~/src/Redux/Payment/Reducers/CreditCardDetails.reducers";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const CREDIT_CARD_DETAILS_URL = URL.PAYMENT.CREDIT_CARD_DETAIL;

export const getCreditCardDetailsListThunk =
  (customerId: string): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchCreditCardDetailsList({
        customerId,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: CREDIT_CARD_DETAILS_URL,
        method: "POST",
        data: {
          customerId: customerId,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;
      const { tokens = {} } = data;
      const token =
        // Instore.get('auth') ||
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY";

      /*       const { accessToken = token, refreshToken = "" } = tokens;
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken); */
      const mapper = new CreditCardDetailMapper();
      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchCreditCardDetailsListSuccess(mappedData));
      console.log(mappedData, "cc data", response.data.data);
      return mappedData;
    } catch (error) {
      dispatch(fetchCreditCardDetailsListFail(error as ApiResponseError));
      throw error;
    }
  };
