{
    "v": "4.8.0",
    "meta": { "g": "LottieFiles AE 3.5.2", "a": "", "k": "", "d": "", "tc": "" },
    "fr": 60,
    "ip": 0,
    "op": 51,
    "w": 1000,
    "h": 1000,
    "ddd": 0,
    "assets": [],
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 3,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 0 },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [496.5, 550.5, 0] },
                "a": { "a": 0, "k": [50, 50, 0] },
                "s": { "a": 0, "k": [132, 132, 100] }
            },
            "ao": 0,
            "ip": 0,
            "op": 51,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 100 },
                "r": { "a": 0, "k": 0 },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": 0.83, "y": 0.66 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 0,
                            "s": [-186.5, -50.5, 0],
                            "to": [0, 0.03, 0],
                            "ti": [0, -0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.78 },
                            "o": { "x": 0.17, "y": 0.11 },
                            "t": 1,
                            "s": [-186.5, -50.32, 0],
                            "to": [0, 0.12, 0],
                            "ti": [0, -0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.8 },
                            "o": { "x": 0.17, "y": 0.13 },
                            "t": 2,
                            "s": [-186.5, -49.75, 0],
                            "to": [0, 0.25, 0],
                            "ti": [0, -0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.14 },
                            "t": 3,
                            "s": [-186.5, -48.79, 0],
                            "to": [0, 0.39, 0],
                            "ti": [0, -0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 4,
                            "s": [-186.5, -47.42, 0],
                            "to": [0, 0.53, 0],
                            "ti": [0, -0.68, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 5,
                            "s": [-186.5, -45.62, 0],
                            "to": [0, 0.68, 0],
                            "ti": [0, -0.83, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 6,
                            "s": [-186.5, -43.35, 0],
                            "to": [0, 0.84, 0],
                            "ti": [0, -1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 7,
                            "s": [-186.5, -40.61, 0],
                            "to": [0, 1, 0],
                            "ti": [0, -1.17, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 8,
                            "s": [-186.5, -37.35, 0],
                            "to": [0, 1.18, 0],
                            "ti": [0, -1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 9,
                            "s": [-186.5, -33.56, 0],
                            "to": [0, 1.36, 0],
                            "ti": [0, -1.56, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 10,
                            "s": [-186.5, -29.19, 0],
                            "to": [0, 1.56, 0],
                            "ti": [0, -1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 11,
                            "s": [-186.5, -24.21, 0],
                            "to": [0, 1.77, 0],
                            "ti": [0, -1.99, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 12,
                            "s": [-186.5, -18.58, 0],
                            "to": [0, 2, 0],
                            "ti": [0, -2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 13,
                            "s": [-186.5, -12.24, 0],
                            "to": [0, 2.24, 0],
                            "ti": [0, -2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 14,
                            "s": [-186.5, -5.14, 0],
                            "to": [0, 2.51, 0],
                            "ti": [0, -2.79, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 15,
                            "s": [-186.5, 2.79, 0],
                            "to": [0, 2.8, 0],
                            "ti": [0, -3.11, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 16,
                            "s": [-186.5, 11.64, 0],
                            "to": [0, 3.12, 0],
                            "ti": [0, -3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 17,
                            "s": [-186.5, 21.48, 0],
                            "to": [0, 3.47, 0],
                            "ti": [0, -3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 18,
                            "s": [-186.5, 32.46, 0],
                            "to": [0, 3.87, 0],
                            "ti": [0, -4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 19,
                            "s": [-186.5, 44.71, 0],
                            "to": [0, 4.33, 0],
                            "ti": [0, -4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 20,
                            "s": [-186.5, 58.43, 0],
                            "to": [0, 4.86, 0],
                            "ti": [0, -5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 21,
                            "s": [-186.5, 73.87, 0],
                            "to": [0, 5.5, 0],
                            "ti": [0, -6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 22,
                            "s": [-186.5, 91.44, 0],
                            "to": [0, 6.31, 0],
                            "ti": [0, -7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 23,
                            "s": [-186.5, 111.72, 0],
                            "to": [0, 7.4, 0],
                            "ti": [0, -9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 24,
                            "s": [-186.5, 135.83, 0],
                            "to": [0, 9.23, 0],
                            "ti": [0, 0, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 25,
                            "s": [-186.5, 167.07, 0],
                            "to": [0, 0, 0],
                            "ti": [0, 9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 26,
                            "s": [-186.5, 135.83, 0],
                            "to": [0, -9.23, 0],
                            "ti": [0, 7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 27,
                            "s": [-186.5, 111.72, 0],
                            "to": [0, -7.4, 0],
                            "ti": [0, 6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 28,
                            "s": [-186.5, 91.44, 0],
                            "to": [0, -6.31, 0],
                            "ti": [0, 5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 29,
                            "s": [-186.5, 73.87, 0],
                            "to": [0, -5.5, 0],
                            "ti": [0, 4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 30,
                            "s": [-186.5, 58.42, 0],
                            "to": [0, -4.86, 0],
                            "ti": [0, 4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 31,
                            "s": [-186.5, 44.71, 0],
                            "to": [0, -4.33, 0],
                            "ti": [0, 3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 32,
                            "s": [-186.5, 32.46, 0],
                            "to": [0, -3.87, 0],
                            "ti": [0, 3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 33,
                            "s": [-186.5, 21.48, 0],
                            "to": [0, -3.47, 0],
                            "ti": [0, 3.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 34,
                            "s": [-186.5, 11.63, 0],
                            "to": [0, -3.11, 0],
                            "ti": [0, 2.8, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 35,
                            "s": [-186.5, 2.79, 0],
                            "to": [0, -2.8, 0],
                            "ti": [0, 2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 36,
                            "s": [-186.5, -5.14, 0],
                            "to": [0, -2.51, 0],
                            "ti": [0, 2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 37,
                            "s": [-186.5, -12.24, 0],
                            "to": [0, -2.24, 0],
                            "ti": [0, 2, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 38,
                            "s": [-186.5, -18.58, 0],
                            "to": [0, -1.99, 0],
                            "ti": [0, 1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 39,
                            "s": [-186.5, -24.21, 0],
                            "to": [0, -1.77, 0],
                            "ti": [0, 1.56, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 40,
                            "s": [-186.5, -29.19, 0],
                            "to": [0, -1.56, 0],
                            "ti": [0, 1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 41,
                            "s": [-186.5, -33.55, 0],
                            "to": [0, -1.36, 0],
                            "ti": [0, 1.17, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 42,
                            "s": [-186.5, -37.34, 0],
                            "to": [0, -1.17, 0],
                            "ti": [0, 1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 43,
                            "s": [-186.5, -40.6, 0],
                            "to": [0, -1, 0],
                            "ti": [0, 0.84, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 44,
                            "s": [-186.5, -43.35, 0],
                            "to": [0, -0.84, 0],
                            "ti": [0, 0.68, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 45,
                            "s": [-186.5, -45.62, 0],
                            "to": [0, -0.68, 0],
                            "ti": [0, 0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.86 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 46,
                            "s": [-186.5, -47.42, 0],
                            "to": [0, -0.53, 0],
                            "ti": [0, 0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.87 },
                            "o": { "x": 0.17, "y": 0.2 },
                            "t": 47,
                            "s": [-186.5, -48.8, 0],
                            "to": [0, -0.39, 0],
                            "ti": [0, 0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.89 },
                            "o": { "x": 0.17, "y": 0.23 },
                            "t": 48,
                            "s": [-186.5, -49.75, 0],
                            "to": [0, -0.25, 0],
                            "ti": [0, 0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.34 },
                            "t": 49,
                            "s": [-186.5, -50.32, 0],
                            "to": [0, -0.12, 0],
                            "ti": [0, 0.03, 0]
                        },
                        { "t": 50, "s": [-186.5, -50.5, 0] }
                    ]
                },
                "a": { "a": 0, "k": [4.04, 3.04, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0, 0] },
                            "t": 0,
                            "s": [100, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0, 0] },
                            "t": 1,
                            "s": [99.98, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0, 0] },
                            "t": 2,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0, 0] },
                            "t": 3,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 4,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 5,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 6,
                            "s": [99.22, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 7,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 8,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 9,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 10,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 11,
                            "s": [97.09, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 12,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 13,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 14,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 15,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 16,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 17,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 18,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 19,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 20,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 21,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1.19, 1.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 22,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.06, 0.08, 0] },
                            "t": 23,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.98, 0.98, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 24,
                            "s": [90.02, 89.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 0.77, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.03, -0.03, 0] },
                            "t": 25,
                            "s": [100, 80, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.92, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 26,
                            "s": [92.67, 87.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.26, 1.06, 0] },
                            "t": 27,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 28,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 29,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 30,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 31,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 32,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 33,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 34,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 35,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 36,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 37,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 38,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 39,
                            "s": [97.09, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 40,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 41,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 42,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 43,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 44,
                            "s": [99.22, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 45,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 46,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0, 0] },
                            "t": 47,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0, 0] },
                            "t": 48,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0, 0] },
                            "t": 49,
                            "s": [99.98, 100, 100]
                        },
                        { "t": 50, "s": [100, 100, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "cl": "dot-class",
                            "o": { "a": 0, "k": 100 },
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 100 },
                "r": { "a": 0, "k": 0 },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 0,
                            "s": [53.5, -45.61, 0],
                            "to": [0, -0.3, 0],
                            "ti": [0, 0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.86 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 1,
                            "s": [53.5, -47.42, 0],
                            "to": [0, -0.53, 0],
                            "ti": [0, 0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.87 },
                            "o": { "x": 0.17, "y": 0.2 },
                            "t": 2,
                            "s": [53.5, -48.79, 0],
                            "to": [0, -0.39, 0],
                            "ti": [0, 0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.89 },
                            "o": { "x": 0.17, "y": 0.23 },
                            "t": 3,
                            "s": [53.5, -49.75, 0],
                            "to": [0, -0.25, 0],
                            "ti": [0, 0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.34 },
                            "t": 4,
                            "s": [53.5, -50.32, 0],
                            "to": [0, -0.12, 0],
                            "ti": [0, 0, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.66 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 5,
                            "s": [53.5, -50.5, 0],
                            "to": [0, 0, 0],
                            "ti": [0, -0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.78 },
                            "o": { "x": 0.17, "y": 0.11 },
                            "t": 6,
                            "s": [53.5, -50.32, 0],
                            "to": [0, 0.12, 0],
                            "ti": [0, -0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.8 },
                            "o": { "x": 0.17, "y": 0.13 },
                            "t": 7,
                            "s": [53.5, -49.75, 0],
                            "to": [0, 0.25, 0],
                            "ti": [0, -0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.14 },
                            "t": 8,
                            "s": [53.5, -48.79, 0],
                            "to": [0, 0.39, 0],
                            "ti": [0, -0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 9,
                            "s": [53.5, -47.42, 0],
                            "to": [0, 0.53, 0],
                            "ti": [0, -0.68, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 10,
                            "s": [53.5, -45.61, 0],
                            "to": [0, 0.68, 0],
                            "ti": [0, -0.84, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 11,
                            "s": [53.5, -43.35, 0],
                            "to": [0, 0.84, 0],
                            "ti": [0, -1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 12,
                            "s": [53.5, -40.6, 0],
                            "to": [0, 1, 0],
                            "ti": [0, -1.17, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 13,
                            "s": [53.5, -37.34, 0],
                            "to": [0, 1.18, 0],
                            "ti": [0, -1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 14,
                            "s": [53.5, -33.55, 0],
                            "to": [0, 1.36, 0],
                            "ti": [0, -1.56, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 15,
                            "s": [53.5, -29.19, 0],
                            "to": [0, 1.56, 0],
                            "ti": [0, -1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 16,
                            "s": [53.5, -24.21, 0],
                            "to": [0, 1.77, 0],
                            "ti": [0, -2, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 17,
                            "s": [53.5, -18.57, 0],
                            "to": [0, 2, 0],
                            "ti": [0, -2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 18,
                            "s": [53.5, -12.23, 0],
                            "to": [0, 2.24, 0],
                            "ti": [0, -2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 19,
                            "s": [53.5, -5.13, 0],
                            "to": [0, 2.51, 0],
                            "ti": [0, -2.8, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 20,
                            "s": [53.5, 2.81, 0],
                            "to": [0, 2.8, 0],
                            "ti": [0, -3.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 21,
                            "s": [53.5, 11.65, 0],
                            "to": [0, 3.12, 0],
                            "ti": [0, -3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 22,
                            "s": [53.5, 21.5, 0],
                            "to": [0, 3.47, 0],
                            "ti": [0, -3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 23,
                            "s": [53.5, 32.48, 0],
                            "to": [0, 3.87, 0],
                            "ti": [0, -4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 24,
                            "s": [53.5, 44.73, 0],
                            "to": [0, 4.33, 0],
                            "ti": [0, -4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 25,
                            "s": [53.5, 58.45, 0],
                            "to": [0, 4.86, 0],
                            "ti": [0, -5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 26,
                            "s": [53.5, 73.9, 0],
                            "to": [0, 5.5, 0],
                            "ti": [0, -6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 27,
                            "s": [53.5, 91.48, 0],
                            "to": [0, 6.31, 0],
                            "ti": [0, -7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 28,
                            "s": [53.5, 111.76, 0],
                            "to": [0, 7.4, 0],
                            "ti": [0, -9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 29,
                            "s": [53.5, 135.88, 0],
                            "to": [0, 9.23, 0],
                            "ti": [0, 0, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 30,
                            "s": [53.5, 167.13, 0],
                            "to": [0, 0, 0],
                            "ti": [0, 9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 31,
                            "s": [53.5, 135.88, 0],
                            "to": [0, -9.23, 0],
                            "ti": [0, 7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 32,
                            "s": [53.5, 111.76, 0],
                            "to": [0, -7.4, 0],
                            "ti": [0, 6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 33,
                            "s": [53.5, 91.48, 0],
                            "to": [0, -6.31, 0],
                            "ti": [0, 5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 34,
                            "s": [53.5, 73.91, 0],
                            "to": [0, -5.5, 0],
                            "ti": [0, 4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 35,
                            "s": [53.5, 58.45, 0],
                            "to": [0, -4.86, 0],
                            "ti": [0, 4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 36,
                            "s": [53.5, 44.73, 0],
                            "to": [0, -4.33, 0],
                            "ti": [0, 3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 37,
                            "s": [53.5, 32.48, 0],
                            "to": [0, -3.87, 0],
                            "ti": [0, 3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 38,
                            "s": [53.5, 21.5, 0],
                            "to": [0, -3.47, 0],
                            "ti": [0, 3.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 39,
                            "s": [53.5, 11.65, 0],
                            "to": [0, -3.12, 0],
                            "ti": [0, 2.8, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 40,
                            "s": [53.5, 2.81, 0],
                            "to": [0, -2.8, 0],
                            "ti": [0, 2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 41,
                            "s": [53.5, -5.13, 0],
                            "to": [0, -2.51, 0],
                            "ti": [0, 2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 42,
                            "s": [53.5, -12.23, 0],
                            "to": [0, -2.24, 0],
                            "ti": [0, 2, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 43,
                            "s": [53.5, -18.57, 0],
                            "to": [0, -2, 0],
                            "ti": [0, 1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 44,
                            "s": [53.5, -24.2, 0],
                            "to": [0, -1.77, 0],
                            "ti": [0, 1.56, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 45,
                            "s": [53.5, -29.18, 0],
                            "to": [0, -1.56, 0],
                            "ti": [0, 1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 46,
                            "s": [53.5, -33.55, 0],
                            "to": [0, -1.36, 0],
                            "ti": [0, 1.18, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 47,
                            "s": [53.5, -37.35, 0],
                            "to": [0, -1.17, 0],
                            "ti": [0, 1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 48,
                            "s": [53.5, -40.6, 0],
                            "to": [0, -1, 0],
                            "ti": [0, 0.84, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 49,
                            "s": [53.5, -43.35, 0],
                            "to": [0, -0.83, 0],
                            "ti": [0, 0.38, 0]
                        },
                        { "t": 50, "s": [53.5, -45.61, 0] }
                    ]
                },
                "a": { "a": 0, "k": [4.04, 3.04, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0, 0] },
                            "t": 0,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 1,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0, 0] },
                            "t": 2,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0, 0] },
                            "t": 3,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0, 0] },
                            "t": 4,
                            "s": [99.98, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 5,
                            "s": [100, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0, 0] },
                            "t": 6,
                            "s": [99.98, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0, 0] },
                            "t": 7,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0, 0] },
                            "t": 8,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 9,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 10,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 11,
                            "s": [99.22, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 12,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 13,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 14,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 15,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 16,
                            "s": [97.09, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 17,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 18,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 19,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 20,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 21,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 22,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 23,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 24,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 25,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 26,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1.19, 1.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 27,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.06, 0.08, 0] },
                            "t": 28,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.98, 0.98, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 29,
                            "s": [90.02, 89.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 0.77, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.03, -0.03, 0] },
                            "t": 30,
                            "s": [100, 80, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.92, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 31,
                            "s": [92.67, 87.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.26, 1.06, 0] },
                            "t": 32,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 33,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 34,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 35,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 36,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 37,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 38,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 39,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 40,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 41,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 42,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 43,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 44,
                            "s": [97.09, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 45,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 46,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 47,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 48,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 49,
                            "s": [99.22, 100, 100]
                        },
                        { "t": 50, "s": [99.47, 100, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "cl": "dot-class",
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 5,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 100 },
                "r": { "a": 0, "k": 0 },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 0,
                            "s": [293.5, -29.19, 0],
                            "to": [0, -0.73, 0],
                            "ti": [0, 1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 1,
                            "s": [293.5, -33.55, 0],
                            "to": [0, -1.36, 0],
                            "ti": [0, 1.17, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 2,
                            "s": [293.5, -37.34, 0],
                            "to": [0, -1.17, 0],
                            "ti": [0, 1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 3,
                            "s": [293.5, -40.6, 0],
                            "to": [0, -1, 0],
                            "ti": [0, 0.84, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 4,
                            "s": [293.5, -43.35, 0],
                            "to": [0, -0.84, 0],
                            "ti": [0, 0.68, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 5,
                            "s": [293.5, -45.62, 0],
                            "to": [0, -0.68, 0],
                            "ti": [0, 0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.86 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 6,
                            "s": [293.5, -47.42, 0],
                            "to": [0, -0.53, 0],
                            "ti": [0, 0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.87 },
                            "o": { "x": 0.17, "y": 0.2 },
                            "t": 7,
                            "s": [293.5, -48.8, 0],
                            "to": [0, -0.39, 0],
                            "ti": [0, 0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.89 },
                            "o": { "x": 0.17, "y": 0.23 },
                            "t": 8,
                            "s": [293.5, -49.75, 0],
                            "to": [0, -0.25, 0],
                            "ti": [0, 0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.34 },
                            "t": 9,
                            "s": [293.5, -50.32, 0],
                            "to": [0, -0.12, 0],
                            "ti": [0, 0, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.66 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 10,
                            "s": [293.5, -50.5, 0],
                            "to": [0, 0, 0],
                            "ti": [0, -0.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.78 },
                            "o": { "x": 0.17, "y": 0.11 },
                            "t": 11,
                            "s": [293.5, -50.32, 0],
                            "to": [0, 0.12, 0],
                            "ti": [0, -0.25, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.8 },
                            "o": { "x": 0.17, "y": 0.13 },
                            "t": 12,
                            "s": [293.5, -49.75, 0],
                            "to": [0, 0.25, 0],
                            "ti": [0, -0.39, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.14 },
                            "t": 13,
                            "s": [293.5, -48.79, 0],
                            "to": [0, 0.39, 0],
                            "ti": [0, -0.53, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 14,
                            "s": [293.5, -47.42, 0],
                            "to": [0, 0.53, 0],
                            "ti": [0, -0.68, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 15,
                            "s": [293.5, -45.62, 0],
                            "to": [0, 0.68, 0],
                            "ti": [0, -0.83, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 16,
                            "s": [293.5, -43.35, 0],
                            "to": [0, 0.84, 0],
                            "ti": [0, -1, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 17,
                            "s": [293.5, -40.61, 0],
                            "to": [0, 1, 0],
                            "ti": [0, -1.17, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 18,
                            "s": [293.5, -37.35, 0],
                            "to": [0, 1.18, 0],
                            "ti": [0, -1.36, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 19,
                            "s": [293.5, -33.56, 0],
                            "to": [0, 1.36, 0],
                            "ti": [0, -1.56, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 20,
                            "s": [293.5, -29.19, 0],
                            "to": [0, 1.56, 0],
                            "ti": [0, -1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 21,
                            "s": [293.5, -24.21, 0],
                            "to": [0, 1.77, 0],
                            "ti": [0, -1.99, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 22,
                            "s": [293.5, -18.58, 0],
                            "to": [0, 2, 0],
                            "ti": [0, -2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 23,
                            "s": [293.5, -12.24, 0],
                            "to": [0, 2.24, 0],
                            "ti": [0, -2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 24,
                            "s": [293.5, -5.14, 0],
                            "to": [0, 2.51, 0],
                            "ti": [0, -2.79, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 25,
                            "s": [293.5, 2.79, 0],
                            "to": [0, 2.8, 0],
                            "ti": [0, -3.11, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 26,
                            "s": [293.5, 11.64, 0],
                            "to": [0, 3.12, 0],
                            "ti": [0, -3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 27,
                            "s": [293.5, 21.48, 0],
                            "to": [0, 3.47, 0],
                            "ti": [0, -3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 28,
                            "s": [293.5, 32.46, 0],
                            "to": [0, 3.87, 0],
                            "ti": [0, -4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 29,
                            "s": [293.5, 44.71, 0],
                            "to": [0, 4.33, 0],
                            "ti": [0, -4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 30,
                            "s": [293.5, 58.43, 0],
                            "to": [0, 4.86, 0],
                            "ti": [0, -5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 31,
                            "s": [293.5, 73.87, 0],
                            "to": [0, 5.5, 0],
                            "ti": [0, -6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.82 },
                            "o": { "x": 0.17, "y": 0.16 },
                            "t": 32,
                            "s": [293.5, 91.44, 0],
                            "to": [0, 6.31, 0],
                            "ti": [0, -7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.81 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 33,
                            "s": [293.5, 111.72, 0],
                            "to": [0, 7.4, 0],
                            "ti": [0, -9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.15 },
                            "t": 34,
                            "s": [293.5, 135.83, 0],
                            "to": [0, 9.23, 0],
                            "ti": [0, 0, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.17 },
                            "t": 35,
                            "s": [293.5, 167.07, 0],
                            "to": [0, 0, 0],
                            "ti": [0, 9.23, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.85 },
                            "o": { "x": 0.17, "y": 0.19 },
                            "t": 36,
                            "s": [293.5, 135.83, 0],
                            "to": [0, -9.23, 0],
                            "ti": [0, 7.4, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 37,
                            "s": [293.5, 111.72, 0],
                            "to": [0, -7.4, 0],
                            "ti": [0, 6.31, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 38,
                            "s": [293.5, 91.44, 0],
                            "to": [0, -6.31, 0],
                            "ti": [0, 5.5, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 39,
                            "s": [293.5, 73.87, 0],
                            "to": [0, -5.5, 0],
                            "ti": [0, 4.86, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 40,
                            "s": [293.5, 58.42, 0],
                            "to": [0, -4.86, 0],
                            "ti": [0, 4.33, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 41,
                            "s": [293.5, 44.71, 0],
                            "to": [0, -4.33, 0],
                            "ti": [0, 3.87, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 42,
                            "s": [293.5, 32.46, 0],
                            "to": [0, -3.87, 0],
                            "ti": [0, 3.47, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 43,
                            "s": [293.5, 21.48, 0],
                            "to": [0, -3.47, 0],
                            "ti": [0, 3.12, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 44,
                            "s": [293.5, 11.63, 0],
                            "to": [0, -3.11, 0],
                            "ti": [0, 2.8, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 45,
                            "s": [293.5, 2.79, 0],
                            "to": [0, -2.8, 0],
                            "ti": [0, 2.51, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 46,
                            "s": [293.5, -5.14, 0],
                            "to": [0, -2.51, 0],
                            "ti": [0, 2.24, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 47,
                            "s": [293.5, -12.24, 0],
                            "to": [0, -2.24, 0],
                            "ti": [0, 2, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.84 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 48,
                            "s": [293.5, -18.58, 0],
                            "to": [0, -1.99, 0],
                            "ti": [0, 1.77, 0]
                        },
                        {
                            "i": { "x": 0.83, "y": 0.83 },
                            "o": { "x": 0.17, "y": 0.18 },
                            "t": 49,
                            "s": [293.5, -24.21, 0],
                            "to": [0, -1.77, 0],
                            "ti": [0, 0.83, 0]
                        },
                        { "t": 50, "s": [293.5, -29.19, 0] }
                    ]
                },
                "a": { "a": 0, "k": [4.04, 3.04, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0, 0] },
                            "t": 0,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 1,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 2,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 3,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 4,
                            "s": [99.22, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 5,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 6,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0, 0] },
                            "t": 7,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0, 0] },
                            "t": 8,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0, 0] },
                            "t": 9,
                            "s": [99.98, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 10,
                            "s": [100, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0, 0] },
                            "t": 11,
                            "s": [99.98, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0, 0] },
                            "t": 12,
                            "s": [99.92, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0, 0] },
                            "t": 13,
                            "s": [99.81, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 14,
                            "s": [99.66, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 15,
                            "s": [99.47, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 16,
                            "s": [99.22, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 17,
                            "s": [98.91, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 18,
                            "s": [98.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 19,
                            "s": [98.13, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 20,
                            "s": [97.65, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 21,
                            "s": [97.09, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 22,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 23,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 24,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 25,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 26,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 27,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 28,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 29,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0, 0] },
                            "t": 30,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 31,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1.19, 1.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0, 0] },
                            "t": 32,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.06, 0.08, 0] },
                            "t": 33,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.98, 0.98, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 34,
                            "s": [90.02, 89.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.77, 0.77, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.03, -0.03, 0] },
                            "t": 35,
                            "s": [100, 80, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.92, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 36,
                            "s": [92.67, 87.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.26, 1.06, 0] },
                            "t": 37,
                            "s": [80, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0, 0] },
                            "t": 38,
                            "s": [83.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 39,
                            "s": [85.43, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 40,
                            "s": [87.4, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 41,
                            "s": [89.08, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 42,
                            "s": [90.55, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 43,
                            "s": [91.85, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 44,
                            "s": [93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 45,
                            "s": [94.02, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 46,
                            "s": [94.93, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 47,
                            "s": [95.74, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 48,
                            "s": [96.46, 100, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0, 0] },
                            "t": 49,
                            "s": [97.09, 100, 100]
                        },
                        { "t": 50, "s": [97.65, 100, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "cl": "dot-class",
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 10,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 5,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 0,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 1,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 2,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 3,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 4,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 5,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 6,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 7,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 8,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 9,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 10,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 11,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 12,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 13,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 14,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 15,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 16,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 17,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 18,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 19,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 20,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 21,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 22,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 23,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.04] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 24,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.08] },
                            "t": 25,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.32] },
                            "t": 26,
                            "s": [11.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 27,
                            "s": [15.51]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 28,
                            "s": [17.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.25] },
                            "t": 29,
                            "s": [19.12]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.99] },
                            "o": { "x": [0.17], "y": [0.37] },
                            "t": 30,
                            "s": [19.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.71] },
                            "o": { "x": [0.17], "y": [-0.01] },
                            "t": 31,
                            "s": [20]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.8] },
                            "o": { "x": [0.17], "y": [0.12] },
                            "t": 32,
                            "s": [19.82]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.14] },
                            "t": 33,
                            "s": [19.38]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.15] },
                            "t": 34,
                            "s": [18.76]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 35,
                            "s": [18.02]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 36,
                            "s": [17.17]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 37,
                            "s": [16.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 38,
                            "s": [15.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 39,
                            "s": [14.18]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 40,
                            "s": [13.07]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 41,
                            "s": [11.92]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 42,
                            "s": [10.72]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 43,
                            "s": [9.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 44,
                            "s": [8.22]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 45,
                            "s": [6.93]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 46,
                            "s": [5.6]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 47,
                            "s": [4.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 48,
                            "s": [2.86]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 49,
                            "s": [1.44]
                        },
                        { "t": 50, "s": [0] }
                    ]
                },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [-186.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.59, 0.59, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 1,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 2,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 3,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 4,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 5,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.24, 0.23, 0] },
                            "t": 6,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 0.9, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0.26, 0] },
                            "t": 7,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 8,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 9,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.12, 0] },
                            "t": 10,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.14, 0] },
                            "t": 11,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 12,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.15, 0] },
                            "t": 13,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 14,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 15,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 16,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 17,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 18,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 19,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 20,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 21,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 22,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": {
                                "x": [0.83, 0.83, 0.83],
                                "y": [-24966939.73, -12444441.72, 1]
                            },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 23,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.94, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.08, 0.08, 0] },
                            "t": 24,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.22, -0.22, 0] },
                            "t": 25,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.26, 0.26, 0] },
                            "t": 26,
                            "s": [37.9, 18.83, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 27,
                            "s": [55.56, 27.61, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 28,
                            "s": [68.57, 34.07, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 29,
                            "s": [78.97, 39.24, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 30,
                            "s": [87.63, 43.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 31,
                            "s": [95, 47.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 32,
                            "s": [101.35, 50.36, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 33,
                            "s": [106.89, 53.11, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 34,
                            "s": [111.74, 55.52, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 35,
                            "s": [116.01, 57.64, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 36,
                            "s": [119.77, 59.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 37,
                            "s": [123.08, 61.15, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 38,
                            "s": [125.99, 62.6, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [128.55, 63.87, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [130.78, 64.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [132.72, 65.94, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [134.38, 66.77, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [135.79, 67.47, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 44,
                            "s": [136.98, 68.06, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 45,
                            "s": [137.95, 68.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 46,
                            "s": [138.71, 68.92, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 47,
                            "s": [139.29, 69.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 48,
                            "s": [139.69, 69.41, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 49,
                            "s": [139.92, 69.52, 100]
                        },
                        { "t": 50, "s": [140, 69.56, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "w": { "a": 0, "k": 10 },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 25,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 0,
                            "s": [6.93]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 1,
                            "s": [5.6]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 2,
                            "s": [4.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 3,
                            "s": [2.86]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.92] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 4,
                            "s": [1.44]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [-0.12] },
                            "t": 5,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 6,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 7,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 8,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 9,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 10,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 11,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 12,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 13,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 14,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 15,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 16,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 17,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 18,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 19,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 20,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 21,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 22,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 23,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 24,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 25,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 26,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 27,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 28,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.04] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 29,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.08] },
                            "t": 30,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.32] },
                            "t": 31,
                            "s": [11.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 32,
                            "s": [15.51]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 33,
                            "s": [17.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.25] },
                            "t": 34,
                            "s": [19.12]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.99] },
                            "o": { "x": [0.17], "y": [0.37] },
                            "t": 35,
                            "s": [19.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.71] },
                            "o": { "x": [0.17], "y": [-0.01] },
                            "t": 36,
                            "s": [20]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.8] },
                            "o": { "x": [0.17], "y": [0.12] },
                            "t": 37,
                            "s": [19.82]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.14] },
                            "t": 38,
                            "s": [19.38]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.15] },
                            "t": 39,
                            "s": [18.76]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 40,
                            "s": [18.02]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 41,
                            "s": [17.17]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 42,
                            "s": [16.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 43,
                            "s": [15.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 44,
                            "s": [14.18]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 45,
                            "s": [13.07]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 46,
                            "s": [11.92]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 47,
                            "s": [10.72]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 48,
                            "s": [9.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 49,
                            "s": [8.22]
                        },
                        { "t": 50, "s": [6.93] }
                    ]
                },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [53.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [137.95, 68.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 1,
                            "s": [138.71, 68.92, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 2,
                            "s": [139.29, 69.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 3,
                            "s": [139.69, 69.41, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.92, 0.92, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 4,
                            "s": [139.92, 69.52, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.59, 0.59, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [21065.93, 9735.43, 0] },
                            "t": 5,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 6,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 7,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 8,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 9,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 10,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.24, 0.23, 0] },
                            "t": 11,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 0.9, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0.26, 0] },
                            "t": 12,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 13,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 14,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.12, 0] },
                            "t": 15,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.14, 0] },
                            "t": 16,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 17,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.15, 0] },
                            "t": 18,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 19,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 20,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 21,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 22,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 23,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 24,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 25,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 26,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 27,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": {
                                "x": [0.83, 0.83, 0.83],
                                "y": [-24966968.59, -12444455.77, 1]
                            },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 28,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.94, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.08, 0.08, 0] },
                            "t": 29,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.23, -0.23, 0] },
                            "t": 30,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.26, 0.26, 0] },
                            "t": 31,
                            "s": [37.68, 18.72, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 32,
                            "s": [55.42, 27.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 33,
                            "s": [68.47, 34.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 34,
                            "s": [78.9, 39.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 35,
                            "s": [87.58, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 36,
                            "s": [94.96, 47.18, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 37,
                            "s": [101.32, 50.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 38,
                            "s": [106.87, 53.1, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [111.73, 55.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [116, 57.63, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [119.76, 59.5, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [123.07, 61.15, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [125.99, 62.6, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 44,
                            "s": [128.54, 63.87, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 45,
                            "s": [130.78, 64.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 46,
                            "s": [132.71, 65.94, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 47,
                            "s": [134.38, 66.77, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 48,
                            "s": [135.79, 67.47, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 49,
                            "s": [136.98, 68.06, 100]
                        },
                        { "t": 50, "s": [137.95, 68.54, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "w": { "a": 0, "k": 10 },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 30,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 0,
                            "s": [13.07]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 1,
                            "s": [11.92]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 2,
                            "s": [10.72]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 3,
                            "s": [9.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 4,
                            "s": [8.22]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 5,
                            "s": [6.93]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 6,
                            "s": [5.6]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 7,
                            "s": [4.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 8,
                            "s": [2.86]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.92] },
                            "o": { "x": [0.17], "y": [0.17] },
                            "t": 9,
                            "s": [1.44]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [-0.12] },
                            "t": 10,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 11,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 12,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 13,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 14,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 15,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 16,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 17,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 18,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 19,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 20,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 21,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 22,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 23,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 24,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 25,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 26,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 27,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 28,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 29,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 30,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 31,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 32,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [1] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 33,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.04] },
                            "o": { "x": [0.17], "y": [0] },
                            "t": 34,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.08] },
                            "t": 35,
                            "s": [0]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.32] },
                            "t": 36,
                            "s": [11.49]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 37,
                            "s": [15.51]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.87] },
                            "o": { "x": [0.17], "y": [0.23] },
                            "t": 38,
                            "s": [17.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.89] },
                            "o": { "x": [0.17], "y": [0.25] },
                            "t": 39,
                            "s": [19.12]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.99] },
                            "o": { "x": [0.17], "y": [0.37] },
                            "t": 40,
                            "s": [19.8]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.71] },
                            "o": { "x": [0.17], "y": [-0.01] },
                            "t": 41,
                            "s": [20]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.8] },
                            "o": { "x": [0.17], "y": [0.12] },
                            "t": 42,
                            "s": [19.82]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.14] },
                            "t": 43,
                            "s": [19.38]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.82] },
                            "o": { "x": [0.17], "y": [0.15] },
                            "t": 44,
                            "s": [18.76]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 45,
                            "s": [18.02]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 46,
                            "s": [17.17]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 47,
                            "s": [16.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 48,
                            "s": [15.24]
                        },
                        {
                            "i": { "x": [0.83], "y": [0.83] },
                            "o": { "x": [0.17], "y": [0.16] },
                            "t": 49,
                            "s": [14.18]
                        },
                        { "t": 50, "s": [13.07] }
                    ]
                },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [293.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [130.78, 64.98, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 1,
                            "s": [132.71, 65.94, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 2,
                            "s": [134.38, 66.77, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 3,
                            "s": [135.79, 67.47, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 4,
                            "s": [136.98, 68.06, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 5,
                            "s": [137.95, 68.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 6,
                            "s": [138.71, 68.92, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 7,
                            "s": [139.29, 69.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 8,
                            "s": [139.69, 69.41, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.92, 0.92, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 9,
                            "s": [139.92, 69.52, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.59, 0.59, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [21065.91, 9735.42, 0] },
                            "t": 10,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 11,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 12,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 13,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 14,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.22, 0.22, 0] },
                            "t": 15,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.24, 0.23, 0] },
                            "t": 16,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 0.9, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0.26, 0] },
                            "t": 17,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 18,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 19,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.12, 0] },
                            "t": 20,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.14, 0] },
                            "t": 21,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 22,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.15, 0] },
                            "t": 23,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 24,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 25,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 26,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 27,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 28,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 29,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 30,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 31,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 32,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": {
                                "x": [0.83, 0.83, 0.83],
                                "y": [-24966941.25, -12444441.34, 1]
                            },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 33,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.94, 0.94, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.08, 0.08, 0] },
                            "t": 34,
                            "s": [140, 69.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.88, 0.88, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [-0.23, -0.23, 0] },
                            "t": 35,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.26, 0.26, 0] },
                            "t": 36,
                            "s": [37.68, 18.72, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 37,
                            "s": [55.42, 27.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 38,
                            "s": [68.47, 34.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [78.9, 39.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [87.58, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [94.96, 47.18, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [101.32, 50.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [106.87, 53.1, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 44,
                            "s": [111.73, 55.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 45,
                            "s": [116, 57.63, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 46,
                            "s": [119.76, 59.5, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 47,
                            "s": [123.07, 61.15, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 48,
                            "s": [125.99, 62.6, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 49,
                            "s": [128.54, 63.87, 100]
                        },
                        { "t": 50, "s": [130.78, 64.98, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "w": { "a": 0, "k": 10 },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 35,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 8,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 20 },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [-186.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 0.66, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [95, 47.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.78, 0.78, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 1,
                            "s": [94.92, 47.16, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 2,
                            "s": [94.67, 47.04, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0.14, 0] },
                            "t": 3,
                            "s": [94.25, 46.83, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 4,
                            "s": [93.65, 46.53, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 5,
                            "s": [92.87, 46.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 6,
                            "s": [91.88, 45.65, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 7,
                            "s": [90.68, 45.05, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 8,
                            "s": [89.26, 44.35, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 9,
                            "s": [87.6, 43.53, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 10,
                            "s": [85.69, 42.58, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 11,
                            "s": [83.52, 41.5, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 12,
                            "s": [81.06, 40.28, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 13,
                            "s": [78.29, 38.9, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 14,
                            "s": [75.19, 37.36, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 15,
                            "s": [71.73, 35.64, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 16,
                            "s": [67.87, 33.72, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 17,
                            "s": [63.57, 31.58, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 18,
                            "s": [58.78, 29.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 19,
                            "s": [53.43, 26.55, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 20,
                            "s": [47.44, 23.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 21,
                            "s": [40.69, 20.22, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 22,
                            "s": [33.02, 16.41, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 23,
                            "s": [24.17, 12.01, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 24,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 25,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 26,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 27,
                            "s": [24.17, 12.01, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 28,
                            "s": [33.02, 16.41, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 29,
                            "s": [40.69, 20.22, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 30,
                            "s": [47.44, 23.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 31,
                            "s": [53.43, 26.55, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 32,
                            "s": [58.78, 29.2, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 33,
                            "s": [63.57, 31.58, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 34,
                            "s": [67.87, 33.72, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 35,
                            "s": [71.73, 35.64, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 36,
                            "s": [75.19, 37.36, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 37,
                            "s": [78.29, 38.9, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 38,
                            "s": [81.06, 40.28, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [83.52, 41.5, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [85.69, 42.58, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [87.6, 43.53, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [89.26, 44.35, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [90.68, 45.05, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 44,
                            "s": [91.88, 45.65, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 45,
                            "s": [92.87, 46.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 46,
                            "s": [93.65, 46.53, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 47,
                            "s": [94.25, 46.83, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 48,
                            "s": [94.67, 47.04, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 49,
                            "s": [94.92, 47.16, 100]
                        },
                        { "t": 50, "s": [95, 47.2, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "cl": "dot-class",
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 9,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 20 },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [53.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [92.83, 46.12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 1,
                            "s": [93.61, 46.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 2,
                            "s": [94.21, 46.81, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 3,
                            "s": [94.63, 47.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 4,
                            "s": [94.88, 47.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 0.66, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 5,
                            "s": [94.96, 47.18, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.78, 0.78, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 6,
                            "s": [94.88, 47.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 7,
                            "s": [94.63, 47.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0.14, 0] },
                            "t": 8,
                            "s": [94.21, 46.81, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 9,
                            "s": [93.61, 46.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 10,
                            "s": [92.83, 46.12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 11,
                            "s": [91.84, 45.63, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 12,
                            "s": [90.64, 45.03, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 13,
                            "s": [89.22, 44.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 14,
                            "s": [87.56, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 15,
                            "s": [85.66, 42.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 16,
                            "s": [83.48, 41.48, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 17,
                            "s": [81.03, 40.26, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 18,
                            "s": [78.26, 38.88, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 19,
                            "s": [75.16, 37.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 20,
                            "s": [71.7, 35.62, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 21,
                            "s": [67.84, 33.71, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 22,
                            "s": [63.54, 31.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 23,
                            "s": [58.75, 29.19, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 24,
                            "s": [53.41, 26.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 25,
                            "s": [47.42, 23.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 26,
                            "s": [40.67, 20.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 27,
                            "s": [33.01, 16.4, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 28,
                            "s": [24.16, 12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 29,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 30,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 31,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 32,
                            "s": [24.16, 12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 33,
                            "s": [33.01, 16.4, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 34,
                            "s": [40.67, 20.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 35,
                            "s": [47.42, 23.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 36,
                            "s": [53.41, 26.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 37,
                            "s": [58.75, 29.19, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 38,
                            "s": [63.54, 31.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [67.84, 33.71, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [71.7, 35.62, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [75.16, 37.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [78.26, 38.88, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [81.03, 40.26, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 44,
                            "s": [83.48, 41.48, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 45,
                            "s": [85.66, 42.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 46,
                            "s": [87.56, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 47,
                            "s": [89.22, 44.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 48,
                            "s": [90.64, 45.03, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 49,
                            "s": [91.84, 45.63, 100]
                        },
                        { "t": 50, "s": [92.83, 46.12, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "cl": "dot-class",
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 5,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 20 },
                "r": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [293.5, 173.5, 0] },
                "a": { "a": 0, "k": [4.04, -71.96, 0] },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.17, 0.17, 0] },
                            "t": 0,
                            "s": [85.66, 42.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 1,
                            "s": [87.56, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 2,
                            "s": [89.22, 44.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 3,
                            "s": [90.64, 45.03, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 4,
                            "s": [91.84, 45.63, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 5,
                            "s": [92.83, 46.12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.86, 0.86, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 6,
                            "s": [93.61, 46.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.87, 0.87, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.2, 0.2, 0] },
                            "t": 7,
                            "s": [94.21, 46.81, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.89, 0.89, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.23, 0.23, 0] },
                            "t": 8,
                            "s": [94.63, 47.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.34, 0.34, 0] },
                            "t": 9,
                            "s": [94.88, 47.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.66, 0.66, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 10,
                            "s": [94.96, 47.18, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.78, 0.78, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.11, 0.11, 0] },
                            "t": 11,
                            "s": [94.88, 47.14, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.8, 0.8, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.13, 0.13, 0] },
                            "t": 12,
                            "s": [94.63, 47.02, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.14, 0.14, 0] },
                            "t": 13,
                            "s": [94.21, 46.81, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 14,
                            "s": [93.61, 46.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 15,
                            "s": [92.83, 46.12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 16,
                            "s": [91.84, 45.63, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 17,
                            "s": [90.64, 45.03, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 18,
                            "s": [89.22, 44.33, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 19,
                            "s": [87.56, 43.51, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 20,
                            "s": [85.66, 42.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 21,
                            "s": [83.48, 41.48, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 22,
                            "s": [81.03, 40.26, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 23,
                            "s": [78.26, 38.88, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 24,
                            "s": [75.16, 37.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 25,
                            "s": [71.7, 35.62, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 26,
                            "s": [67.84, 33.71, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 27,
                            "s": [63.54, 31.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 28,
                            "s": [58.75, 29.19, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 29,
                            "s": [53.41, 26.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 30,
                            "s": [47.42, 23.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 31,
                            "s": [40.67, 20.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.82, 0.82, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.16, 0.16, 0] },
                            "t": 32,
                            "s": [33.01, 16.4, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.81, 0.81, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 33,
                            "s": [24.16, 12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [1, 1, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.15, 0.15, 0] },
                            "t": 34,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0, 0, 0] },
                            "t": 35,
                            "s": [0, 0, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.85, 0.85, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.19, 0.19, 0] },
                            "t": 36,
                            "s": [13.64, 6.78, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 37,
                            "s": [24.16, 12, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 38,
                            "s": [33.01, 16.4, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 39,
                            "s": [40.67, 20.21, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 40,
                            "s": [47.42, 23.56, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 41,
                            "s": [53.41, 26.54, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 42,
                            "s": [58.75, 29.19, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 43,
                            "s": [63.54, 31.57, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 44,
                            "s": [67.84, 33.71, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 45,
                            "s": [71.7, 35.62, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 46,
                            "s": [75.16, 37.34, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 47,
                            "s": [78.26, 38.88, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.84, 0.84, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 48,
                            "s": [81.03, 40.26, 100]
                        },
                        {
                            "i": { "x": [0.83, 0.83, 0.83], "y": [0.83, 0.83, 1] },
                            "o": { "x": [0.17, 0.17, 0.17], "y": [0.18, 0.18, 0] },
                            "t": 49,
                            "s": [83.48, 41.48, 100]
                        },
                        { "t": 50, "s": [85.66, 42.56, 100] }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": { "a": 0, "k": [150, 150] },
                            "p": { "a": 0, "k": [0, 0] },
                            "r": { "a": 0, "k": 100 }
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.925490196078, 0.062745098039, 0.392156862745, 1]
                            },
                            "o": { "a": 0, "k": 100 },
                            "cl": "dot-class",
                            "r": 1,
                            "bm": 0
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [4.04, -71.96] },
                            "a": { "a": 0, "k": [0, 0] },
                            "s": { "a": 0, "k": [100, 100] },
                            "r": { "a": 0, "k": 0 },
                            "o": { "a": 0, "k": 100 },
                            "sk": { "a": 0, "k": 0 },
                            "sa": { "a": 0, "k": 0 }
                        }
                    ],
                    "bm": 0
                }
            ],
            "ip": 0,
            "op": 51,
            "st": 10,
            "bm": 0
        }
    ],
    "markers": []
}
