import { BillerTypeEnum } from "~/src/Redux/Billers/Model/Biller.model";
import { BillerAccount } from "~/src/Redux/Billers/Model/BillerAccount.model";
import { isAutoPayRestriced } from "~/src/Common/utitlity/biller.utilities";

export const GAactionObjectResolver: { [key: string]: string } = {
  "Delete Biller": "Delete biller",
  "Disable Autopay": "Disable Autopay",
  "Delete Scheduled Payment": "Delete scheduled payment",
  "Edit Autopay": "Edit Autopay",
  "Add Autopay": "Add Autopay",
};

export const SelectionModalContentObject = {
  "Delete Biller": {
    heading: "Delete biller?",
    description:
      "Are you sure you want to permanently delete this biller? You will have to re-register to process any upcoming payments.",
  },
  "Disable Autopay": {
    heading: "Disable autopay?",
    description:
      "Are you sure you want to disable autopay? You will have to enable it again to process any future payments automatically.",
  },
  "Delete Scheduled Payment": {
    heading: "Delete scheduled payment?",
    description: "Are you sure you want to delete the scheduled payment? ",
  },
};

export type billerStatusAndAmountObjectType = {
  isScheduled: boolean;
  isDue: boolean;
  isAutoPay: boolean;
  scheduledDate: Date | undefined;
  dueDate: Date | undefined;
  dueAmount: number;
  scheduledAmount: number;
};

export const billerStatusAmountResolver = (
  billerAccount: BillerAccount
): billerStatusAndAmountObjectType => {
  let isScheduled = false,
    isDue = false,
    isAutoPay = false;
  let scheduledDate: Date | undefined = undefined;
  let dueDate: Date | undefined = undefined;
  let dueAmount = 0;
  let scheduledAmount = 0;

  if (billerAccount?.autopayStatus) {
    isAutoPay = true;
  }
  if (billerAccount?.currentBill?.billStatus === "UNPAID") {
    isDue = true;
    dueAmount = billerAccount?.currentBill?.netBillAmount;
    dueDate = billerAccount?.currentBill?.billDueDate;
  }

  if (billerAccount?.currentBill?.billStatus === "SCHEDULED") {
    isScheduled = true;
    scheduledAmount = billerAccount?.currentBill?.netBillAmount;
    scheduledDate = billerAccount?.currentBill?.billDueDate;
  }

  if (billerAccount?.paymentStatus === "SCHEDULED") {
    isScheduled = true;
    scheduledAmount = Number(billerAccount?.paymentAmount);
    scheduledDate = billerAccount?.scheduleDate;
  }

  return {
    isScheduled,
    isDue,
    isAutoPay,
    scheduledDate,
    dueDate,
    dueAmount,
    scheduledAmount,
  };
};

export const ManageBillerOptions = {
  deleteBiller: "Delete Biller",
  editAutoPay: "Edit Autopay",
  disableAutoPay: "Disable Autopay",
  deleteScheduledPayment: "Delete Scheduled Payment",
  enableAutopay: "Add Autopay",
  editNickName: "Edit Nick Name",
};

export const ManageBillerSuccessModalStatusText = {
  deleteBiller: "Biller deleted!",
  editAutoPay: "Autopay modified successfully",
  disableAutoPay: "Autopay disabled successfully",
  deleteScheduledPayment: "Scheduled payment deleted successfully",
  enableAutopay: "Autopay enabled successfully",
  scheduledPayment: "Bill payment scheduled successfully",
  payment: "Bill paid successfully",
  editNickName: "Nickname edited successfully",
};

const FilterStatusResolverObject = {
  Due: "UNPAID",
  Scheduled: "SCHEDULED",
};

const ReverseBlanketCategoryResolver = {
  Utility: [
    "Mobile Postpaid",
    "Electricity",
    "Gas",
    "Broadband Postpaid",
    "Landline Postpaid",
    "LPG Cylinder",
    "Water",
    "Tax",
  ],
  Finance: ["Mutual Fund", "Credit Card", "Insurance", "Loan"],
  Recharge: ["Mobile Prepaid", "DTH", "Fastag", "Play Store", "Cable TV"],
  // Autopay: [
  //   "Mutual Fund",
  //   "Mobile Postpaid",
  //   "Electricity",
  //   "Water",
  //   "Gas",
  //   "Broadband Postpaid",
  //   "Landline Postpaid",
  //   "Tax",
  //   "Cable TV",
  //   "Insurance",
  // ],
  // Others: [
  //   "Education",
  //   "Rental Payment",
  //   "Housing Society",
  //   "Subscriptions",
  //   "Donation",
  //   "Hospital",
  // ],
};

const searchItemFilter = (item: BillerAccount, searchValue = ""): boolean => {
  return item.billerName.toLowerCase().includes(searchValue.toLowerCase());
};

export const StatusItemFilter = (
  item: BillerAccount,
  status: "Due" | "Scheduled" | "Autopay"
): boolean | undefined => {
  if (status === "Autopay") {
    return (
      item.autopayStatus &&
      item.autopayStatus !== null &&
      item.autopayStatus !== undefined
    );
  } else {
    return (
      (item.currentBill &&
        item.currentBill.billStatus === FilterStatusResolverObject[status]) ||
      item.paymentStatus === FilterStatusResolverObject[status]
    );
  }
};

const ReverseBlanketCategoryFilter = (
  item: BillerAccount,
  status: "Utility" | "Finance" | "Recharge" // | "Others"
): boolean | undefined => {
  return ReverseBlanketCategoryResolver[status].some(
    (category) => category.toLowerCase() === item.billerCategory.toLowerCase()
  );
};

export const FilterandSearchArray = (
  registeredBillersArray: BillerAccount[],
  filterValue: string,
  searchValue: string
): BillerAccount[] => {
  if (
    filterValue === "Due" ||
    filterValue === "Scheduled" ||
    filterValue === "Autopay"
  ) {
    return registeredBillersArray.filter(
      (item) =>
        StatusItemFilter(item, filterValue) &&
        searchItemFilter(item, searchValue)
    );
  } else if (
    filterValue === "Utility" ||
    filterValue === "Finance" ||
    filterValue === "Recharge" // ||
    //filterValue === "Others"
  ) {
    return registeredBillersArray.filter(
      (item) =>
        ReverseBlanketCategoryFilter(item, filterValue) &&
        searchItemFilter(item, searchValue)
    );
  } else {
    return registeredBillersArray.filter((item) =>
      searchItemFilter(item, searchValue)
    );
  }
};

export const FilterByStatusBillerAccounts = (
  billerAccounts: BillerAccount[]
): BillerAccount[] => {
  const dueBillers: BillerAccount[] = [];
  const scheduledBillers: BillerAccount[] = [];
  const adhocBillers: BillerAccount[] = [];
  const restOfBillers: BillerAccount[] = [];
  const autoPayBillers: BillerAccount[] = [];
  const nonAutoPayBillers: BillerAccount[] = [];
  // const noAutoPayRequired = ["FASTag", "Credit Card", "LPG Cylinder"];

  dueBillers.push(
    ...billerAccounts.filter((item) => StatusItemFilter(item, "Due"))
  );
  scheduledBillers.push(
    ...billerAccounts.filter((item) => StatusItemFilter(item, "Scheduled"))
  );

  autoPayBillers.push(
    ...billerAccounts.filter(
      (item) =>
        item.billerType !== BillerTypeEnum.PAYEE &&
        !isAutoPayRestriced(item.billerCategory) &&
        StatusItemFilter(item, "Autopay") &&
        !StatusItemFilter(item, "Due") &&
        !StatusItemFilter(item, "Scheduled")
    )
  );
  adhocBillers.push(
    ...billerAccounts.filter(
      (item) =>
        !StatusItemFilter(item, "Due") &&
        !StatusItemFilter(item, "Scheduled") &&
        item.billerType === BillerTypeEnum.PAYEE
    )
  );
  restOfBillers.push(
    ...billerAccounts.filter(
      (item) =>
        !StatusItemFilter(item, "Due") &&
        !StatusItemFilter(item, "Scheduled") &&
        item.billerType !== BillerTypeEnum.PAYEE &&
        !StatusItemFilter(item, "Autopay")
    )
  );
  nonAutoPayBillers.push(
    ...billerAccounts.filter(
      (item) =>
        item.billerType !== BillerTypeEnum.PAYEE &&
        isAutoPayRestriced(item.billerCategory) &&
        !StatusItemFilter(item, "Scheduled") && //  if payment is scheduled for cc and doesn't comes under autopay
        StatusItemFilter(item, "Autopay")
    )
  );
  dueBillers.sort((entryOne, entryTwo) => {
    return (
      (entryOne.currentBill?.billDueDate.getTime() || 0) -
      (entryTwo.currentBill?.billDueDate.getTime() || 0)
    );
  });
  autoPayBillers.sort((entryOne, entryTwo) => {
    if (entryOne.billerName < entryTwo.billerName) return -1;
    if (entryOne.billerName > entryTwo.billerName) return 1;
    return 0;
    // return (
    //   entryTwo.registrationDate.getTime() - entryOne.registrationDate.getTime()
    // );
  });
  scheduledBillers.sort((entryOne, entryTwo) => {
    return (
      (entryOne.scheduleDate?.getTime() ||
        entryOne.currentBill?.billDueDate.getTime() ||
        0) -
      (entryTwo.scheduleDate?.getTime() ||
        entryTwo.currentBill?.billDueDate.getTime() ||
        0)
    );
  });
  adhocBillers.sort((entryOne, entryTwo) => {
    if (entryOne.billerName < entryTwo.billerName) return -1;
    if (entryOne.billerName > entryTwo.billerName) return 1;
    return 0;
    // return (
    //   entryTwo.registrationDate.getTime() - entryOne.registrationDate.getTime()
    // );
  });

  restOfBillers.sort((entryOne, entryTwo) => {
    if (entryOne.billerName < entryTwo.billerName) return -1;
    if (entryOne.billerName > entryTwo.billerName) return 1;
    return 0;
    // return (
    //   entryTwo.registrationDate.getTime() - entryOne.registrationDate.getTime()
    // );
  });
  nonAutoPayBillers.sort((entryOne, entryTwo) => {
    if (entryOne.billerName < entryTwo.billerName) return -1;
    if (entryOne.billerName > entryTwo.billerName) return 1;
    return 0;
    // return (
    //   entryTwo.registrationDate.getTime() - entryOne.registrationDate.getTime()
    // );
  });
  const restAllBillers = [
    ...adhocBillers,
    ...restOfBillers,
    ...nonAutoPayBillers,
  ];
  restAllBillers.sort((entryOne, entryTwo) => {
    if (entryOne.billerName < entryTwo.billerName) return -1;
    if (entryOne.billerName > entryTwo.billerName) return 1;
    return 0;
    // return (
    //   entryTwo.registrationDate.getTime() - entryOne.registrationDate.getTime()
    // );
  });

  return [
    ...dueBillers,
    ...autoPayBillers,
    ...scheduledBillers,
    ...restAllBillers,
  ];
};
