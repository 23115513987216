import { ModelMapper } from '~/src/Lib/types/modelmapper'
import parse from 'date-fns/parse'

export interface BillDetailDto {
  customer_name?: string
  billerid: string
  billeraccountid: string
  billid: string
  billnumber: string
  billdate: string
  billduedate: string
  billamount: string
  net_billamount: string //   discpunt + late fee
  billstatus: string
  currency?: string
  authenticators?: BillAuthenticatorDto[]
  early_billduedate?: string
  early_billdiscount?: string
  early_billamount?: string
  late_payment_charges?: string
  late_payment_amount?: string
  additional_details?: BillAdditionalDetailDto[]
  line_items: BillLineItemDto[]
}
export interface BillAdditionalDetailDto {
  seq: string
  value: string
  label?: string // label and billfor are interchangable
  billfor?: string
}

export interface BillAuthenticatorDto {
  seq: string
  parameter_name: string
  value: string
}

export interface BillLineItemDto {
  description: string
  amount: string
}

export enum BillStatusEnum {
  UNPAID = 'UNPAID',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED'
}
export interface BillDetail {
  customerName?: string
  billerId: string
  billerAccountId: string
  billId: string
  billNumber: string
  billDate: Date
  billDueDate: Date
  billAmount: number
  netBillAmount: number // discpunt + late fee
  billStatus: BillStatusEnum
  currency?: string
  authenticators?: BillAuthenticator[]
  earlyBillduedate?: Date
  earlyBillDiscount?: number
  earlyBillAmount?: number
  latePaymentCharges?: number
  latePaymentAmount?: number
  additionalDetails?: BillAdditionalDetail[]
  lineItems: BillLineItem[]
}
export interface BillAdditionalDetail {
  seq: number
  value: string
  label: string // label and billfor are interchangable
}

export interface BillAuthenticator {
  seq: number
  parameterName: string
  value: string
}

export interface BillLineItem {
  description: string
  amount: number
}

export class BillDetailMapper
  implements ModelMapper<BillDetailDto, BillDetail>
{
  toModel(dto: BillDetailDto): BillDetail {
    return {
      customerName: dto.customer_name,
      billerId: dto.billerid,
      billerAccountId: dto.billeraccountid,
      billId: dto.billid,
      billNumber: dto.billnumber,
      billDate: dto.billdate
        ? parse(dto.billdate, 'dd-MM-yyyy', new Date())
        : parse(dto.billduedate, 'dd-MM-yyyy', new Date()),
      billDueDate: parse(dto.billduedate, 'dd-MM-yyyy', new Date()),
      billAmount: Number.parseFloat(dto.billamount),
      netBillAmount: Number.parseFloat(dto.net_billamount),
      billStatus: dto.billstatus as BillStatusEnum,
      currency: dto.currency,
      authenticators: dto.authenticators?.map(auth => ({
        seq: Number(auth.seq),
        parameterName: auth.parameter_name,
        value: auth.value
      })),
      earlyBillduedate: dto.early_billduedate
        ? parse(dto.early_billduedate, 'dd-MM-yyyy', new Date())
        : undefined,
      earlyBillDiscount: dto.early_billdiscount
        ? Number.parseFloat(dto.early_billdiscount)
        : undefined,
      earlyBillAmount: dto.early_billamount
        ? Number.parseFloat(dto.early_billamount)
        : undefined,
      latePaymentCharges: dto.late_payment_charges
        ? Number.parseFloat(dto.late_payment_charges)
        : undefined,
      latePaymentAmount: dto.late_payment_amount
        ? Number.parseFloat(dto.late_payment_amount)
        : undefined,
      additionalDetails: dto.additional_details?.map(det => ({
        seq: Number(det.seq),
        label: det.billfor || det.label,
        value: det.value
      })),
      lineItems: dto.line_items?.map(itm => ({
        description: itm.description,
        amount: Number.parseFloat(itm.amount)
      }))
    } as BillDetail
  }
  toDto(model: BillDetail): BillDetailDto {
    throw new Error('Method not implemented.' + model)
  }
}
