import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "../ServiceTracker/Selectors";
import { getCoachMarksDetail } from "./Actions";

export const SLICE_NAME = "coachmarks";

export const getCoachMarkLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getCoachMarksDetail]);

export const getCoachMarkSuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getCoachMarksDetail]);

export const getCoachMarkErrorSelector = (state: RootState) =>
  isServiceError(state, [getCoachMarksDetail]);
