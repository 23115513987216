import { setBillerIdStatusNotLoaded } from "~/src/Redux/Billers/Reducers/Biller.reducer";
import { setBillerRegisterStatusNotLoaded } from "~/src/Redux/Billers/Reducers/RegisterBiller.reducer";
import { setBillerRegisterationToPaymentPage } from "~/src/Redux/Billers/Reducers/SearchService.reducer";
import { setValidateBillStatusNotLoaded } from "~/src/Redux/Billers/Reducers/ValidateBill.reducer";
import { setValidatePaymentStatusNotLoaded } from "~/src/Redux/Billers/Reducers/ValidatePayment.reducer";
import { getDueBills } from "~/src/Redux/Billers/Services/BillerAccount.Service";
import {
  hideLoader,
  showLoader,
} from "~/src/Redux/Loaders/Reducers/Loaders.reducers";
import { setOperatorStatusNotLoaded } from "~/src/Redux/Prepaid/Reducers/PrepaidOperator.reducer";
import { setPlanStatusNotLoaded } from "~/src/Redux/Prepaid/Reducers/PrepaidPlans.reducer";
import { DataStatus } from "~/src/Redux/remoteDataSlice";
import AppStore, { RootState } from "~/src/Configurations/AppStore";

export const billerRegistrationLoader = async () => {
  AppStore.dispatch(showLoader());
  AppStore.dispatch(setBillerRegisterationToPaymentPage({})); //clear journey data if any
  AppStore.dispatch(setBillerRegisterStatusNotLoaded(DataStatus.NOT_LOADED)); // checked /biller/register
  AppStore.dispatch(setValidatePaymentStatusNotLoaded(DataStatus.NOT_LOADED)); //check validate/bill
  AppStore.dispatch(setValidateBillStatusNotLoaded(DataStatus.NOT_LOADED)); //  checked /validate/bill-not-register

  //mobile prepaid
  AppStore.dispatch(setOperatorStatusNotLoaded(DataStatus.NOT_LOADED));
  AppStore.dispatch(setBillerIdStatusNotLoaded(DataStatus.NOT_LOADED));
  AppStore.dispatch(setPlanStatusNotLoaded(DataStatus.NOT_LOADED));
  AppStore.dispatch(setOperatorStatusNotLoaded(DataStatus.NOT_LOADED));

  const state: RootState = AppStore.getState();
  const customerId =
    state?.customerDetail?.customerDetail?.data?.customerId || "";

  if (customerId && customerId.length > 0) {
    const getDueBillsPromise =
      state?.billerAccount?.billerAccountData?.status === DataStatus.LOADED
        ? Promise.resolve(state.billerAccount?.billerAccountData?.data)
        : state?.billerAccount?.billerAccountData?.status === DataStatus.LOADING
        ? Promise.resolve()
        : AppStore.dispatch(getDueBills(customerId));

    await Promise.any([getDueBillsPromise]);
    AppStore.dispatch(hideLoader());
  }

  return "";
};
