{
    "nm": "Loader 24x24 Dark",
    "ddd": 0,
    "h": 1000,
    "w": 1000,
    "meta": { "g": "LottieFiles AE 3.5.2" },
    "layers": [
        {
            "ty": 3,
            "nm": "deleted_Null 4",
            "sr": 1,
            "st": 0,
            "op": 1800,
            "ip": 0,
            "hd": false,
            "ddd": 0,
            "bm": 0,
            "hasMask": false,
            "ao": 0,
            "ks": {
                "a": { "a": 0, "k": [50, 50, 0], "ix": 1 },
                "s": { "a": 0, "k": [184, 184, 100], "ix": 6 },
                "sk": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [501.12, 500, 0], "ix": 2 },
                "r": { "a": 0, "k": 0, "ix": 10 },
                "sa": { "a": 0, "k": 0 },
                "o": { "a": 0, "k": 0, "ix": 11 }
            },
            "ind": 1
        },
        {
            "ty": 4,
            "nm": "Ball 2",
            "sr": 1,
            "st": 5,
            "op": 1805,
            "ip": -5,
            "hd": false,
            "cl": "one-dot",
            "ln": "dot",
            "ddd": 0,
            "bm": 0,
            "hasMask": false,
            "ao": 0,
            "ks": {
                "a": { "a": 0, "k": [4.043, 3.043, 0], "ix": 1 },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": { "x": 0.8, "y": 0 },
                            "i": { "x": 0.999, "y": 1 },
                            "s": [132, 132, 100],
                            "t": 0
                        },
                        {
                            "o": { "x": 0.167, "y": 0.167 },
                            "i": { "x": 0.833, "y": 0.833 },
                            "s": [105.6, 132, 100],
                            "t": 23
                        },
                        {
                            "o": { "x": 0.167, "y": 0.167 },
                            "i": { "x": 0.833, "y": 0.833 },
                            "s": [132, 105.6, 100],
                            "t": 25
                        },
                        {
                            "o": { "x": 0.001, "y": 0 },
                            "i": { "x": 0.2, "y": 1 },
                            "s": [105.6, 132, 100],
                            "t": 27
                        },
                        { "s": [132, 132, 100], "t": 50 }
                    ],
                    "ix": 6
                },
                "sk": { "a": 0, "k": 0 },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": { "x": 0.8, "y": 0 },
                            "i": { "x": 0.999, "y": 1 },
                            "s": [50, 24, 0],
                            "t": 0,
                            "ti": [0, 0, 0],
                            "to": [0, 0, 0]
                        },
                        {
                            "o": { "x": 0.001, "y": 0 },
                            "i": { "x": 0.2, "y": 1 },
                            "s": [50, 225.087, 0],
                            "t": 25,
                            "ti": [0, 0, 0],
                            "to": [0, 0, 0]
                        },
                        { "s": [50, 24, 0], "t": 50 }
                    ],
                    "ix": 2
                },
                "r": { "a": 0, "k": 0, "ix": 10 },
                "sa": { "a": 0, "k": 0 },
                "o": { "a": 0, "k": 100, "ix": 11 }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Rectangle 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "rc",
                            "bm": 0,
                            "hd": false,
                            "mn": "ADBE Vector Shape - Rect",
                            "nm": "Rectangle Path 1",
                            "d": 1,
                            "p": { "a": 0, "k": [0, 0], "ix": 3 },
                            "r": { "a": 0, "k": 100, "ix": 4 },
                            "s": { "a": 0, "k": [150, 150], "ix": 2 }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "cl": "dot-class",
                            "nm": "Fill 1",
                            "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
                            "r": 1,
                            "o": { "a": 0, "k": 100, "ix": 5 }
                        },
                        {
                            "ty": "tr",
                            "a": { "a": 0, "k": [0, 0], "ix": 1 },
                            "s": { "a": 0, "k": [100, 100], "ix": 3 },
                            "sk": { "a": 0, "k": 0, "ix": 4 },
                            "p": { "a": 0, "k": [4.043, -71.957], "ix": 2 },
                            "r": { "a": 0, "k": 0, "ix": 6 },
                            "sa": { "a": 0, "k": 0, "ix": 5 },
                            "o": { "a": 0, "k": 100, "ix": 7 }
                        }
                    ]
                }
            ],
            "ind": 2,
            "parent": 1
        },
        {
            "ty": 4,
            "nm": "Hole 5",
            "sr": 1,
            "st": 5,
            "op": 1780,
            "ip": -5,
            "hd": false,
            "cl": "one-dot",
            "ln": "shadow",
            "ddd": 0,
            "bm": 0,
            "hasMask": false,
            "ao": 0,
            "ks": {
                "a": { "a": 0, "k": [4.043, -71.957, 0], "ix": 1 },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": { "x": 0.8, "y": 0 },
                            "i": { "x": 0.999, "y": 1 },
                            "s": [232.009, 115.275, 100],
                            "t": 0
                        },
                        {
                            "o": { "x": 0.001, "y": 0 },
                            "i": { "x": 0.2, "y": 1 },
                            "s": [0, 0, 100],
                            "t": 25
                        },
                        { "s": [232.009, 115.275, 100], "t": 50 }
                    ],
                    "ix": 6
                },
                "sk": { "a": 0, "k": 0 },
                "p": { "a": 0, "k": [501.12, 828.52, 0], "ix": 2 },
                "r": { "a": 0, "k": 0, "ix": 10 },
                "sa": { "a": 0, "k": 0 },
                "o": { "a": 0, "k": 20, "ix": 11 }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Rectangle 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "rc",
                            "bm": 0,
                            "hd": false,
                            "mn": "ADBE Vector Shape - Rect",
                            "nm": "Rectangle Path 1",
                            "d": 1,
                            "p": { "a": 0, "k": [0, 0], "ix": 3 },
                            "r": { "a": 0, "k": 100, "ix": 4 },
                            "s": { "a": 0, "k": [150, 150], "ix": 2 }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "cl": "dot-class",
                            "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
                            "r": 1,
                            "o": { "a": 0, "k": 100, "ix": 5 }
                        },
                        {
                            "ty": "tr",
                            "a": { "a": 0, "k": [0, 0], "ix": 1 },
                            "s": { "a": 0, "k": [100, 100], "ix": 3 },
                            "sk": { "a": 0, "k": 0, "ix": 4 },
                            "p": { "a": 0, "k": [4.043, -71.957], "ix": 2 },
                            "r": { "a": 0, "k": 0, "ix": 6 },
                            "sa": { "a": 0, "k": 0, "ix": 5 },
                            "o": { "a": 0, "k": 100, "ix": 7 }
                        }
                    ]
                }
            ],
            "ind": 3
        }
    ],
    "v": "4.8.0",
    "fr": 60,
    "op": 51,
    "ip": 0,
    "assets": []
}
