import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";
import {
  PromoCodeOffersData,
  PromoCodeOffersDto,
  PromoCodeOffersDataMapper,
} from "~/src/Redux/PromoCode/Model/PromoCodeOffers.model";
import { getPromoCodeOffersDataAction } from "~/src/Redux/PromoCode/Actions/PromoCodeOffers.action";

type payload = {
  customerId: string;
  fetchRegistration: boolean;
};

async function Service(req: payload) {
  const { customerId, fetchRegistration } = req;
  const options = {
    url: URL.PROMOCODE.PROMOCODES_ACTIVE_COUPONS,
    method: "POST",
    data: {
      customerId: customerId,
      fetchRegistration: fetchRegistration,
    },
  };

  try {
    const response = await asHttp.request(options);
    const { data: body } = response;
    const { data } = body;
    const mapper = new PromoCodeOffersDataMapper();
    const mappedData = data.map((promoCodeOffers: PromoCodeOffersDto) =>
      mapper.toModel(promoCodeOffers)
    );

    return mappedData;
  } catch (err) {
    throw err;
  }
}

export const getPromoCodeOffersListThunk = serviceActionCreator(
  getPromoCodeOffersDataAction,
  Service
);
