import { ApiResponseError } from "../Lib/types/api";

export enum DataStatus {
  NOT_LOADED = "NOT_LOADED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERRORED = "ERRORED",
}

export interface TransferData<T> {
  status: DataStatus;
  data: T;
  error?: ApiResponseError;
}

export interface IRemoteData<T> {
  status: DataStatus;
  data?: T;
  error?: ApiResponseError;
}

// Refactored createRemoteData to return only serializable values without methods
export const createRemoteData = <T>(
  status: DataStatus = DataStatus.NOT_LOADED,
  data?: T,
  error?: ApiResponseError
): IRemoteData<T> => {
  return {
    status,
    data,
    error,
  };
};

// Functions to handle transitions (external functions to avoid non-serializable state)
export const setLoading = <T>(state: IRemoteData<T>): IRemoteData<T> => {
  return createRemoteData(DataStatus.LOADING, state.data);
};

export const setData = <T>(data: T, state: IRemoteData<T>): IRemoteData<T> => {
  return createRemoteData(DataStatus.LOADED, data);
};

export const setError = <T>(
  error: ApiResponseError,
  state: IRemoteData<T>
): IRemoteData<T> => {
  return createRemoteData(DataStatus.ERRORED, state.data, error);
};
