import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { CustomerDetailMapper } from "../Model";
import { getCustomerDataConfigAction } from "../Customer.action";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";

async function Service(customerDetail: string) {
  const options = {
    url: URL.AUTHENTICATION.CUSTOMER_DETAIL,
    method: "POST",
    data: { data: customerDetail },
  };

  try {
    const response = await asHttp.request(options);
    const { data: body } = response;
    const { data } = body;
    const mappedData = await new CustomerDetailMapper().toModel(data);

    return mappedData;
  } catch (err) {
    throw err;
  }
}

export const getCustomerDetailThunk = serviceActionCreator(
  getCustomerDataConfigAction,
  Service
);
