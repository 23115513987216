import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selectors";
import { BannerData } from "./Model";
import { getBannersDataConfigAction } from "./Actions";

import {
  createRemoteData,
  IRemoteData,
  setLoading,
  setData,
  setError,
} from "../remoteDataSlice";

export type BannersState = IRemoteData<BannerData[]>;

const initialState: BannersState = createRemoteData();

const sliceOptions: CreateSliceOptions<BannersState> = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<BannersState>): void => {
    builder.addCase(
      getBannersDataConfigAction.success,
      (state, { payload }) => {
        return setData(payload, state);
      }
    );
    builder.addCase(
      getBannersDataConfigAction.error,
      (state: BannersState, { payload }) => {
        return setError(payload, state);
      }
    );
    builder.addCase(
      getBannersDataConfigAction.loading,
      (state: BannersState) => {
        return setLoading(state);
      }
    );
  },
};

const bannersSlice = createSlice(sliceOptions);

export default bannersSlice.reducer;
