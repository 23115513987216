import { redirect } from "react-router-dom";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { getBannersThunk } from "~/src/Redux/Banners/banner.Service";
import { modifyRegisteredBillerNotLoaded } from "~/src/Redux/Billers/Reducers/ModifyRegisteredBiller.reducer";
import { setBillerRegisterStatusNotLoaded } from "~/src/Redux/Billers/Reducers/RegisterBiller.reducer";
import {
  resetBillerRegisterationToPaymentPage,
  resetSearchBillerState,
  resetSelectedBillerDetails,
} from "~/src/Redux/Billers/Reducers/SearchService.reducer";
import { setValidateBillStatusNotLoaded } from "~/src/Redux/Billers/Reducers/ValidateBill.reducer";
import { setValidatePaymentStatusNotLoaded } from "~/src/Redux/Billers/Reducers/ValidatePayment.reducer";
import { getDueBills } from "~/src/Redux/Billers/Services/BillerAccount.Service";
import { getCategoryListThunk } from "~/src/Redux/Categories/Services/getCategoryList.Service";
import { getCoachMarksDataThunk } from "~/src/Redux/CoachMarks/Services/getCoachmarks.Service";
import { setUtmDetails } from "~/src/Redux/Customer/Customer.reducer";
import { getCustomerDetailThunk } from "~/src/Redux/Customer/Services/getCustomerDetail.Service";
import { getPaymentHistoryListThunk } from "~/src/Redux/History/Services/getHistoryList.Service";
import { hideLoader } from "~/src/Redux/Loaders/Reducers/Loaders.reducers";
import { setMakePaymentStatusNotLoaded } from "~/src/Redux/Payment/Reducers/MakePayment.reducers";
import { getPromoCodeOffersListThunk } from "~/src/Redux/PromoCode/Services/PromoCodeOffers.service";
import { DataStatus } from "~/src/Redux/remoteDataSlice";
import { getDiscoveryApiDetailsListThunk } from "~/src/Redux/Splash/Service/discoveryApi.service";
import AppStore, { RootState } from "../../Configurations/AppStore";
import { setCashbackActiveOfferStatusNotLoaded } from "~/src/Redux/Cashback/Reducers/CashbackActiveOffer.reducer";
import { setBillerStateNotLoaded } from "~/src/Redux/Billers/Reducers/Biller.reducer";

let homePageLoaderExecuted = false;

export const homePageLoader = async () => {
  try {
    if (homePageLoaderExecuted) {
      console.log("Loader already executed, skipping...");
      return null;
    }
    homePageLoaderExecuted = true; // Set the flag

    //reset previous journey state
    AppStore.dispatch(setBillerRegisterStatusNotLoaded(DataStatus.NOT_LOADED));
    AppStore.dispatch(setValidatePaymentStatusNotLoaded(DataStatus.NOT_LOADED));
    AppStore.dispatch(setValidateBillStatusNotLoaded(DataStatus.NOT_LOADED));
    AppStore.dispatch(resetBillerRegisterationToPaymentPage({}));
    AppStore.dispatch(resetSelectedBillerDetails({}));
    AppStore.dispatch(setMakePaymentStatusNotLoaded({}));
    AppStore.dispatch(modifyRegisteredBillerNotLoaded({}));
    AppStore.dispatch(resetSearchBillerState({}));
    AppStore.dispatch(setBillerStateNotLoaded({}));
    AppStore.dispatch(
      setCashbackActiveOfferStatusNotLoaded(DataStatus.NOT_LOADED)
    );

    console.log("Loader called");

    const state: RootState = AppStore.getState();
    const customerId =
      state?.customerDetail?.customerDetail?.data?.customerId || "";

    if (customerId && customerId.length > 0) {
      const bannerPromise =
        state?.banners?.status === DataStatus.LOADED
          ? Promise.resolve(state.banners.data)
          : AppStore.dispatch(getBannersThunk());

      const getCategoryListPromise =
        state?.categoryList?.categoryList?.status === DataStatus.LOADED
          ? Promise.resolve(state.categoryList.categoryList.data)
          : AppStore.dispatch(getCategoryListThunk(customerId));

      const getPaymentHistoryListPromise =
        state?.historyList?.historyDetailData?.status === DataStatus.LOADED
          ? Promise.resolve(state.historyList.historyDetailData.data)
          : state?.historyList?.historyDetailData?.status === DataStatus.LOADING
          ? Promise.resolve()
          : AppStore.dispatch(
              getPaymentHistoryListThunk({
                customerId: customerId,
                oneYearData: true,
              })
            );

      const getDueBillsPromise =
        state?.billerAccount?.billerAccountData?.status === DataStatus.LOADED
          ? Promise.resolve(state.billerAccount?.billerAccountData?.data)
          : state?.billerAccount?.billerAccountData?.status ===
            DataStatus.LOADING
          ? Promise.resolve()
          : AppStore.dispatch(getDueBills(customerId));

      const getDiscoveryApiDetailsListPromise =
        state?.discoveryApiList?.discoveryApiData?.status === DataStatus.LOADED
          ? Promise.resolve(state.discoveryApiList?.discoveryApiData?.data)
          : AppStore.dispatch(getDiscoveryApiDetailsListThunk(customerId));

      const getCoachMarksDataPromise =
        state?.coachmarks?.coachMarksData?.status === DataStatus.LOADED
          ? Promise.resolve(state.coachmarks?.coachMarksData?.data)
          : AppStore.dispatch(getCoachMarksDataThunk({ customerId }));

      const getPromoCodeOffersPromise =
        state?.promoCodeOffers?.promoCodesOffersData?.status ===
        DataStatus.LOADED
          ? Promise.resolve(state?.promoCodeOffers?.promoCodesOffersData?.data)
          : AppStore.dispatch(
              getPromoCodeOffersListThunk({
                customerId: customerId,
                fetchRegistration: true,
              })
            );

      await Promise.any([
        bannerPromise,
        getCategoryListPromise,
        getPaymentHistoryListPromise,
        getDueBillsPromise,
        getDiscoveryApiDetailsListPromise,
        getCoachMarksDataPromise,
        getPromoCodeOffersPromise,
      ]);

      AppStore.dispatch(hideLoader());
    }

    homePageLoaderExecuted = false;
    return "";
  } catch (error) {
    window.location =
      APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString() as any;
    console.error("Error in homePageLoader:", error);
    throw error;
  }
};

const UTM_SOURCES = process.env.UTM_SOURCES || "siddhi,saksham";
export const verifyDetailPageLoader = async ({
  request,
}: {
  request: Request;
}) => {
  const IS_ENCRYPT =
    (process.env.DISABLE_PAYLOAD_CRYPTOGRAPHY || "").toLowerCase() === "false";

  const { search, searchParams } = new URL(request.url);
  let data: string = searchParams.get("data") || "";

  if (
    search.includes("&") &&
    (searchParams.has("utm_source") ||
      searchParams.has("utm_medium") ||
      searchParams.has("utm_campaign"))
  ) {
    data = search.split("data=")[1].split("&")[0];
  }
  //check added in case utm params aren't there but other & params are there
  if (search.includes("&")) {
    data = search.split("data=")[1].split("&")[0];
  } else {
    data = search.split("=")[1];
  }
  if (searchParams.has("utm_source")) {
    const utmSource = searchParams?.get("utm_source");
    if (utmSource && UTM_SOURCES.split(",").includes(utmSource.toLowerCase())) {
      if (searchParams.has("utm_medium") && searchParams.has("utm_campaign")) {
        const utmCampaign = searchParams?.get("utm_campaign");
        const utm_medium = searchParams?.get("utm_medium");

        console.log("utmSource", utmSource, utmCampaign, utm_medium);
        if (utmCampaign && utm_medium)
          AppStore.dispatch(
            setUtmDetails({
              employeeCode: utmCampaign,
              channelId: utmSource,
              branchCode: utm_medium,
            })
          );
      }
    }
  } else {
    data = search.split("data=")[1].split("&")[0];
  }

  if (data.length === 0) {
    window.location =
      APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString() as any;
    throw redirect(APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString());
  } else {
    if (IS_ENCRYPT) {
      encodeURIComponent(data);
    }

    const customerData = await AppStore.dispatch(getCustomerDetailThunk(data));
    if (
      customerData?.billeraccountid &&
      customerData?.billeraccountid.length > 0 &&
      customerData?.customerId &&
      customerData?.customerId?.length > 0
    ) {
      await AppStore.dispatch(getDueBills(customerData?.customerId));
    }
    return "";
  }
};
