import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { getBannersDataConfigAction } from "./Actions";
import serviceActionCreator from "~/src/Redux/serviceActionCreator";
import { BannerMapper, BannerData } from "./Model";

async function Service() {
  const options = {
    url: URL.BANNERS,
    method: "GET",
  };

  try {
    const response = await asHttp.request(options);
    const { data: body } = response;
    const { data } = body;
    const mappedData = data.map((biller: BannerData) => BannerMapper(biller));
    return mappedData;
  } catch (err) {
    throw err;
  }
}

export const getBannersThunk = serviceActionCreator(
  getBannersDataConfigAction,
  Service
);
