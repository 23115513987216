import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import { RootState } from '~/src/Configurations/AppStore'
import { AS_API_DOMAIN } from '~/src/Configurations/env'
import { URL } from '~/src/Constants/API_URLS'

import { Action, ThunkDispatch, ThunkAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '~/src/Lib/types/api'

import { CategoryListMapper } from '../Model'
import {
  fetchCategoryList,
  fetchCategoryListFail,
  fetchCategoryListSuccess
} from '../Reducer'
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
const CATEGORY_LIST_URL = URL.CATEGORY_LIST
export const getCategoryListThunk =
  (customerId: string): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchCategoryList(customerId))
    try {
      console.log('heyyy')
      const options: WebHttpRequestOptions = {
        url: CATEGORY_LIST_URL,
        method: 'GET'
        // data: { customerId }
      }

      // const response = await asHttp.request(options)
      // const { data: body } = response
      // const { data } = body
      const response = await asHttp.request(options)
      const { data } = response
      const { tokens = {} } = data
      const token =
        // Instore.get('auth') ||
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY'

      const { accessToken = token, refreshToken = '' } = tokens
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken)
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken)
      const mapper = new CategoryListMapper()
      console.log('response', response)
      const mappedData = response.data.data.map(biller =>
        mapper.toModel(biller)
      )
      // const mappedData = await new CategoryListMapper().toModel(data)

      dispatch(fetchCategoryListSuccess(mappedData))
      return mappedData
    } catch (error) {
      dispatch(fetchCategoryListFail(error as ApiResponseError))
      throw error
    }
  }
