import SingleDotLoader from './assets/singleDotLoader.json';
import ThreeDotLoader from './assets/threeDotLoader.json';
export const DS_LOADER_DEFAULT_PROPS = {
    'ds-variant': 'threeDot',
    position: 'fixed',
    backdrop: true,
    loop: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        progressiveLoad: true
    },
    style: { height: '100px', width: '100px' }
};
export const DATA_MAP = {
    singleDot: SingleDotLoader,
    threeDot: ThreeDotLoader
};
