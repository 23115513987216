import { combineReducers, Reducer, ReducersMapObject } from "redux";
import AuthReducer, { AuthState } from "./Auth/Reducer";
import { SLICE_NAME as AuthSliceName } from "./Auth/Selectors";
import ServiceTrackerReducer, {
  ServiceTrackerState,
} from "./ServiceTracker/Reducer";
import { SLICE_NAME as ServiceTrackerSliceName } from "./ServiceTracker/Selectors";
import BannersReducer, { BannersState } from "./Banners/Reducer";
import { SLICE_NAME as BannerSliceName } from "./Banners/Selectors";
import { SLICE_NAME as CustomerSliceName } from "./Customer/Customer.selector";
import CustomerReducer, {
  CustomerDetailState,
} from "./Customer/Customer.reducer";
import { SLICE_NAME as CategoryListSliceName } from "./Categories/Selector";
import CategoryListReducer, { CategoryListState } from "./Categories/Reducer";
import HistoryListReducer, { HistoryListState } from "./History/Reducer";
import { SLICE_NAME as HistoryListSliceName } from "./History/Selector";
import { SLICE_NAME as BillerAccountSlice } from "./Billers/Selectors/BillerAccount.selector";
import BillerAccountReducer, {
  BillerAccountStateType,
} from "./Billers/Reducers/BillerAccount.reducer";
import { SLICE_NAME as SearchServiceSlice } from "./Billers/Selectors/SearchService.selector";
import SearchServiceReducer, {
  BillerDataStateType,
} from "./Billers/Reducers/SearchService.reducer";
import { SLICE_NAME as ComplaintTypesSliceName } from "./Support/Selectors/ComplaintType.selector";
import ComplaintTypeReducer, {
  ComplaintTypesListState,
} from "./Support/Reducers/ComplaintType.reducer";
import RaiseComplaintReducer, {
  RaiseComplaintListState,
} from "./Support/Reducers/RaiseComplaint.reducer";
import { SLICE_NAME as RaiseComplaintSliceName } from "./Support/Selectors/RaiseComplaint.selector";
import { SLICE_NAME as ValidatePaymentSlice } from "./Billers/Selectors/ValidatePayment.selector";
import ValidatePaymentReducer, {
  ValidatePaymentStateType,
} from "./Billers/Reducers/ValidatePayment.reducer";
import { SLICE_NAME as BillerSliceName } from "./Billers/Selectors/Biller.selector";
import BillerReducer, {
  BillerDataStateType as BillerAccountDataStateType,
} from "./Billers/Reducers/Biller.reducer";
import PastTicketsReducer, {
  PastTicketsListState,
} from "./Support/Reducers/PastTickets.reducer";
import { SLICE_NAME as PastTicketsSliceName } from "./Support/Selectors/PastTickets.selector";
import ComplaintStatusReducer, {
  ComplaintStatusListState,
} from "./Support/Reducers/ComplaintStatus.reducer";
import { SLICE_NAME as ComplaintStatusSliceName } from "./Support/Selectors/ComplaintStatus.selector";
import AccountDetailsReducers, {
  AccountDetailsListState,
} from "./Payment/Reducers/AccountDetails.reducers";
import { SLICE_NAME as AccountDetailsSliceName } from "./Payment/Selectors/AccountDetails.selector";
import CreditCardDetailsReducers, {
  CreditCardDetailsListState,
} from "./Payment/Reducers/CreditCardDetails.reducers";
import { SLICE_NAME as CreditCardDetailsSliceName } from "./Payment/Selectors/CreditCardDetails.selector";
import RegisterBillerReducer, {
  RegisterBillerDetailsListState,
} from "./Billers/Reducers/RegisterBiller.reducer";
import { SLICE_NAME as RegisterBillerDetailsSliceName } from "./Billers/Selectors/RegisterBiller.selector";
import CityListDetailsReducer, {
  CityListState,
} from "./Billers/Reducers/CityList.reducer";
import { SLICE_NAME as CityDetailsSliceName } from "~/src/Redux/Billers/Selectors/CityList.selector";
import PrepaidOperatorReducer, {
  PrepaidOperatorListState,
} from "./Prepaid/Reducers/PrepaidOperator.reducer";
import { SLICE_NAME as PrepaidOperatorSliceName } from "./Prepaid/Selectors/PrepaidOperator.selector";
import PrepaidCircleReducer, {
  PrepaidCircleListState,
} from "./Prepaid/Reducers/PrepaidCircle.reducer";
import { SLICE_NAME as PrepaidCircleSliceName } from "./Prepaid/Selectors/PrepaidCircle.selector";
import PrepaidPlansReducer, {
  PrepaidPlansListState,
} from "./Prepaid/Reducers/PrepaidPlans.reducer";
import { SLICE_NAME as PrepaidPlansSliceName } from "./Prepaid/Selectors/PrepaidPlans.selector";
import MakePaymentReducer, {
  MakePaymentListState,
} from "./Payment/Reducers/MakePayment.reducers";
import { SLICE_NAME as MakePaymentSliceName } from "./Payment/Selectors/MakePayment.selector";
import SendOtpReducer, {
  SendOtpListState,
} from "./Payment/Reducers/SendOtp.reducers";
import { SLICE_NAME as SendOtpSliceName } from "./Payment/Selectors/SendOtp.selector";
import ValidateOtpReducer, {
  ValidateOtpListState,
} from "./Payment/Reducers/ValidateOtp.reducers";
import { SLICE_NAME as ValidateOtpSliceName } from "./Payment/Selectors/ValidateOtp.selector";
import MpinReducer, { MpinListState } from "./Payment/Reducers/Mpin.reducers";
import { SLICE_NAME as MpinSliceName } from "./Payment/Selectors/Mpin.selector";
import CashbackActiveOfferReducer, {
  CashbackActiveOfferListState,
} from "./Cashback/Reducers/CashbackActiveOffer.reducer";
import { SLICE_NAME as CashbackActiveOfferSliceName } from "~/src/Redux/Cashback/Selectors/CashbackActiveOffer.selector";
import CashbackCustomerDetailReducer, {
  CashbackDetailListState,
} from "./Cashback/Reducers/CashbackCustomerDetail.reducer";
import { SLICE_NAME as CashbackCustomerDetailSliceName } from "~/src/Redux/Cashback/Selectors/CashbackCustomerDetail.selector";
import RecentCashbackDetailReducer, {
  RecentCashbackDetailListState,
} from "./Cashback/Reducers/RecentCashbackDetail.reducer";
import { SLICE_NAME as RecentCashbackDetailSliceName } from "~/src/Redux/Cashback/Selectors/RecentCashbackDetail.selector";
import MilestonesDetailReducer, {
  MilestonesDetailListState,
} from "~/src/Redux/Cashback/Reducers/MilestonesDetail.reducer";
import { SLICE_NAME as MilestonesDetailSliceName } from "~/src/Redux/Cashback/Selectors/MilestonesDetail.selector";
import CashbackTransactionDetailReducer, {
  CashbackTransactionListState,
} from "./Cashback/Reducers/CashbackTransactionDetail.reducer";
import { SLICE_NAME as CashbackTransactionDetail } from "~/src/Redux/Cashback/Selectors/CashbackTransactionDetail.selector";
import TncDetailReducer, {
  CashbackTncListState,
} from "~/src/Redux/T&C/Reducers/TermsConditions.reducer";
import { SLICE_NAME as TncDetailSliceName } from "~/src/Redux/T&C/Selectors/TermsConditions.selector";
import DeleteRegisterBillerReducer, {
  DeleteRegisterBillerListState,
} from "./Billers/Reducers/DeleteRegisterBiller.Reducer";
import { SLICE_NAME as DeleteBillerSliceName } from "~/src/Redux/Billers/Selectors/DeleteRegisterBiller.selector";
import ModifyRegisteredBillerReducer, {
  ModifyRegisteredBillerListState,
} from "./Billers/Reducers/ModifyRegisteredBiller.reducer";
import { SLICE_NAME as ModifyBillerSliceName } from "~/src/Redux/Billers/Selectors/ModifyRegisteredBiller.selector";
import ScheduledBillersReducer, {
  ScheduledBillersListState,
} from "~/src/Redux/Billers/Reducers/ScheduledBillers.reducer";
import { SLICE_NAME as ScheduledBillersSliceName } from "~/src/Redux/Billers/Selectors/ScheduledBillers.selector";
import StopScheduledPaymentReducer, {
  StopScheduledPaymentListState,
} from "~/src/Redux/Payment/Reducers/StopScheduledPayment.reducer";
import { SLICE_NAME as StopScheduledPaymentSliceName } from "~/src/Redux/Payment/Selectors/StopScheduledPayment.selector";
import { SLICE_NAME as LoaderSliceName } from "~/src/Redux/Loaders/Selectors/Loaders.selector";
import ValidateBillReducer, {
  ValidateBillStateType,
} from "~/src/Redux/Billers/Reducers/ValidateBill.reducer";
import { SLICE_NAME as ValidateBillSliceName } from "~/src/Redux/Billers/Selectors/ValidateBill.selector";
import FaqReducer, { FaqListState } from "~/src/Redux/FAQ/Faq.reducer";
import { SLICE_NAME as FaqSliceName } from "~/src/Redux/FAQ/Faq.selector";
import LoadersReducers, {
  LoaderState,
} from "./Loaders/Reducers/Loaders.reducers";
import DownloadReceiptReducer, {
  DownloadReceiptState,
} from "~/src/Redux/Payment/Reducers/DownloadReceipt.reducer";
import { SLICE_NAME as downloadReceiptSliceName } from "~/src/Redux/Payment/Selectors/DownloadReceipt.selector";
import DiscoveryApiReducer, {
  DiscoveryApiListState,
} from "~/src/Redux/Splash/Reducer/DiscoveryApi.reducer";
import { SLICE_NAME as DiscoveryApiSliceName } from "~/src/Redux/Splash/Selector/DiscoveryApi.selector";
import CoachMarksRedcuer, {
  CoachMarksState,
} from "~/src/Redux/CoachMarks/Reducer";
import { SLICE_NAME as CoachMarksSliceName } from "~/src/Redux/CoachMarks/Selector";
import HandshakeReducer, {
  HandshakeState,
} from "~/src/Redux/Handshake/Reducer";
import { SLICE_NAME as handshakeSliceName } from "~/src/Redux/Handshake/Selector";
import { SLICE_NAME as promoCodeOffersSliceName } from "~/src/Redux/PromoCode/Selectors/PromoCodeOffers.selector";
import PromoCodeOffersReducer, {
  PromoCodeOffersListState,
} from "~/src/Redux/PromoCode/Reducers/PromoCodeOffers.reducer";

export type TRootState = {
  [AuthSliceName]: AuthState;
  [ServiceTrackerSliceName]: ServiceTrackerState;
  [BannerSliceName]: BannersState;
  [CustomerSliceName]: CustomerDetailState;
  [CategoryListSliceName]: CategoryListState;
  [HistoryListSliceName]: HistoryListState;
  [BillerAccountSlice]: BillerAccountStateType;
  [SearchServiceSlice]: BillerDataStateType;
  [ComplaintTypesSliceName]: ComplaintTypesListState;
  [RaiseComplaintSliceName]: RaiseComplaintListState;
  [ValidatePaymentSlice]: ValidatePaymentStateType;
  [BillerSliceName]: BillerAccountDataStateType;
  [PastTicketsSliceName]: PastTicketsListState;
  [ComplaintStatusSliceName]: ComplaintStatusListState;
  [AccountDetailsSliceName]: AccountDetailsListState;
  [CreditCardDetailsSliceName]: CreditCardDetailsListState;
  [RegisterBillerDetailsSliceName]: RegisterBillerDetailsListState;
  [CityDetailsSliceName]: CityListState;
  [PrepaidOperatorSliceName]: PrepaidOperatorListState;
  [PrepaidCircleSliceName]: PrepaidCircleListState;
  [PrepaidPlansSliceName]: PrepaidPlansListState;
  [MakePaymentSliceName]: MakePaymentListState;
  [SendOtpSliceName]: SendOtpListState;
  [ValidateOtpSliceName]: ValidateOtpListState;
  [MpinSliceName]: MpinListState;
  [CashbackActiveOfferSliceName]: CashbackActiveOfferListState;
  [CashbackCustomerDetailSliceName]: CashbackDetailListState;
  [RecentCashbackDetailSliceName]: RecentCashbackDetailListState;
  [MilestonesDetailSliceName]: MilestonesDetailListState;
  [CashbackTransactionDetail]: CashbackTransactionListState;
  [TncDetailSliceName]: CashbackTncListState;
  [DeleteBillerSliceName]: DeleteRegisterBillerListState;
  [ModifyBillerSliceName]: ModifyRegisteredBillerListState;
  [ScheduledBillersSliceName]: ScheduledBillersListState;
  [StopScheduledPaymentSliceName]: StopScheduledPaymentListState;
  [ValidateBillSliceName]: ValidateBillStateType;
  [LoaderSliceName]: LoaderState;
  [FaqSliceName]: FaqListState;
  [downloadReceiptSliceName]: DownloadReceiptState;
  [DiscoveryApiSliceName]: DiscoveryApiListState;
  [CoachMarksSliceName]: CoachMarksState;
  [handshakeSliceName]: HandshakeState;
  [promoCodeOffersSliceName]: PromoCodeOffersListState;
};

const reducers: ReducersMapObject = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [BannerSliceName]: BannersReducer,
  [CustomerSliceName]: CustomerReducer,
  [CategoryListSliceName]: CategoryListReducer,
  [HistoryListSliceName]: HistoryListReducer,
  [BillerAccountSlice]: BillerAccountReducer,
  [SearchServiceSlice]: SearchServiceReducer,
  [ComplaintTypesSliceName]: ComplaintTypeReducer,
  [RaiseComplaintSliceName]: RaiseComplaintReducer,
  [ValidatePaymentSlice]: ValidatePaymentReducer,
  [BillerSliceName]: BillerReducer,
  [PastTicketsSliceName]: PastTicketsReducer,
  [ComplaintStatusSliceName]: ComplaintStatusReducer,
  [AccountDetailsSliceName]: AccountDetailsReducers,
  [CreditCardDetailsSliceName]: CreditCardDetailsReducers,
  [RegisterBillerDetailsSliceName]: RegisterBillerReducer,
  [CityDetailsSliceName]: CityListDetailsReducer,
  [PrepaidOperatorSliceName]: PrepaidOperatorReducer,
  [PrepaidCircleSliceName]: PrepaidCircleReducer,
  [PrepaidPlansSliceName]: PrepaidPlansReducer,
  [MakePaymentSliceName]: MakePaymentReducer,
  [SendOtpSliceName]: SendOtpReducer,
  [ValidateOtpSliceName]: ValidateOtpReducer,
  [MpinSliceName]: MpinReducer,
  [CashbackActiveOfferSliceName]: CashbackActiveOfferReducer,
  [CashbackCustomerDetailSliceName]: CashbackCustomerDetailReducer,
  [RecentCashbackDetailSliceName]: RecentCashbackDetailReducer,
  [MilestonesDetailSliceName]: MilestonesDetailReducer,
  [CashbackTransactionDetail]: CashbackTransactionDetailReducer,
  [TncDetailSliceName]: TncDetailReducer,
  [DeleteBillerSliceName]: DeleteRegisterBillerReducer,
  [ModifyBillerSliceName]: ModifyRegisteredBillerReducer,
  [ScheduledBillersSliceName]: ScheduledBillersReducer,
  [StopScheduledPaymentSliceName]: StopScheduledPaymentReducer,
  [ValidateBillSliceName]: ValidateBillReducer,
  [LoaderSliceName]: LoadersReducers,
  [FaqSliceName]: FaqReducer,
  [downloadReceiptSliceName]: DownloadReceiptReducer,
  [DiscoveryApiSliceName]: DiscoveryApiReducer,
  [CoachMarksSliceName]: CoachMarksRedcuer,
  [handshakeSliceName]: HandshakeReducer,
  [promoCodeOffersSliceName]: PromoCodeOffersReducer,
};

const persistedReducers: any[] = [...Object.values(reducers)]; //[CustomerReducer, SearchServiceReducer];

export default combineReducers<TRootState>(reducers);
export { persistedReducers };
