import AppStore, { RootState } from "../../../Configurations/AppStore";

import {
  getBiller,
  getBillerAccount,
  getBillerStatus,
} from "~/src/Redux/Billers/Services/Biller.Service";
import { registerBillerListThunk } from "~/src/Redux/Billers/Services/RegisterBillerService";
import { validatePayment as validatePaymentReq } from "~/src/Redux/Billers/Services/ValidatePayment.service";
import { getCashbackActiveOfferListThunk } from "~/src/Redux/Cashback/Services/CashbackActiveOffer.service";

import { twoDecimalPointRestriction } from "~/src/Common/utitlity/validation.utilities";
import { setBillerRegisterationToPaymentPage } from "~/src/Redux/Billers/Reducers/SearchService.reducer";

import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import { BillerTypeEnum } from "~/src/Redux/Billers/Model/Biller.model";
import { setBillerAccountStatusNotLoaded } from "~/src/Redux/Billers/Reducers/BillerAccount.reducer";
import { getAccountDetailsListThunk } from "~/src/Redux/Payment/Services/accountDetails.service";
import { getCreditCardDetailsListThunk } from "~/src/Redux/Payment/Services/creditcardDetails.service";
import { getPrepaidOperatorListThunk } from "~/src/Redux/Prepaid/Services/prepaidOperator.service";
import {
  isCreditCardAllowed,
  isCreditCardRestriced,
} from "../../common/utitlity/biller.utilities";
import { billerStatusAmountResolver } from "../../ManageBillers/helpers/ManageBillerHelperFunctions";
import { setMakePaymentStatusNotLoaded } from "~/src/Redux/Payment/Reducers/MakePayment.reducers";
import { modifyRegisteredBillerNotLoaded } from "~/src/Redux/Billers/Reducers/ModifyRegisteredBiller.reducer";
import {
  hideLoader,
  showLoader,
} from "~/src/Redux/Loaders/Reducers/Loaders.reducers";

let loaderExecuted = false;
const npsCategory = "National Pension System";

const validationApiForPayee = [
  "NCMC Recharge".toLowerCase(),
  "Prepaid Meter".toLowerCase(),
];

export const paymentPageLoader = async () => {
  if (loaderExecuted) {
    console.log("Loader already executed, skipping...");
    return null;
  }
  loaderExecuted = true; // Set the flag

  const state: RootState = AppStore.getState();
  const {
    customerDetail,
    billers: { billerRegisterationToPaymentPage },
  } = state;

  try {
    AppStore.dispatch(setMakePaymentStatusNotLoaded({}));
    AppStore.dispatch(modifyRegisteredBillerNotLoaded({}));

    let biller = billerRegisterationToPaymentPage?.biller;
    let billerAccount = billerRegisterationToPaymentPage?.billerAccount;
    let validatePayment = billerRegisterationToPaymentPage?.validatePayment;
    let unregistred = billerRegisterationToPaymentPage?.unregistred || false;
    const [
      customerId,
      mobile,
      email,
      billerId,
      authenticators,
      shortName,
      agentDetails,
      additionalValidation,
    ] = state?.billerAccount?.registrationDetails;

    if (!biller && (billerAccount || validatePayment)) {
      const getBillerDetailsApiCall = await Promise.all([
        AppStore.dispatch(
          getBiller(billerAccount?.billerId || validatePayment?.billerId || "")
        ),
      ]);
      biller = getBillerDetailsApiCall[0];
    }

    let prepaidOperatorDetails;
    if (biller?.billerCategory === "Mobile Prepaid" && billerAccount) {
      const mobile = billerAccount?.authenticators[0].value;
      prepaidOperatorDetails = await AppStore.dispatch(
        getPrepaidOperatorListThunk(customerDetail?.customerId, mobile)
      );
    }

    if (!billerAccount && validatePayment && !unregistred) {
      const getBillerAcoutApiCall = await Promise.all([
        AppStore.dispatch(
          getBillerAccount(
            customerDetail?.customerId,
            validatePayment?.billerAccountId || ""
          )
        ),
        AppStore.dispatch(setBillerAccountStatusNotLoaded({})),
      ]);
      billerAccount = getBillerAcoutApiCall[0];
    }

    // Note: this if for the billerType:"BILLER" flow which are already registered billers.
    if (
      !validatePayment &&
      biller?.billerType === BillerTypeEnum.BILLER &&
      biller.onlineValidation &&
      billerAccount?.status.toLowerCase() === "active" &&
      !billerStatusAmountResolver(billerAccount).isScheduled &&
      biller?.billerCategory.toLowerCase() !== "fastag"
    ) {
      const CreditCardBillersForRefreshBill =
        process.env.CREDIT_CARD_BILLERS_FOR_REFRESH_BILL?.split(",") || [
          "AXIS00000NATKF",
          "YESB00000NAT8U",
          "KOTA00000NATED",
          "HDFC00000NATW1",
        ];
      if (
        CreditCardBillersForRefreshBill.includes(biller.billerId) &&
        biller.billerCategory === "Credit Card"
      ) {
        const getValidationApiCall = await Promise.all([
          AppStore.dispatch(
            validatePaymentReq({
              billerId: biller?.billerId || "",
              authenticators: billerAccount?.authenticators || [],
            })
          ),
        ]);
        validatePayment = getValidationApiCall[0];
      }

      if (
        biller?.onlineValidation &&
        !billerAccount.currentBill &&
        !CreditCardBillersForRefreshBill.includes(biller.billerId) &&
        !biller?.billPresentment &&
        !biller?.paymentAmountValidation
      ) {
        const getValidationApiCall = await Promise.all([
          AppStore.dispatch(
            validatePaymentReq({
              billerId: biller?.billerId || "",
              authenticators: billerAccount?.authenticators || [],
            })
          ),
        ]);
        validatePayment = getValidationApiCall[0];
      }
    }

    // Note: this is for the billerType:"BOTH" flow which are already registered billers.
    // Note: no need to call the validation-api again if user have the current bill.
    if (
      !validatePayment &&
      biller?.billerType === BillerTypeEnum.BOTH &&
      biller &&
      !billerAccount?.currentBill &&
      !biller.paymentAmountValidation
    ) {
      // const paymentAmount =
      //   biller?.paymentAmountValidation &&
      //   billerAccount?.currentBill?.netBillAmount
      //     ? {
      //         paymentAmount: Number(
      //           billerAccount?.currentBill.netBillAmount.toString()
      //         ),
      //       }
      //     : {};
      const getValidationApiCall = await AppStore.dispatch(
        validatePaymentReq({
          billerId: biller?.billerId || "",
          authenticators: billerAccount?.authenticators || [],
          // ...paymentAmount,
        })
      );
      validatePayment = getValidationApiCall;
    }

    // BPN-3087 Validation Api needs to call for ncmc and prepaidmeter which are payee biller
    if (
      !validatePayment &&
      biller &&
      validationApiForPayee.includes(biller?.billerCategory?.toLowerCase())
    ) {
      const getValidationApiCall = await Promise.all([
        AppStore.dispatch(
          validatePaymentReq({
            billerId: biller?.billerId || "",
            authenticators: billerAccount?.authenticators || [],
          })
        ),
      ]);
      validatePayment = getValidationApiCall[0];
    }

    if (biller?.billerCategory.toLowerCase() === "fastag") {
      // Note: this is foor the billerCategoty is "FASTag" which are already registered billers.
      if (
        biller &&
        biller.billerType !== BillerTypeEnum.PAYEE &&
        !validatePayment &&
        !billerAccount?.currentBill &&
        !biller?.paymentAmountValidation
      ) {
        const getValidationApiCall = await Promise.all([
          AppStore.dispatch(
            validatePaymentReq({
              billerId: biller?.billerId || "",
              authenticators: billerAccount?.authenticators || [],
            })
          ),
        ]);
        validatePayment = getValidationApiCall[0];
      }
    }

    if (
      unregistred &&
      !(biller?.billerCategory.toLowerCase() === npsCategory.toLowerCase()) &&
      state?.registerBillerDetailsList?.registerBillerData?.status ===
        DataStatus.NOT_LOADED
    ) {
      // Await registerBillerListThunk to ensure completion before proceeding
      AppStore.dispatch(showLoader());
      const registerationResponse = await Promise.all([
        AppStore.dispatch(
          registerBillerListThunk({
            billerId,
            authenticators,
            shortName,
            agentDetails,
          })
        ),
      ]); // Ensures resolved value is returned

      billerAccount = registerationResponse[0];
      AppStore.dispatch(showLoader());

      // Ensure biller account status is updated after successful registration
      await AppStore.dispatch(setBillerAccountStatusNotLoaded({}));
    }

    const billAmount =
      Number(
        twoDecimalPointRestriction(
          billerAccount?.currentBill?.netBillAmount.toString() || ""
        )
      ) || validatePayment?.currentBill?.netBillAmount;

    // cashback/search-active-offers
    const cashBackApiCheck =
      biller?.billerType === BillerTypeEnum.PAYEE ||
      biller?.billerCategory.toLowerCase() === "fastag" //   // adding condition for BPN-2875
        ? [] // Skip if conditions don't meet
        : [
            AppStore.dispatch(
              getCashbackActiveOfferListThunk(
                customerDetail?.customerId,
                biller?.billerId || "",
                customerDetail?.customerDetail?.data?.accountSchemeCodes,
                biller?.billerCategory || "",
                Number(process.env.AUTO_BILL_AMOUNT),
                billAmount,
                biller?.isBillerBBPS,
                "",
                false,
                state?.billerAccount?.cashbackBillerAccountData
              )
            ),
          ];

    //checking if credit card list api need to be called or not
    const creditCardApi =
      biller &&
      isCreditCardAllowed(biller) &&
      biller?.isBillerBBPS &&
      !isCreditCardRestriced(biller?.billerCategory)
        ? [
            AppStore.dispatch(
              getCreditCardDetailsListThunk(customerDetail?.customerId)
            ),
          ]
        : [];

    await Promise.allSettled([
      ...cashBackApiCheck,
      ...creditCardApi,
      AppStore.dispatch(getAccountDetailsListThunk(customerDetail?.customerId)),
      AppStore.dispatch(
        getBillerStatus(biller?.billerId || billerAccount?.billerId || "")
      ),
    ]);

    let totalAmount = validatePayment?.paymentAmount?.toString();
    let ccfFee = "";
    if (biller?.billerCategory === "National Pension System") {
      totalAmount =
        validatePayment?.currentBill &&
        validatePayment?.currentBill.additionalDetails
          ?.filter((item) => item.seq !== 5)
          .reduce((acc, item) => acc + parseFloat(item.value), 0);

      ccfFee =
        validatePayment?.currentBill &&
        validatePayment?.currentBill.additionalDetails
          ?.filter((item) => item.label.includes("CCF1"))
          .reduce((sum, item) => sum + parseFloat(item.value), 0);
    }

    AppStore.dispatch(
      setBillerRegisterationToPaymentPage({
        ...state?.billers?.billerRegisterationToPaymentPage,
        biller,
        billerAccount,
        validatePayment,
        unregistred,
        amount: totalAmount,
        convenienceFee: ccfFee,
        nickName: billerAccount?.shortName,

        // selOperator: prepaidOperatorDetails?.selOperator,

        ...(prepaidOperatorDetails && {
          selCircle: prepaidOperatorDetails?.circleName,
          selCircleId: prepaidOperatorDetails?.circleId,
        }),
      })
    );

    loaderExecuted = false;
    AppStore.dispatch(hideLoader());
    return null;
  } catch (error) {
    console.error("Error in PaymentPageLoader:", error);
    window.location =
      APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString() as any;
    // redirect(APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString());
    throw error;
  }
};
