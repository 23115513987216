import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/CoachMarks/Selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  CoachMarksData,
  ProcessCoachMarksData,
} from "~/src/Redux/CoachMarks/Model";
import {
  getCoachMarksDetailAction,
  processCoachMarksDetailAction,
} from "./Actions";

export type CoachMarksState = {
  coachMarksData: IRemoteData<CoachMarksData>;
  processCoachMarksData: IRemoteData<ProcessCoachMarksData>;
};

const initialState: CoachMarksState = {
  coachMarksData: new RemoteData(),
  processCoachMarksData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions<CoachMarksState> = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CoachMarksState>): void => {
    builder.addCase(
      getCoachMarksDetailAction.success,
      (state: CoachMarksState, action) => {
        state.coachMarksData = state.coachMarksData.setData(action.payload);
      }
    );

    builder.addCase(
      getCoachMarksDetailAction.error,
      (state: CoachMarksState, action) => {
        state.coachMarksData = state.coachMarksData.setError(action.payload);
      }
    );
    builder.addCase(
      getCoachMarksDetailAction.loading,
      (state: CoachMarksState, action) => {
        state.coachMarksData = state.coachMarksData.setLoading();
      }
    );

    builder.addCase(
      processCoachMarksDetailAction.success,
      (state: CoachMarksState, action) => {
        state.processCoachMarksData = state.processCoachMarksData.setData(
          action.payload
        );
      }
    );

    builder.addCase(
      processCoachMarksDetailAction.error,
      (state: CoachMarksState, action) => {
        state.processCoachMarksData = state.processCoachMarksData.setError(
          action.payload
        );
      }
    );
    builder.addCase(
      processCoachMarksDetailAction.loading,
      (state: CoachMarksState, action) => {
        state.processCoachMarksData = state.processCoachMarksData.setLoading();
      }
    );
  },
};

const coachMarksSlice = createSlice(sliceOptions);

export default coachMarksSlice.reducer;
export const {} = coachMarksSlice.actions;
