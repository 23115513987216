import { ModelMapper } from "~/src/Lib/types/modelmapper";
import parse from "date-fns/parse";
import {
  BillDetail,
  BillDetailDto,
  BillDetailMapper,
} from "./BillDetail.model";
import {
  PaymentAccount,
  PaymentAccountDto,
  PaymentAccountMapper,
} from "~/src/Redux/Payment/Model/PaymentDetail.model";

export interface BillerAccountDto {
  billeraccountid: string;
  billerid: string;
  biller_name: string;
  short_name: string;
  authenticators: AuthenticatorValueDto[];
  status: "ACTIVE" | "DELETED";
  registration_date: string;
  autopay_status: "Y" | "N";
  autopay_amount?: string;
  payment_account?: PaymentAccountDto;
  currency?: string;
  biller_category: string;
  biller_logo: string;
  biller_type: string;
  paymentid?: string;
  billlist?: BillDetailDto[];
  payment_type?: string;
  payment_amount: string;
  biller_status?: string;
  payment_status?: string;
  schedule_date?: string;
  agent_details?: AgentDetailsDto;
}
export interface AuthenticatorValueDto {
  parameter_name: string;
  value: string;
}
export interface AgentDetailsDto {
  employeeCode: string;
  branchCode: string;
  channelId: string;
}

export interface BillerAccount {
  billerAccountId: string;
  billerId: string;
  billerName: string;
  shortName?: string;
  authenticators: AuthenticatorValue[];
  status: "ACTIVE" | "DELETED";
  registrationDate: Date;
  autopayStatus: boolean;
  autopayAmount?: number;
  paymentAccount?: PaymentAccount;
  currency: string;
  billerCategory: string;
  billerLogo: string;
  billerType: string;
  paymentId?: string;
  billList?: BillDetail[];
  currentBill?: BillDetail;
  paymentAmount?: string;
  paymentType?: string;
  billerStatus?: string;
  paymentStatus?: string;
  scheduleDate?: Date | undefined;
  agent_details?: AgentDetails;
}

export interface AuthenticatorValue {
  parameterName: string;
  value: string;
}
export interface AgentDetails {
  employeeCode: string;
  branchCode: string;
  channelId: string;
}
export class BillerAccountMapper
  implements ModelMapper<BillerAccountDto, BillerAccount>
{
  toModel(dto: BillerAccountDto): BillerAccount {
    const billDetailMapper = new BillDetailMapper();
    return {
      billerAccountId: dto.billeraccountid,
      shortName: dto.short_name,
      authenticators: dto.authenticators.map((auth) => ({
        parameterName: auth.parameter_name,
        value: auth.value,
      })),
      status: dto.status,
      registrationDate: parse(
        dto.registration_date,
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      ),
      autopayStatus: dto.autopay_status == "Y" ? true : false,
      autopayAmount: dto.autopay_amount,
      paymentAccount: dto.payment_account
        ? new PaymentAccountMapper().toModel(dto.payment_account)
        : undefined,
      currency: dto.currency,
      billerId: dto.billerid,
      billerName: dto.biller_name || dto.billerid,
      billerCategory: dto.biller_category,
      billerLogo: dto.biller_logo,
      billerType: dto.biller_type,
      paymentId: dto.paymentid,
      billList: dto.billlist?.map((bill) => billDetailMapper.toModel(bill)),
      currentBill:
        dto.billlist && dto.billlist.length > 0
          ? billDetailMapper.toModel(dto.billlist[0])
          : undefined,
      paymentAmount: dto.payment_amount,
      paymentType: dto.payment_type,
      billerStatus: dto.biller_status,
      paymentStatus: dto.payment_status,
      scheduleDate: dto.schedule_date
        ? parse(dto?.schedule_date, "dd-MM-yyyy", new Date())
        : undefined,
      agentDetails: dto.agent_details,
    } as BillerAccount;
  }
  toDto(model: BillerAccount): BillerAccountDto {
    throw new Error("Method not implemented." + model);
  }
}
