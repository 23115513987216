import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { CashbackActiveOffersDataMapper } from "~/src/Redux/Cashback/Model/cashbackActiveOffer.model";

import {
  fetchCashbackOfferList,
  fetchCashbackOfferListFail,
  fetchCashbackOfferListSuccess,
} from "~/src/Redux/Cashback/Reducers/CashbackActiveOffer.reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const CASHBACK_ACTIVE_OFFER_URL = URL.CASHBACK.CASHBACK_ACTIVE_OFFERS;

export const getCashbackActiveOfferListThunk =
  (
    customerId: string,
    billerId: string,
    schemeCodes: any,
    billerCategory: string,
    autoBillAmount: any,
    manualBillAmount: any,
    billerType: any,
    paymentType: string,
    fetchRegistration: boolean,
    registrations: any[]
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchCashbackOfferList({
        customerId,
        billerId,
        schemeCodes,
        billerCategory,
        autoBillAmount,
        manualBillAmount,
        billerType,
        paymentType,
        fetchRegistration,
        registrations,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: CASHBACK_ACTIVE_OFFER_URL,
        method: "POST",
        data: {
          customerId: customerId,
          billerId: billerId,
          schemeCodes: schemeCodes,
          billerCategory: billerCategory,
          manualBillAmount: manualBillAmount,
          autoBillAmount: autoBillAmount,
          billerType: billerType ? "BBPS" : "NONBBPS",
          paymentType: paymentType,
          fetchRegistration: fetchRegistration,
          registrations: registrations,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;
      const { tokens = {} } = data;
      const token =
        // Instore.get('auth') ||
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY";

      /*       const { accessToken = token, refreshToken = "" } = tokens;
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken); */

      const mapper = new CashbackActiveOffersDataMapper();

      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchCashbackOfferListSuccess(mappedData));

      return mappedData;
    } catch (error) {
      dispatch(fetchCashbackOfferListFail(error as ApiResponseError));
      throw error;
    }
  };
