import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/AccountDetails.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { AccountInfo } from "../Model/AccountDetail.model";

export type AccountDetailsListState = {
  accountDetailsData: IRemoteData<AccountInfo>;
};

const initialState: AccountDetailsListState = {
  accountDetailsData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchAccountDetailsList(state) {
      state.accountDetailsData = state.accountDetailsData.setLoading();
    },
    fetchAccountDetailsListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.accountDetailsData = state.accountDetailsData =
        state.accountDetailsData.setError(action.payload);
    },
    fetchAccountDetailsListSuccess(state, action: PayloadAction<AccountInfo>) {
      state.accountDetailsData = state.accountDetailsData.setData(
        action.payload
      );
    },
    fetchAccountDetailsListNotLoaded(state) {
      state.accountDetailsData = new RemoteData();
      state.accountDetailsData.status = DataStatus.NOT_LOADED;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const accountDetailsSlice = createSlice(sliceOptions);

export default accountDetailsSlice.reducer;
export const {
  fetchAccountDetailsList,
  fetchAccountDetailsListFail,
  fetchAccountDetailsListSuccess,
  fetchAccountDetailsListNotLoaded,
} = accountDetailsSlice.actions;
