import { ModelMapper } from "~/src/Lib/types/modelmapper";
export interface OperatorCircleDto {
  billerid: string;
  biller_name: string;
  circle_name: string;
  circleid: string;
  status: string;
}

export interface OperatorCircle {
  billerId: string;
  billerName: string;
  circleId: string;
  circleName: string;
}

export class OperatorCircleMapper
  implements ModelMapper<OperatorCircleDto, OperatorCircle>
{
  toModel(dto: OperatorCircleDto): OperatorCircle {
    return {
      billerId: dto.billerid,
      billerName: dto.biller_name,
      circleId: dto.circleid,
      circleName: dto.circle_name,
    } as OperatorCircle;
  }
  toDto(model: OperatorCircle): OperatorCircleDto {
    console.log("Model", model);
    throw new Error("Method not implemented.");
  }
}

export interface PlanDetail {
  billerId: string;
  billerName: string;
  planId: string;
  talktime: number;
  amount: number;
  validity: string;
  planDescription: string;
  circleName: string;
  planCategoryName: string;
  circleId: string;
  topPlan: boolean;
}

export interface PlanDetailDto {
  objectid: string;
  billerid: string;
  biller_category: string;
  biller_name: string;
  planid: string;
  talktime: string;
  amount: string;
  validity: string;
  plan_description: string;
  circle_name: string;
  plan_status: string;
  plan_category_name: string;
  circleid: string;
  top_plan: string;
}

export class PlanDetailMapper
  implements ModelMapper<PlanDetailDto, PlanDetail>
{
  toModel(dto: PlanDetailDto): PlanDetail {
    return {
      billerId: dto.billerid,
      billerName: dto.biller_name,
      circleId: dto.circleid,
      circleName: dto.circle_name,
      planId: dto.planid,
      talktime: dto.talktime === "NA" ? 0 : Number(dto.talktime),
      amount: Number(dto.amount),
      validity: dto.validity, //string
      planDescription: dto.plan_description,
      planCategoryName: dto.plan_category_name,
      // planSubCategory: dto.plan_subcategory,
      topPlan: dto?.top_plan?.toUpperCase() === "Y" ? true : false,
    } as PlanDetail;
  }
  toDto(model: PlanDetail): PlanDetailDto {
    console.log("Model", model);
    throw new Error("Method not implemented.");
  }
}

export interface CircleDto {
  circleId: string;
  id: string;
  name: string;
}

export interface Circle {
  circleId: string;
  id: string;
  name: string;
}

export class CircleMapper implements ModelMapper<CircleDto, Circle> {
  toModel(dto: CircleDto): Circle {
    return {
      circleId: dto.circleId,
      name: dto.name,
      id: dto.id,
    } as Circle;
  }
  toDto(model: Circle): CircleDto {
    console.log("Model", model);
    throw new Error("Method not implemented.");
  }
}

export interface PrepaidLogs {
  statusCode: number;
  status: string;
  message: string;
}

export interface PrepaidLogsDto {
  statusCode: number;
  status: string;
  message: string;
}

export class PrepaidLogsMapper
  implements ModelMapper<PrepaidLogsDto, PrepaidLogs>
{
  toModel(dto: PrepaidLogsDto): PrepaidLogs {
    return {
      statusCode: dto.statusCode,
      status: dto.status,
      message: dto.message,
    } as PrepaidLogs;
  }
  toDto(model: PrepaidLogs): PrepaidLogsDto {
    console.log("Model", model);
    throw new Error("Method not implemented.");
  }
}
