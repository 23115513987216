import { RootState } from "~/src/Configurations/AppStore";
import {
  isServiceLoading,
  isServiceSuccess,
  isServiceError,
} from "~/src/Redux/ServiceTracker/Selectors";
import { getPromoCodeOffersListConfig } from "~/src/Redux/PromoCode/Actions/PromoCodeOffers.action";

export const SLICE_NAME = "promoCodeOffers";

export const getPromoCodeOffersLoadingSelector = (state: RootState) =>
  isServiceLoading(state, [getPromoCodeOffersListConfig]);

export const getPromoCodeOffersSuccessSelector = (state: RootState) =>
  isServiceSuccess(state, [getPromoCodeOffersListConfig]);

export const getPromoCodeOffersErrorSelector = (state: RootState) =>
  isServiceError(state, [getPromoCodeOffersListConfig]);

export const getPromoCodeOffersSelector = (state: RootState) =>
  state[SLICE_NAME];
