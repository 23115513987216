import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/ValidateBill.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { ValidatePayment } from "../Model/ValidatePayment.model";
import { getvalidateBill } from "../Actions/ValidateBill.action";

export type ValidateBillStateType = {
  validateData: IRemoteData<ValidatePayment>;
  validateBillData: ValidatePayment;
  validateBillStatus: DataStatus;
  validateBillError?: ApiResponseError;
};

const initialState: ValidateBillStateType = {
  validateData: new RemoteData(),
  validateBillData: {} as ValidatePayment,
  validateBillStatus: DataStatus.NOT_LOADED,
  validateBillError: undefined,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setValidateBillStatusNotLoaded(state) {
      state.validateData = new RemoteData();
      state.validateBillData = {} as ValidatePayment;
      state.validateBillStatus = DataStatus.NOT_LOADED;
      state.validateBillError = undefined;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getvalidateBill.success, (state, { payload }) => {
      state.validateData = state.validateData.setData(payload);
      state.validateBillData = payload;
      state.validateBillStatus = state.validateData.status;
      state.validateBillError = undefined;
    });

    builder.addCase(getvalidateBill.error, (state, { payload }) => {
      state.validateData = state.validateData.setError(payload);
      state.validateBillError = payload;
      state.validateBillStatus = state.validateData.status;
    });

    builder.addCase(getvalidateBill.loading, (state) => {
      state.validateData = state.validateData.setLoading();
      state.validateBillStatus = state.validateData.status;
    });
  },
};

const validateBill = createSlice(sliceOptions);

export default validateBill.reducer;
export const { setValidateBillStatusNotLoaded } = validateBill.actions;
