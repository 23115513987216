import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Customer.selector";
import { getCustomerDataConfigAction } from "./Customer.action";
import { IRemoteData, RemoteData } from "~/src/Lib/types/datatransfer";
import { CustomerDetail } from "./Model";

export enum CustomerChannelEnum {
  InternetBanking = "InternetBanking" as any, //407
  MobileBanking = "MobileBanking" as any, //410
}
export interface UtmDetails {
  employeeCode: string;
  branchCode: string;
  channelId: string;
}

export type CustomerDetailState = {
  customerDetail: IRemoteData<CustomerDetail>;
  customerId: string;
  firstTimeAppLoad: boolean;
  utmDetails: UtmDetails;
};
const initialState: CustomerDetailState = {
  customerDetail: new RemoteData(),
  customerId: "",
  firstTimeAppLoad: true,
  utmDetails: {} as UtmDetails,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setFirstTimeAppLoad(state, action: PayloadAction) {
      state.firstTimeAppLoad = action.payload;
    },
    setUtmDetails(state, action: PayloadAction) {
      state.utmDetails = action.payload;
    },
  },

  extraReducers: (
    builder: ActionReducerMapBuilder<CustomerDetailState>
  ): void => {
    builder.addCase(
      getCustomerDataConfigAction.success,
      (state, { payload }) => {
        state.customerDetail = state.customerDetail.setData(payload);
        state.customerId = payload.customerId;
      }
    );
    builder.addCase(getCustomerDataConfigAction.error, (state, { payload }) => {
      state.customerDetail = state.customerDetail.setError(payload);
    });
    builder.addCase(
      getCustomerDataConfigAction.loading,
      (state, { payload }) => {
        state.customerDetail = state.customerDetail.setLoading();
      }
    );
  },
};

const customerSlice = createSlice(sliceOptions);

export default customerSlice.reducer;
export const { setFirstTimeAppLoad, setUtmDetails } = customerSlice.actions;
