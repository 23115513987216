import { ModelMapper } from "~/src/Lib/types/modelmapper";

interface CategoryListData {
  id: string;
  categoryName: string;
  categoryLogo: string;
  displayName: string;
  animatedCategoryLogo: string;
}
export interface CategoryListDto {
  _id: string;
  category_type: string;
  category_list: {
    _id: string;
    category_name: string;
    category_logo: string;
    display_name: string;
    animated_category_logo: string;
  }[];
}
export interface CategoryList {
  id: string;
  categoryType: string;
  categoryList: CategoryListData[];
}
export class CategoryListMapper
  implements ModelMapper<CategoryListDto, CategoryList>
{
  toModel(dto: CategoryListDto): CategoryList {
    return {
      id: dto._id,
      categoryType: dto.category_type,
      categoryList: dto.category_list.map((auth) => ({
        id: auth._id,
        categoryName: auth.category_name,
        categoryLogo: auth.category_logo,
        displayName: auth.display_name,
        animatedCategoryLogo: auth.animated_category_logo,
      })),
    };
  }
  toDto(model: CategoryList): CategoryListDto {
    throw new Error(`Method not implemented. for ${model}`);
  }
}
